import { GLForgotPassword } from "@group-link-one/grouplink-components";

import { useForgotPassword } from "./useForgot";

export const Forgot = () => {
  const { sendForm, redirectToLogin, disabled } = useForgotPassword();

  return (
    <GLForgotPassword
      onRedirectToLogin={redirectToLogin}
      onSubmit={sendForm}
      isLoading={disabled}
    />
  );
};
