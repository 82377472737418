import { useAuth } from "@group-link-one/gl-auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCustomerService } from "../../../../Services/customerService/useCustomerService";
import { useImpersonateContentStore } from "../../store/impersonateContent.store";
import { useImpersonateStore } from "../../store/impersonateModal.store";

export const useImpersonateContent = () => {
  const { manipulateReduxValues, setIsPending, accessToken } = useAuth();
  const { customerImpersonation } = useCustomerService();
  const navigate = useNavigate();
  const { state, actions } = useImpersonateStore();
  const { state: impersonateContentState, actions: impersonateContentActions } =
    useImpersonateContentStore();

  const functionToVerifyIfCanAdvanceMap = {
    "organization": verifyIfCanAdvanceToUsers,
    "user": () => false,
  };

  function verifyIfCanAdvanceToUsers() {
    if (impersonateContentState.organizationId === 0) {
      impersonateContentActions.setOrgErrorMessage(
        "Please select an organization"
      );

      return true;
    }

    impersonateContentActions.setOrgErrorMessage("");
    return false;
  }

  const impersonateCustomer = async () => {
    if (impersonateContentState.userId === 0) return;
    manipulateReduxValues("SET_ROOT_TOKEN", accessToken!);
    actions.setIsLoading(true);
    setIsPending(true);

    customerImpersonation({
      customer_id: Number(impersonateContentState.userId),
    })
      .then((res) => {
        manipulateReduxValues("SET_ACCESS_TOKEN", `Bearer ${res.access_token}`);
        localStorage.setItem("isImpersonated", "true");

        actions.resetAll();
        impersonateContentActions.resetAll();
        navigate("/settings/overview");
        setIsPending(false);
      })
      .catch((err) => {
        console.log(err);
        actions.setIsLoading(false);
        setIsPending(false);
      });
  };

  useEffect(() => {
    if (impersonateContentState.userId === 0) {
      impersonateContentActions.setUserErrorMessage("Please select an user");
    }
  }, []);

  return {
    functionToVerifyIfCanAdvanceMap,
    state,
    actions,
    impersonateCustomer,
    impersonateContentActions,
  };
};
