import "./Services/remoteConfig";

import {
  GLMenuMobileProvider,
  GLModalLogoutProvider,
  GLToastProvider,
  I18n,
} from "@group-link-one/grouplink-components";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Helmet } from "react-helmet";

import FireStoreProvider from "./Context/FireStoreProvider";
import FlagsProvider from "./Context/FlagsProvider";
import { useCallbackToModalLogout } from "./hooks/useCallbackToModalLogout";
import { Routes } from "./Routes/index.routes";

function App() {
  const { callbackFnToModalLogout } = useCallbackToModalLogout();

  return (
    <>
      <FlagsProvider>
        <FireStoreProvider>
        <GLModalLogoutProvider
          callbackFnToModalLogout={callbackFnToModalLogout}
        >
          <GLMenuMobileProvider>
            <GLToastProvider>
              <I18n>
                <Routes />
              </I18n>
            </GLToastProvider>
          </GLMenuMobileProvider>
          </GLModalLogoutProvider>
        </FireStoreProvider>

      </FlagsProvider>

      <ReactQueryDevtools />
      <Helmet>
        <style>
          {`
            .tsqd-main-panel {
              z-index: 99999 !important;
            }
          `}
        </style>
      </Helmet>
    </>
  );
}

export default App;
