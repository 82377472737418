import { GLSettingsLoginSecurity } from "@group-link-one/grouplink-components";

import { useLoginSecurity } from "./useLoginSecurity";

export const LoginSecurity = () => {
  const {
    logOutDevice,
    updatePassword,
    sessions,
    isLoading,
    generateCode,
    sendCodeIsLoading,
    user,
  } = useLoginSecurity();

  return (
    <GLSettingsLoginSecurity
      onLogOutDevice={logOutDevice}
      onSubmitUpdatePassword={updatePassword}
      sessions={sessions}
      userHistoryIsLoading={isLoading}
      sendCodeAction={generateCode}
      sendCodeIsLoading={sendCodeIsLoading}
      user={user!}
    />
  );
};
