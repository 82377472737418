import { GLSettingsUsersContent } from "@group-link-one/grouplink-components";

import { IUsersContent } from "../types/types";
import { useUsersContent } from "./useUsersContent";

export function UsersContent({ onActiveUserById }: IUsersContent) {
  const { options } = useUsersContent();

  return (
    <GLSettingsUsersContent
      options={options}
      onActiveUserById={onActiveUserById}
    />
  );
}
