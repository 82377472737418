import {
  GL_BUILDING,
  GL_COLORS,
  GLBox,
  GLCard,
  GLExtendedTextField,
  GLList,
  GLSelectMultiple,
  GLTipsCard,
  GLTypography,
} from "@group-link-one/grouplink-components";

import { useOrganizationContent } from "./useOrganizationContent";

export const OrganizationsContent = () => {
  const {
    data,
    onSelectUseCase,
    onSelectOrganization,
    impersonateContentState,
    impersonateContentActions,
    isMobile,
    t,
  } = useOrganizationContent();

  return (
    <GLBox direction="row" justify="space-between">
      <GLBox
        direction="column"
        width={"100%"}
        style={{
          maxWidth: 1174,
        }}
      >
        <GLTypography
          text={t(
            "impersonate.modal.steps.organization.labels.selectOrganization"
          )}
          weight={600}
        />

        <GLList
          mode="default"
          // isLoading={isLoading}
          contentStyle={{
            height: 500,
            overflow: "auto",
          }}
          tabs={[
            {
              name: t("impersonate.modal.steps.organization.tabs.all"),
              count: data?.length,
              cacheKey: "get-organizations",
            },
          ]}
          tabActive={t("impersonate.modal.steps.organization.tabs.all")}
          filters={
            <GLBox>
              {impersonateContentState.useCaseOptionsAvailables && (
                <GLSelectMultiple
                  options={impersonateContentState.useCaseOptionsAvailables}
                  optionsSelected={[]}
                  canSelectMultiple={false}
                  hasCheckbox={false}
                  onSelectedOptionsChange={(selectedOptions) => {
                    if (onSelectUseCase) onSelectUseCase(selectedOptions);
                  }}
                  inputOptions={{
                    width: "fit-content",
                    layout: "size",
                    style: {
                      minWidth: "240px",
                      height: "46.25px",
                    },
                    placeholder: t("deviceList.selectUseCase.placeholder"),
                  }}
                  rootOptions={{
                    width: "fit-content",
                  }}
                />
              )}

              <GLExtendedTextField
                value={impersonateContentState.textToSearchOrg}
                onChange={(e) => impersonateContentActions.setOrganizationTextToSearch(e.target.value)}
              />
            </GLBox>
          }
          error={impersonateContentState.orgErrorMessage ? "Error" : undefined}
          content={
            <GLBox width={"100%"} direction="column">
              {data?.map((organization: any) => (
                  <GLCard
                    id={organization.id}
                    key={organization.id}
                    variant="settings-organization-card"
                    name={organization.org_name}
                    onClick={onSelectOrganization}
                    isCheck={
                      organization.id === impersonateContentState.organizationId
                    }
                  />
                ))}
            </GLBox>
          }
        />
      </GLBox>

      {!isMobile && (
        <GLBox width={"504px"}>
          <GLTipsCard
            icon={<GL_BUILDING fill={GL_COLORS.FONT_COLOR_DARK_GREY} />}
            title={t("impersonate.modal.steps.organization.tips.title")}
            size="small"
            hasAnimation
            type="default"
            content={
              <GLTypography
                text={t(
                  "impersonate.modal.steps.organization.tips.description"
                )}
              />
            }
          />
        </GLBox>
      )}
    </GLBox>
  );
};
