import {
  GL_COLORS,
  GL_GLStation,
  GLBox,
  GLList,
  GLLoading,
  GLTypography,
} from "@group-link-one/grouplink-components";

import { DeviceListCard } from "../Cards/DeviceListCard";
import {
  DeviceListAllDevicesContainer,
  DeviceListEmpty,
} from "./DeviceListContentStyle";
import {
  useDeviceListAllDevices,
} from "./useDeviceListContent";

export function DeviceListContent() {
  const {
    activeTab,
    currentDeviceList,
    distanceFromHeader,
    paginationState,
    listContainerRef,
    tabs,
    isToShowDeviceList,
    isToShowEmptyState,
    isToShowError,
    currentDeviceListIsLoading,
    getMoreDevicesOnScroll,
    onInfiniteScroll
  } = useDeviceListAllDevices();

  return (
    <DeviceListAllDevicesContainer ref={listContainerRef} gap={30} direction="column" height="100%">
      <GLList
        tabs={tabs}
        tabActive={activeTab?.name || ""}
        hasFixedHeaderAnimation={false}
        scrollPositionToActiveHeaderFixed={distanceFromHeader}
        isLoading={currentDeviceListIsLoading}
        content={
          <>
            {isToShowDeviceList &&
              currentDeviceList!.map((item) => (
                <DeviceListCard key={item.device_id} item={item} />
              ))}

            {isToShowEmptyState && (
              <DeviceListEmpty>
                <div>
                  <GL_GLStation
                    size={30}
                    fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                  />
                </div>

                <GLTypography
                  text="No GL device found"
                  color="FONT_COLOR_DARK_GREY"
                  fontSize={5}
                  weight={600}
                  as="p"
                />
              </DeviceListEmpty>
            )}

            {isToShowError && (
              <DeviceListEmpty>
                <div>
                  <GL_GLStation
                    size={30}
                    fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                  />
                </div>

                <GLTypography
                  text="No GL device found"
                  color="FONT_COLOR_DARK_GREY"
                  fontSize={5}
                  weight={600}
                  as="p"
                />
              </DeviceListEmpty>
            )}

            {paginationState.isFetchingInfiniteScroll && (
              <GLBox align="center" justify="center" style={{ marginBlock: 20 }}>
                <GLLoading type="dots" size="primary" />
              </GLBox>
            )}
          </>
        }
        mode="infinit-scroll"
        type={isToShowEmptyState || isToShowError ? "empty" : "list"}
        listName="Device List"
        onScroll={(event) =>
          onInfiniteScroll({ event, callback: getMoreDevicesOnScroll })
        }
      />
    </DeviceListAllDevicesContainer>
  );
}
