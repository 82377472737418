import { GLSettingsOverview } from "@group-link-one/grouplink-components";

import { useFlags } from "../../../Context/FlagsProvider";
import { useOverview } from "./useOverview";

export const Overview = () => {
  const { hasPrivileges } = useFlags();
  const { navigate, user } = useOverview();

  return (
    <GLSettingsOverview
      hasPrivileges={hasPrivileges}
      user={user!}
      count={String(0)}
      editProfileButtonAction={() => navigate("/settings/my-account")}
      navigateToApps={() => {}}
      navigateToLoginSecurity={() => navigate("/settings/login-security")}
    />
  );
};
