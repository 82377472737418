import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { useDeviceListService } from "../../../../Services/deviceListService/useDeviceListService";
import { DeviceMapResponse } from "../../../../Services/deviceListService/useDeviceListService.types";
import { useGetDailyReadings } from "../../../DeviceList/ModalDeviceDetails/Graphs/useGetDailyReadings";
import { useDetailsDeviceStore } from "../../../DeviceList/store/details-device-store";
import { getOptionsByApplication } from "../../../DeviceList/utils/getOptionsByApplication";
import { useHealthCheckStore } from "../../store/health-check-store";
import { GLMarkerProps } from "./Marker";

export const useMarker = ({ device }: GLMarkerProps) => {

  const {
    state: healthCheckState,
    actions: healthCheckActions,
  } = useHealthCheckStore()

  const { actions: detailsDeviceActions } = useDetailsDeviceStore();
  const { getDailyReadings } = useGetDailyReadings()

  const { getDevicesActivatedLastReadings } = useDeviceListService()
  const queryClient = useQueryClient()

  const deviceApplication = getOptionsByApplication({ type: device.application }).svgIcon

  const markers = {
    active: {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        ${typeof deviceApplication?.green === "function" ? deviceApplication.green("green") : deviceApplication?.green}
      `),
      scaledSize: new window.google.maps.Size(44, 44),
    },
    activeSelected: {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        <svg width="96" height="96" xmlns="http://www.w3.org/2000/svg">
            <!-- Raio maior com linha tracejada e fundo verde claro -->
            <circle cx="48" cy="48" r="44" fill="#ccffcc70" stroke="#00FFAA" stroke-width="2" stroke-dasharray="4 2"/>
            <!-- Círculo interno centralizado -->
            <circle cx="48" cy="48" r="14" fill="#00FFAA" stroke="white" stroke-width="2"/>
        </svg>
      `),
      scaledSize: new window.google.maps.Size(96, 96),
    },
    withAlert: {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        ${typeof deviceApplication?.red === "function" ? deviceApplication.red("red") : deviceApplication?.red}
      `),
      scaledSize: new window.google.maps.Size(44, 44),
    },
    withAlertSelected: {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        <svg width="96" height="96" xmlns="http://www.w3.org/2000/svg">
            <!-- Raio maior com linha tracejada e fundo verde claro -->
            <circle cx="48" cy="48" r="44" fill="#f88d9d70" stroke="#E12C47" stroke-width="2" stroke-dasharray="4 2"/>
            <!-- Círculo interno centralizado -->
            <circle cx="48" cy="48" r="14" fill="#E12C47" stroke="white" stroke-width="2"/>
        </svg>
      `),
      scaledSize: new window.google.maps.Size(96, 96),
    },
  }

  function getMarkerIcon(deviceModalIsOpen: boolean, device_id?: number) {

    if (!device_id) return markers.active

    if (deviceModalIsOpen &&
      healthCheckState.deviceSelected?.device_id === device_id &&
      healthCheckState.deviceSelected?.channels[0].alerts.length === 0) {
      return markers.activeSelected
    }

    if (deviceModalIsOpen &&
      healthCheckState.deviceSelected?.channels[0].alerts &&
      healthCheckState.deviceSelected?.channels[0].alerts.length > 0 &&
      healthCheckState.deviceSelected?.device_id === device_id) {
      return markers.withAlertSelected
    }

    if (device.alerts.length === 0) {
      return markers.active
    }

    if (device.alerts && device.alerts.length > 0) {
      return markers.withAlert
    }


  }

  async function onMarkerClick(deviceParam: DeviceMapResponse) {
    healthCheckActions.setDeviceModalIsOpen(true)
    healthCheckActions.setIsFetchingDeviceInfo(true)

    const [deviceInfo, deviceDailyReading] = await Promise.all([
      getDeviceInfo(deviceParam.device_id),
      getDailyReadings({
        device_id: deviceParam.device_id,
        range: {
          from: healthCheckState.range.from,
          to: healthCheckState.range.to
        }
      })
    ])

    healthCheckActions.setIsFetchingDeviceInfo(false)
    healthCheckActions.setDeviceSelectedDailyReadings(deviceDailyReading?.response)
    healthCheckActions.setDeviceSelected(deviceInfo)
  }

  async function getDeviceInfo(device_id: number) {
    const deviceInfo = await queryClient.fetchQuery({
      queryKey: ["devices-activated-last-readings", device_id],
      queryFn: async () => {
        const response = await getDevicesActivatedLastReadings({
          device_id: [device_id]
        })

        return response.rows[0]
      }
    })

    return deviceInfo
  }

  const markerIcon = useMemo(() => {
    return getMarkerIcon(healthCheckState.deviceModalIsOpen, device?.device_id)
  }, [healthCheckState.deviceModalIsOpen, healthCheckState.deviceSelected, device?.device_id, deviceApplication])

  return {
    markerIcon,
    healthCheckActions,
    detailsDeviceActions,
    onMarkerClick
  }
}
