import {
  GetReportRow,
  useDeleteReportStore,
  useI18n,
  useToast,
} from "@group-link-one/grouplink-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useReportListService } from "../../../Services/reportListService/useReportListService";

export const useModalDeleteReport = () => {
  const { deleteReport } = useReportListService();
  const { addToast } = useToast();
  const { t } = useI18n();

  const queryClient = useQueryClient();

  const { state: deleteReportState, actions: deleteReportActions } =
    useDeleteReportStore();

  const { mutateAsync: onDelete, isPending } = useMutation({
    mutationFn: async () => {
      if (!deleteReportState.reportId) return;

      await deleteReport({
        id: deleteReportState.reportId,
      });
    },
    onSuccess: () => {
      addToast({
        title: t("reports.toast.deleted.success.title"),
        message: t("reports.toast.deleted.success.message", {
          reportName: deleteReportState.reportName,
        }),
        type: "success",
      });
      updateReportList();

      deleteReportActions.setModalIsOpen(false);
      deleteReportActions.setReportName(undefined);
      deleteReportActions.setReportId(undefined);
    },
  });

  function updateReportList() {
    const reportsCached: GetReportRow[] | undefined = queryClient.getQueryData([
      "get-reports",
    ]);

    if (reportsCached) {
      queryClient.setQueryData(
        ["get-reports"],

        reportsCached.filter(
          (report) => report.id !== deleteReportState.reportId
        )
      );
    }
  }

  return {
    isPending,
    onDelete,
  };
};
