import { useAuth } from "@group-link-one/gl-auth";
import {
  GLPageNotFound,
  Privacy,
  Terms,
  useGLModalLogout,
  useUserInfoStore,
} from "@group-link-one/grouplink-components";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useFlags } from "../Context/FlagsProvider";
import { LOCAL_STORAGE_KEY_EXTERNAL_TIME, useCheckTimeManipulation } from "../hooks/useCheckTimeManipulation";
import { usePrivileges } from "../hooks/usePrivilleges";
import Logo404 from "../images/art_404.svg";
import RightLogo404 from "../images/bg_404.svg";
import GLLogoDark from "../images/Common/logo-glsmartretail-dark.svg";
import GLLogo from "../images/Common/logo-glsmartretail-light.svg";
import { HelpCenterLayout } from "../layouts/HelpCenterLayout/HelpCenterLayout";
import { MainLayout } from "../layouts/MainLayout/MainLayout";
import { OnboardLayout } from "../layouts/OnboardLayout";
import { PageLayout } from "../layouts/PageLayout";
import { usePageLayout } from "../layouts/usePageLayout";
import { Audit } from "../Pages/Audit/Audit";
import { DeviceList } from "../Pages/DeviceList/DeviceList";
import { EventList } from "../Pages/EventList/eventList";
import { GroupList } from "../Pages/GroupList/GroupList";
import { HealthCheckMap } from "../Pages/HealthCheckMap/Map/HealthCheckMap";
import { NewPassword } from "../Pages/Onboard/CreateNewPassword/newPassword";
import { SignUp } from "../Pages/Onboard/SignUp/signUp";
import { Reports } from "../Pages/ReportList/ReportList";
import { GroupsUsers } from "../Pages/Settings/GroupsUsers/organizationUsers";
import { LoginSecurity } from "../Pages/Settings/LoginSecurity/loginSecurity";
import { MyAccount } from "../Pages/Settings/MyAccount/myAccount";
import { Overview } from "../Pages/Settings/Overview/overview";
import { Users } from "../Pages/Settings/Users/organizationUsers";
import { VerifyRoute } from "./VerifyRoute";

export const AuthenticatedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { manipulateReduxValues, accessToken, accessTokenIsValid, userGroups } = useAuth();
  const { hasPrivileges, user, hasBetaPrivileges, flagsAlreadyLoaded } = useFlags();

  const { handleOpenModalLogout, modalLogoutIsOpenOpen } = useGLModalLogout();
  const { getHeaderNavList } = usePageLayout()
  const { pages } = usePrivileges()
  const { initializeTime } = useCheckTimeManipulation()
  const {
    actions: userInfoActions,
  } = useUserInfoStore()

  const indexRoute = useMemo(() => {
    return hasPrivileges(["apps_get"])
      ? "/settings/overview"
      : "/settings/overview";
  }, [user]);

  useEffect(() => {
    manipulateReduxValues("SET_ACTION_ON_IDLE", handleOpenModalLogout);
  }, []);

  useEffect(() => {
    if (accessToken && !accessTokenIsValid && !modalLogoutIsOpenOpen) {
      handleOpenModalLogout();
    }
  }, [])

  useEffect(() => {
    const hasKeyExternalTime = localStorage.getItem(LOCAL_STORAGE_KEY_EXTERNAL_TIME)
    if (accessToken && !hasKeyExternalTime) initializeTime()
  }, [accessToken])

  useEffect(() => {
    userInfoActions.setUserGroups(userGroups || [])
    userInfoActions.setHasBetaPrivilleges(hasBetaPrivileges())
  }, [user, userGroups, flagsAlreadyLoaded])

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route
          element={<VerifyRoute needAuth={true} element={<MainLayout />} />}
        >
          <Route
            path="network"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout />}
                onlyPrivileges
                userHasPrivileges={pages.eventList}
              />
            }
          >
            <Route
              path="event-list"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<EventList />}
                  onlyPrivileges
                  userHasPrivileges={pages.eventList}
                />
              }
            />
          </Route>

          <Route
            path="settings"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout headerNavListItems={getHeaderNavList("settings")} />}
                onlyPrivileges
                userHasPrivileges={Object.values(pages.settings).some((value) => value)}
              />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Navigate to="overview" />}
                  onlyPrivileges
                  userHasPrivileges={pages.settings.overview}
                />
              }
            />
            <Route
              path="overview"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Overview />}
                  onlyPrivileges
                  userHasPrivileges={pages.settings.overview}
                />
              }
            />

            <Route
              path="my-account"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<MyAccount />}
                  onlyPrivileges
                  userHasPrivileges={pages.settings.myAccount}
                />
              }
            />

            <Route
              path="login-security"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<LoginSecurity />}
                  onlyPrivileges
                  userHasPrivileges={pages.settings.loginSecurity}
                />
              }
            />

            <Route
              path="users"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Users />}
                  onlyPrivileges
                  userHasPrivileges={pages.settings.users}
                />
              }
            />
            <Route
              path="groups-users"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<GroupsUsers />}
                  onlyPrivileges
                  userHasPrivileges={pages.settings.groupsUsers}
                />
              }
            />

            <Route
              path="audit"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Audit />}
                  onlyPrivileges
                  userHasPrivileges={pages.settings.audit}
                />
              }
            />
          </Route>

          <Route
            path="reports"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout />}
                onlyPrivileges
                userHasPrivileges={pages.reports}
              />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  onlyPrivileges
                  userHasPrivileges={pages.reports}
                  element={<Reports />}
                />
              }
            />
          </Route>

          <Route
            path="devices"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout />}
                onlyPrivileges
                userHasPrivileges={pages.deviceList || pages.deviceGroupList}
              />
            }
          >

            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<DeviceList />}
                  onlyPrivileges
                  userHasPrivileges={pages.deviceList}
                />
              }
            />

            <Route
              path="groups"
              element={
                <VerifyRoute
                  needAuth={true}
                  onlyPrivileges
                  userHasPrivileges={pages.deviceGroupList}
                  element={<GroupList />}
                />
              }
            />
          </Route>

          <Route
            path="health-check"
            element={
              <VerifyRoute
                needAuth={true}
                onlyBetaPrivileges
                onlyPrivileges
                userHasPrivileges={pages.healthCheck}
                element={<PageLayout />}
              />
            }
          >

            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  onlyBetaPrivileges
                  onlyPrivileges
                  userHasPrivileges={pages.healthCheck}
                  element={<HealthCheckMap />}
                />
              }
            />
          </Route>

          <Route
            index
            path="/"
            element={
              <VerifyRoute
                needAuth={true}
                element={<Navigate to={indexRoute} />}
              />
            }
          />
        </Route>

        {accessToken && (
          <>
            <Route element={<OnboardLayout />}>
              <Route path="/join-organization" element={<SignUp />} />
              <Route path="/new-password" element={<NewPassword />} />
            </Route>

            <Route element={<HelpCenterLayout />}>
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
            </Route>

            <Route
              path="*"
              element={
                <GLPageNotFound
                  logoOptions={{
                    src: {
                      logoLight: GLLogo,
                      logoDark: GLLogoDark,
                    },
                    backgroundImage: RightLogo404,
                    alt: "GroupLink logo",
                    logoRight: Logo404,
                  }}
                  onClickButton={() => navigate("/")}
                  title="Oops!"
                  subtitle="Page not found"
                  description="It looks like you were detached from our IoT world..."
                />
              }
            />
          </>
        )}
      </Routes>
    </AnimatePresence>
  );
};
