import {
  IGroupsUser,
  PaginateResponseProps,
} from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import {
  ICreateGroupsBody,
  IRequestParams,
} from "./useOrganizationUsers.types";

export const useOrganizationGroupsService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();
  const getOrganizationGroups = async ({
    id,
    name,
    is_default,
  }: IRequestParams): Promise<PaginateResponseProps<IGroupsUser[]>> => {
    const response = await httpPrivate.get("/org-group", {
      params: {
        id,
        name,
        is_default,
      },
    });

    return response.data;
  };
  const createOrganizationGroup = async (data: ICreateGroupsBody) => {
    return await httpPrivate.post("/org-group", data);
  };
  const updateOrganizationGroup = async (
    id: number,
    data: Partial<ICreateGroupsBody>
  ) => {
    return await httpPrivate.patch(`/org-group/${id}`, data);
  };

  const deleteOrganizationGroup = async (id: number) => {
    return await httpPrivate.delete(`/org-group/${id}`);
  };

  return {
    getOrganizationGroups,
    createOrganizationGroup,
    updateOrganizationGroup,
    deleteOrganizationGroup,
  };
};
