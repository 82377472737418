import { useAuth } from "@group-link-one/gl-auth";
import { useNavigate } from "react-router-dom";

export const useOverview = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return {
    user,
    navigate,
  };
};
