export const LOCAL_STORAGE_KEY_EXTERNAL_TIME = "@initialExternalTime";

export const useCheckTimeManipulation = () => {

  async function getExternalTime() {
    const response = await fetch("https://cloudflare.com/cdn-cgi/trace");
    const data = await response.text();

    const serverTimestamp = Number(
      data.split("\n").find((line) => line.includes("ts="))?.split("=")[1]
    ).toFixed(0);

    const timestampToMilliseconds = Number(serverTimestamp) * 1000;
    const timestampUTC = new Date(timestampToMilliseconds);

    return {
      iso: timestampUTC.toISOString(),
      milliseconds: timestampToMilliseconds
    }
  }

  async function initializeTime() {

    if (localStorage.getItem(LOCAL_STORAGE_KEY_EXTERNAL_TIME)) return

    const { iso } = await getExternalTime();
    localStorage.setItem(LOCAL_STORAGE_KEY_EXTERNAL_TIME, iso);
  }

  return { initializeTime, getExternalTime };
}
