
import { useI18n, useToast } from "@group-link-one/grouplink-components";

import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import { useDeviceListStore } from "../store/device-list-store";


export const useModalDecomission = () => {

  const {
    state: deviceListState,
    actions: deviceListActions,
  } = useDeviceListStore();

  const { t } = useI18n();

  const { deviceDecomission } = useDeviceListService();
  const { addToast } = useToast();

  function onCancel() {
    deviceListActions.setModalDecomissionIsOpen(false);
  }

  async function onDecomission() {
    if (!deviceListState.deviceId) return;

    try {
      deviceListActions.setIsDecomissionLoading(true);
      await deviceDecomission(deviceListState.deviceId);

      addToast({
        title: t("deviceList.decomissionModal.onSuccess.title"),
        message: t("deviceList.decomissionModal.onSuccess.description"),
      })

      onCancel();
    } catch (error) {
      addToast({
        title: t("deviceList.decomissionModal.onError.title"),
        message: t("deviceList.decomissionModal.onError.description"),
        type: "error",
      })
    } finally {
      deviceListActions.setIsDecomissionLoading(false);
    }

  }

  return {
    t,
    deviceListState,
    deviceListActions,
    onCancel,
    onDecomission,

  };
};
