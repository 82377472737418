import { GL_COLORS, GL_EYE, GL_FOLLOWER, GL_GLStation, GL_UNLOCK, GLOnboardingModal, GLOnboardingModalList, ThemeChanger } from "@group-link-one/grouplink-components";
import { useEffect } from "react";

import { useFireStore } from "../Context/FireStoreProvider";
import { IsMaintenance, useFlags } from "../Context/FlagsProvider";
import { ImpersonateModal } from "../Pages/Impersonate/ImpersonateModal";
import { AuthRoutes } from "./auth.routes";
import { AuthenticatedRoutes } from "./authenticated.routes";

const listOnboarding: GLOnboardingModalList[] = [
  {
    title: "Device Group",
    description: "Now you can create a group of devices and manage them easily.",
    icon: <GL_GLStation size={45} fill={GL_COLORS.ACCENT_COLOR} />,
  },
  {
    title: "User Group",
    description: "Now you can create a group of users and manage them easily.",
    icon: <GL_FOLLOWER size={45} fill={GL_COLORS.SUCCESS} />,
  },
  {
    title: "User Role",
    description: "Now you can create a role and assign it to a user.",
    icon: <GL_UNLOCK size={45} fill={GL_COLORS.PURPLE} />,
  },
  {
    title: "Audit Log",
    description: "Now you can see the history of the actions performed in the system.",
    icon: <GL_EYE size={45} fill={GL_COLORS.WARNING} />,
  },
]

export const Routes = () => {
  const {
    customMessageOptions,
    hasToShowOnboard,
    hasToShowWelcome,
    hasToShowCustomMessage,
    updateUserOnboard
  } = useFireStore()

  const { hasBetaPrivileges } = useFlags()

  useEffect(() => {
    ThemeChanger({
      light: "#F6F8F9",
      dark: "#313337",
    });
  }, []);

  return (
    <>
      <IsMaintenance>
        <AuthRoutes />
        <AuthenticatedRoutes />
        <ImpersonateModal />
      </IsMaintenance>

      {hasBetaPrivileges() && hasToShowOnboard && !hasToShowWelcome && !hasToShowCustomMessage && (
        <GLOnboardingModal
          list={listOnboarding}
          type="new-version"
          onClick={() => updateUserOnboard("onboard")}
        />
      )}

      {hasBetaPrivileges() && hasToShowWelcome && !hasToShowOnboard && !hasToShowCustomMessage && (
        <GLOnboardingModal
          list={listOnboarding}
          type="welcome"
          onClick={() => updateUserOnboard("welcome")}
        />
      )}

      {hasBetaPrivileges() && hasToShowCustomMessage && !hasToShowWelcome && !hasToShowOnboard &&  (
        <GLOnboardingModal
          list={customMessageOptions?.options}
          type="custom"
          customTitle={customMessageOptions?.title}
          customDescription={customMessageOptions?.description}
          onClick={() => updateUserOnboard("custom-message")}
        />
      )}

    </>
  );
};
