import { useAuth } from "@group-link-one/gl-auth";
import { GLHelpCenter } from "@group-link-one/grouplink-components";
import { Outlet, useNavigate } from "react-router-dom";

import Logo from "../../images/logo/logo.svg";

export function HelpCenterLayout() {

  const { accessTokenIsValid }  = useAuth()
  const navigate = useNavigate();

  return (
    <GLHelpCenter
      image={Logo}
      userIsLogged={accessTokenIsValid}
      onHeaderButtonClick={() => navigate("/")}
    >
      <Outlet />
    </GLHelpCenter>
  );
}
