import { useAuth } from "@group-link-one/gl-auth";
import { GL_COLORS, GL_GLStation, GLCard } from "@group-link-one/grouplink-components";

import {
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesPendingActivationResponse,
} from "../../../Services/deviceListService/useDeviceListService.types";
import { getOptionsByApplication } from "../utils/getOptionsByApplication";
import { useDeviceListCard } from "./useDeviceListCard";

interface DeviceListCardProps {
  item:
    | GetDevicesActivatedLastReadingsResponse
    | GetDevicesPendingActivationResponse;
}

export function DeviceListCard({ item }: DeviceListCardProps) {
  const {
    features,
    hasBetaPrivileges,
    isMobile,
    onAddressClick,
    onDetailsClick,
    onEditClick,
    onRegisterDeviceClick,
    onClickDecomission
  } = useDeviceListCard();

  const { user } = useAuth();

  function isPendingActivationCard(
    card: unknown
  ): card is GetDevicesPendingActivationResponse {
    const hasLastSeen =
      "last_seen" in (card as GetDevicesPendingActivationResponse);

    return !hasLastSeen;
  }

  if (isPendingActivationCard(item)) {
    return (
      <GLCard
        key={item.device_id}
        status="pending"
        variant="ut-device-list-card"
        id={String(item.device_id)}
        applicationTypeOptions={{
          icon: <GL_GLStation size={18} fill={GL_COLORS.WHITE} />,
          color: GL_COLORS.DISABLED_FONT_COLOR
        }}
        hasAlerts={true}
        hasPermissionToRegisterDevice={user?.use_case === "util_kwh" && features.devices.activateDevice}
        onRegisterDeviceClick={onRegisterDeviceClick}
      />
    );
  }

  const hasAlerts = item.channels.some((channel) => channel.alerts?.length > 0);

  const { icon, color } = getOptionsByApplication({
    type: item.application,
    deviceMeasurementCategory: item.meta.device_measurement_category,
  })

  return (
    <GLCard
      key={item.device_id}
      variant="ut-device-list-card"
      applicationTypeOptions={{
        icon: icon(18, GL_COLORS.WHITE),
        color,
      }}
      activated_by={{
        name: item.channels[0] ? item.channels[0].activated_by : "--",
        email: item.channels[0] ? item.channels[0].activated_by_email : "--",
      }}
      status="active"
      hasAlerts={hasAlerts}
      name={item.channels[0] ? item.channels[0].name : "--"}
      address={item.meta.display_address || "--"}
      id={String(item.device_id)}
      lastEvent={item.last_seen}
      isMobile={isMobile}
      isToShowDetailsButton={!!item.channels[0] && features.devices.showDetails}
      isWaitingForActivation={!item.channels[0]}
      canEdit={!!item.channels[0] && features.devices.editDevice}
      canDecommission={features.devices.decommissionDevice && hasBetaPrivileges()}
      onAddressClick={() => {
        onAddressClick(item.meta.latitude, item.meta.longitude);
      }}
      onOpenDetails={() => onDetailsClick(item)}
      onOpenEdit={() => onEditClick(item)}
      onDecommission={() => onClickDecomission(item)}
    />
  );
}
