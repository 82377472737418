// Contexto com hook useFlags
import { AuthContextValues, useAuth } from "@group-link-one/gl-auth";
import { GLPageNotFound, useI18n } from "@group-link-one/grouplink-components";
import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
} from "firebase/remote-config";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import React from "react";

import Logo404 from "../images/art_404.svg";
import RightLogo404 from "../images/bg_404.svg";
import GLLogoDark from "../images/Common/logo-glutilities-dark.svg";
import GLLogo from "../images/Common/logo-glutilities-light.svg";
import { firebaseApp } from "../Services/remoteConfig";


interface FlagsProviderProps {
  children: React.ReactNode;
}

interface RemoteConfig {
  [key: string]: any;
}

interface FlagConfig {
  flags: RemoteConfig;
  user?: AuthContextValues["user"];
  flagsAlreadyLoaded: boolean;
  isMaintenance: boolean;
  hasPrivileges: (flags: string[]) => boolean;
  userHasPrivileges: (privileges: string[]) => boolean;
  hasBetaPrivileges: (betaType?: "beta" | "beta_client") => boolean;
}

const remoteConfig = getRemoteConfig(firebaseApp);
const FlagsContext = createContext<FlagConfig>({
  flags: {},
  flagsAlreadyLoaded: false,
  isMaintenance: false,
  hasPrivileges: () => false,
  userHasPrivileges: () => false,
  hasBetaPrivileges: () => false,
});
// Only for development
remoteConfig.settings.minimumFetchIntervalMillis = 20000;

/**
 * FlagsProvider component.
 *
 * @param children - ReactNode as children components.
 * @returns JSX.Element
 */
export const FlagsProvider: React.FC<FlagsProviderProps> = ({
  children,
}: FlagsProviderProps): JSX.Element => {
  const [flags, setFlags] = useState<FlagConfig["flags"]>({});
  const { user } = useAuth();
  const userPrivileges = user?.privileges || [];
  const userEmail = user?.email || "";

  const flagsAlreadyLoaded = Object.keys(flags).length > 0;

  const userHasPrivileges = (privileges: string[]) => {
    return userPrivileges.some((privilege) => privileges.includes(privilege));
  };

  const hasBetaPrivileges = (betaType?: "beta" | "beta_client") => {
    return (
      flags[betaType ? betaType : "beta"] &&
      flags[betaType ? betaType : "beta"]
        ?.split(",")
        .map((privilege: string) => privilege.trim())
        .includes(userEmail)
    );
  };

  const hasPrivileges = (_flags: string[]) => {
    const flagPrivileges = _flags.flatMap(
      (flag) =>
        flags[flag]?.split(",").map((privilege: string) => privilege.trim()) ||
        []
    );

    if (flagPrivileges.includes("NO_PERMISSION")) {
      return true
    }

    if (flagPrivileges.includes("disabled")) {
      return false;
    }

    if (userPrivileges) {
      return userHasPrivileges(flagPrivileges);
    }

    return false;
  };

  const isMaintenance = useMemo(() => {
    return flags.is_under_maintenance === true;
  }, [flags])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const activated = await fetchAndActivate(remoteConfig);
        if (!activated) console.warn("not activated");
        const remoteFlags = await getAll(remoteConfig);
        const newFlags = Object.fromEntries(
          Object.entries(
            remoteFlags as unknown as { [key: string]: { _value: any } }
          ).map(([key, config]) => {
            const { _value } = config;
            const flagValue =
              _value === "true" || _value === "false"
                ? _value === "true"
                : _value;
            return [key, flagValue];
          })
        );
        setFlags({ ...flags, ...newFlags });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);


  return (
    <FlagsContext.Provider
      value={{
        flags,
        hasPrivileges,
        userHasPrivileges,
        user,
        isMaintenance,
        flagsAlreadyLoaded,
        hasBetaPrivileges,
      }}
    >
      {children}
    </FlagsContext.Provider>
  );
};
export const useFlags = () => {
  const context = useContext(FlagsContext);
  if (context === undefined) {
    throw new Error("useFlags must be used within a FlagsProvider");
  }
  return context;
};

export const FeatureTemp = ({ children }: FlagsProviderProps) => {
  const flags = useFlags();

  const hasTemp = flags.hasBetaPrivileges();

  if (!hasTemp) {
    return null;
  }

  return <div style={{ border: "1px dashed red" }}>{children}</div>;
};

export const IsMaintenance = ({ children }: FlagsProviderProps): JSX.Element => {
  const { isMaintenance } = useFlags();
  const { t } = useI18n();

  if (!isMaintenance) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
     <GLPageNotFound
        logoOptions={{
          src: {
            logoLight: GLLogo,
            logoDark: GLLogoDark,
          },
          backgroundImage: RightLogo404,
          alt: "GroupLink logo",
          logoRight: Logo404,
        }}
        title=""
        subtitle={t("maintenance.subtitle")}
        description={t("maintenance.description")}
      />
  );
}

export default FlagsProvider;
