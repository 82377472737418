export function GLBigNumberLastReading(props: { fill?: string }) {
  return (
    <svg id="icon-log-in" xmlns="http://www.w3.org/2000/svg" width="110.164" height="110.164" viewBox="0 0 110.164 110.164">
      <defs>
        <linearGradient id="linear-gradient" x1="0.706" y1="-0.47" x2="0.412" y2="1.292" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor={props.fill || "#03f"} />
          <stop offset="1" stopColor="#fff" stopOpacity="0"/>
        </linearGradient>
      </defs>
      <rect id="Retângulo_4987" data-name="Retângulo 4987" width="110" height="110" fill="none"/>
      <g id="Grupo_2393" data-name="Grupo 2393" transform="translate(29.377)">
        <g id="Grupo_2392" data-name="Grupo 2392">
          <path id="Caminho_230" data-name="Caminho 230" d="M75.877,0H20.91A12.924,12.924,0,0,0,8,12.91V30.123h8.607V12.91a4.308,4.308,0,0,1,4.3-4.3H75.877a4.308,4.308,0,0,1,4.3,4.3V97.254a4.308,4.308,0,0,1-4.3,4.3H20.91a4.308,4.308,0,0,1-4.3-4.3V80.041H8V97.254a12.924,12.924,0,0,0,12.91,12.91H75.877a12.925,12.925,0,0,0,12.91-12.91V12.91A12.924,12.924,0,0,0,75.877,0Z" transform="translate(-8 0)" fill="url(#linear-gradient)"/>
        </g>
      </g>
      <g id="Grupo_2395" data-name="Grupo 2395" transform="translate(0 26.259)">
        <g id="Grupo_2394" data-name="Grupo 2394">
          <path id="Caminho_231" data-name="Caminho 231" d="M55.658,131.716,49.1,138.275,66.724,155.9H0v9.276H66.724L49.1,182.8l6.559,6.559,28.823-28.823Z" transform="translate(0 -131.716)" fill="url(#linear-gradient)"/>
        </g>
      </g>
    </svg>
  )
}
