import {
  DateRange,
  GLRelativeListOption,
  i18n,
} from "@group-link-one/grouplink-components";
import { subDays } from "date-fns";
import { create } from "zustand";

import {
  GetDevicesActivatedDailyReadingsResponse,
  GetDevicesActivatedLastReadingsResponse,
} from "../../../Services/deviceListService/useDeviceListService.types";

export interface DetailsDeviceStoreState {
  modalIsOpen: boolean;
  device: GetDevicesActivatedLastReadingsResponse | undefined;

  channelActive: number | undefined;

  channelZero: GetDevicesActivatedDailyReadingsResponse["rows"] | undefined;
  channelOne: GetDevicesActivatedDailyReadingsResponse["rows"] | undefined;
  currentData: GetDevicesActivatedDailyReadingsResponse["rows"] | undefined;
  // currentDowData: GetDevicesDowSummaryRow[] | undefined;
  // currentDowDataFormatted: number[][];

  currentRelativeTime: GLRelativeListOption | undefined;
  currentDateRange: DateRange | undefined;

  range: {
    from: Date | undefined;
    to: Date | undefined;
  };

  isLoading: boolean;
}

export interface DetailsDeviceStoreActions {
  setModalIsOpen: (modalIsOpen: boolean) => void;
  setDevice: (device: GetDevicesActivatedLastReadingsResponse) => void;

  setChannelActive: (channelActive: number) => void;

  setChannelZero: (
    channelZero: GetDevicesActivatedDailyReadingsResponse["rows"]
  ) => void;

  setChannelOne: (
    channelOne: GetDevicesActivatedDailyReadingsResponse["rows"]
  ) => void;

  setCurrentData: (
    currentData: GetDevicesActivatedDailyReadingsResponse["rows"]
  ) => void;

  setRange: (date: DetailsDeviceStoreState["range"]) => void;
  // setCurrentDowData: (
  //   currentDowData: GetDevicesDowSummaryRow[] | undefined
  // ) => void;

  // setDowDataFormatted: (currentDowDataFormatted: number[][]) => void;

  setCurrentRelativeTime: (option?: GLRelativeListOption) => void;
  setCurrentDateRange: (range?: DateRange) => void;

  setIsLoading: (isLoading: boolean) => void;

  resetAll: () => void;
}

export interface DetailsDeviceStore {
  state: DetailsDeviceStoreState;
  actions: DetailsDeviceStoreActions;
}

export const useDetailsDeviceStore = create<DetailsDeviceStore>((set) => ({
  state: {
    modalIsOpen: false,
    device: undefined,
    currentData: undefined,
    currentDateRange: undefined,

    channelActive: 0,

    channelOne: undefined,
    channelZero: undefined,

    // currentDowDataFormatted: [],
    currentRelativeTime: {
      id: "last_7_days",
      range: {
        from: subDays(new Date(), 7),
        to: new Date(),
      },
      text: i18n.t("days.last7Days"),
    },
    range: {
      from: subDays(new Date(), 7),
      to: new Date(),
    },
    isLoading: false,
  },
  actions: {
    setModalIsOpen: (modalIsOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          modalIsOpen,
        },
      })),

    setDevice: (device) =>
      set((state) => ({
        state: {
          ...state.state,
          device,
        },
      })),

    setChannelActive: (channelActive) =>
      set((state) => ({
        state: {
          ...state.state,
          channelActive,
        },
      })),

    setChannelOne: (channelOne) =>
      set((state) => ({
        state: {
          ...state.state,
          channelOne,
        },
      })),

    setChannelZero: (channelZero) =>
      set((state) => ({
        state: {
          ...state.state,
          channelZero,
        },
      })),

    setCurrentDateRange: (currentDateRange) =>
      set((state) => ({
        state: {
          ...state.state,
          currentDateRange,
        },
      })),

    setCurrentRelativeTime: (currentRelativeTime) =>
      set((state) => ({
        state: {
          ...state.state,
          currentRelativeTime,
        },
      })),

    setCurrentData: (currentData) =>
      set((state) => ({
        state: {
          ...state.state,
          currentData,
        },
      })),

    setRange: (range) =>
      set((state) => ({
        state: {
          ...state.state,
          range,
        },
      })),

    setIsLoading: (isLoading) =>
      set((state) => ({
        state: {
          ...state.state,
          isLoading,
        },
      })),

    resetAll: () =>
      set((state) => ({
        state: {
          ...state.state,
          modalIsOpen: false,
          device: undefined,
          currentData: undefined,
          range: {
            from: subDays(new Date(), 7),
            to: new Date(),
          },
          currentDateRange: undefined,
          currentRelativeTime: {
            id: "last_7_days",
            range: {
              from: subDays(new Date(), 7),
              to: new Date(),
            },
            text: i18n.t("days.last7Days"),
          },
          isLoading: false,
          channelActive: 0,
          channelOne: undefined,
          channelZero: undefined,
        },
      })),
  },
}));
