import { DateRange,GLRelativeListOption, GLSelectMultipleOptions, i18n } from "@group-link-one/grouplink-components";
import { subDays } from "date-fns";
import { createWithEqualityFn } from "zustand/traditional";

import {
  DeviceMapResponse,
  GetDeviceHealthCheckCountResponse,
  GetDevicesActivatedDailyReadingsResponse,
  GetDevicesActivatedLastReadingsResponse
} from "../../../Services/deviceListService/useDeviceListService.types";

export interface HealthCheckStoreState {
  deviceModalIsOpen: boolean;
  deviceSelected: undefined | GetDevicesActivatedLastReadingsResponse
  deviceSelectedDailyReadings: GetDevicesActivatedDailyReadingsResponse | undefined;
  devicesFetched: DeviceMapResponse[] | undefined;


  currentRelativeTime: GLRelativeListOption | undefined;
  currentDateRange: DateRange | undefined;

  geo_filter: {
    min_lat: number;
    max_lat: number;
    min_long: number;
    max_long: number;
  } | undefined;

  range: {
    from: Date | undefined;
    to: Date | undefined;
  };

  alertFilterValue: GLSelectMultipleOptions[] | undefined;
  alertTypesSelected: GLSelectMultipleOptions[] | undefined;

  devicesHealthCheckCount: GetDeviceHealthCheckCountResponse | null;

  isFetchingDevices: boolean;
  isFetchingDevicesOverview: boolean;
  isFetchingDeviceInfo: boolean;
}

export interface HealthCheckStoreActions {
  setDeviceModalIsOpen: (deviceModalIsOpen: boolean) => void;
  setDeviceSelected: (deviceSelected?: GetDevicesActivatedLastReadingsResponse ) => void;
  setDevicesFetched: (devicesFetched?: DeviceMapResponse[]) => void;
  setDeviceSelectedDailyReadings: (deviceSelectedDailyReadings?: GetDevicesActivatedDailyReadingsResponse) => void;

  setRange: (date: HealthCheckStoreState["range"]) => void;
  setCurrentRelativeTime: (option?: GLRelativeListOption) => void;
  setCurrentDateRange: (range?: DateRange) => void;

  setGeoFilter: (geo_filter: HealthCheckStoreState["geo_filter"]) => void;
  setAlertFilterValue: (alertFilterValue: HealthCheckStoreState["alertFilterValue"]) => void;
  setAlertTypesSelected: (alertTypesSelected: HealthCheckStoreState["alertTypesSelected"]) => void;

  setDevicesHealthCheckCount: (devicesHealthCheckCount: GetDeviceHealthCheckCountResponse | null) => void;

  setIsFetchingDevices: (isFetchingDevices: boolean) => void;
  setIsFetchingDevicesOverview: (isFetchingDevicesOverview: boolean) => void;
  setIsFetchingDeviceInfo: (isFetchingDeviceInfo: boolean) => void;
  resetAll: () => void;
}

export interface HealthCheckStore {
  state: HealthCheckStoreState;
  actions: HealthCheckStoreActions;
}

export const useHealthCheckStore = createWithEqualityFn<HealthCheckStore>((set) => ({
  state: {
    deviceModalIsOpen: false,
    deviceSelected: undefined,
    devicesFetched: undefined,
    deviceSelectedDailyReadings: undefined,

    currentDateRange: undefined,
    currentRelativeTime: {
      id: "last_7_days",
      range: {
        from: subDays(new Date(), 7),
        to: new Date(),
      },
      text: i18n.t("days.last7Days"),
    },
    range: {
      from: subDays(new Date(), 7),
      to: new Date(),
    },

    geo_filter: undefined,

    alertFilterValue: [{ id: "all", text: "Todos" }],
    alertTypesSelected: undefined,
    devicesHealthCheckCount: null,

    isFetchingDevicesOverview: false,
    isFetchingDevices: false,
    isFetchingDeviceInfo: false,
  },
  actions: {
    setDeviceModalIsOpen: (deviceModalIsOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          deviceModalIsOpen,
        }
      })),

    setDeviceSelectedDailyReadings: (deviceSelectedDailyReadings) =>
      set((state) => ({
        state: {
          ...state.state,
          deviceSelectedDailyReadings,
        }
      })),

    setDeviceSelected: (deviceSelected) =>
      set((state) => ({
        state: {
          ...state.state,
          deviceSelected,
        }
      })),

    setDevicesFetched: (devicesFetched) => {
      set((state) =>
        ({
          state: {
            ...state.state,
            devicesFetched,
          }
        })
      )
    },

    setCurrentDateRange: (currentDateRange) =>
      set((state) => ({
        state: {
          ...state.state,
          currentDateRange,
        },
      })),

    setCurrentRelativeTime: (currentRelativeTime) =>
      set((state) => ({
        state: {
          ...state.state,
          currentRelativeTime,
        },
      })),

    setRange: (range) =>
      set((state) => ({
        state: {
          ...state.state,
          range,
        },
      })),

    setGeoFilter: (geo_filter) =>
      set((state) => ({
        state: {
          ...state.state,
          geo_filter,
        }
      })),

    setIsFetchingDevices: (isFetchingDevices) =>
      set((state) => ({
        state: {
          ...state.state,
          isFetchingDevices,
        }
      })),

    setAlertFilterValue: (alertFilterValue) =>
      set((state) => ({
        state: {
          ...state.state,
          alertFilterValue,
        }
      })),

    setAlertTypesSelected: (alertTypesSelected) =>
      set((state) => ({
        state: {
          ...state.state,
          alertTypesSelected,
        }
      })),

    setDevicesHealthCheckCount: (devicesHealthCheckCount) =>
      set((state) => ({
        state: {
          ...state.state,
          devicesHealthCheckCount,
        }
      })),

    setIsFetchingDevicesOverview: (isFetchingDevicesOverview) =>
      set((state) => ({
        state: {
          ...state.state,
          isFetchingDevicesOverview,
        }
      })),

    setIsFetchingDeviceInfo: (isFetchingDeviceInfo) =>
      set((state) => ({
        state: {
          ...state.state,
          isFetchingDeviceInfo,
        }
      })),

    resetAll: () =>
      set((state) => ({
        state: {
          ...state.state,
          deviceModalIsOpen: false,
          deviceSelected: undefined,
          devicesFetched: undefined,
          alertFilterValue: [{ id: "all", text: "Todos" }],
          alertTypesSelected: undefined,
          isFetchingDevices: false,
          currentDateRange: undefined,
          currentRelativeTime: {
            id: "last_7_days",
            range: {
              from: subDays(new Date(), 7),
              to: new Date(),
            },
            text: i18n.t("days.last7Days"),
          },
          geo_filter: undefined,
          range: {
            from: subDays(new Date(), 7),
            to: new Date(),
          }
        }
      })),
  },
}));
