import { GLTextSwitcherOptions } from "@group-link-one/grouplink-components";
import { ReactElement, useState } from "react";

import { CsvTransfer } from "../CsvTransfer/CsvTransfer";
import { GroupDevicesTransferList } from "../TransferList/GroupDevicesTransferList";

export const useAddDevicesBody = () => {
  const [tabActive, setTabActive] = useState(1);

  const tabOptions: GLTextSwitcherOptions[] = [
    {
      id: 1,
      text: "Transfer list",
    },
    {
      id: 2,
      text: "Import CSV",
    },
  ]

  const contentToShowByTab: Record<string, ReactElement> = {
    "tab-1": <GroupDevicesTransferList />,
    "tab-2": <CsvTransfer />,
  }

  return {
    tabActive,
    setTabActive,
    tabOptions,
    contentToShowByTab
  }
}
