import { GLReports } from "@group-link-one/grouplink-components";

import { useFlags } from "../../Context/FlagsProvider";
import { useModalContent } from "./hooks/useModalContent";
import { useModalDeleteReport } from "./hooks/useModalDeleteReport";
import { useReportsContent } from "./hooks/useReportsContent";

export const ReportsListContent = () => {
  const {
    reports,
    reportsIsLoading,
    reportsRefetch,
    isLoadingReportsAvailables,
    reportsAvailables,
  } = useReportsContent();
  const { hasPrivileges } = useFlags()
  const { createReport, isPending, getDevicesActivated } = useModalContent();
  const { isPending: deleteIsPending, onDelete } = useModalDeleteReport();

  return (
    <GLReports
      reportsContentOptions={{ reports, reportsIsLoading, reportsRefetch }}
      reportTypeOptions={{
        isLoadingReportsAvailables,
        reportsAvailables,
      }}
      modalCreateReportContentOptions={{
        hasPermissionToCreate: hasPrivileges(["action_btn_new_report"]),
        maxDateFrom: 150, // Add the maxDateFrom property with the appropriate value
        createReport,
        isPending,
        updateReport: () => {},
        getDevicesActivated,
        platform: "utilities",
      }}
      modalDeleteReportOptions={{ onDelete, deleteIsPending }}
    />
  );
};
