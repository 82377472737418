import {
  GLPaginationProvider,
  useI18n,
} from "@group-link-one/grouplink-components";

import { GroupsUsersPage } from "./organizationUsersPage";

export const GroupsUsers = () => {
  const { t } = useI18n();

  const tabs = [
    {
      id: 1,
      name: t("users.tabs.one"),
      active: undefined,
      count: 0,
      onClick: () => {},
      cacheKey: "all",
    },
    {
      id: 2,
      name: t("users.tabs.two"),
      active: true,
      count: 0,
      onClick: () => {},
      cacheKey: "active",
    },
    {
      id: 3,
      name: t("users.tabs.three"),
      active: false,
      count: 0,
      onClick: () => {},
      cacheKey: "inactive",
    },
    {
      id: 4,
      name: t("users.tabs.four"),
      active: false,
      count: 0,
      onClick: () => {},
      cacheKey: "invites",
    },
  ];

  const lastNextPageToken = {
    all: {
      token: undefined,
      page: undefined,
    },
    active: {
      token: undefined,
      page: undefined,
    },
    inactive: {
      token: undefined,
      page: undefined,
    },
  };

  return (
    <GLPaginationProvider tabs={tabs} lastNextPageToken={lastNextPageToken}>
      <GroupsUsersPage />
    </GLPaginationProvider>
  );
};
