import { GLTransferList } from "@group-link-one/grouplink-components";

import { useGroupDevicesTransferList } from "./useGroupDevicesTransferList";

export function GroupDevicesTransferList() {
  const {
    t,
    devicesAddeds,
    availableDevicesSearchIsCorrect,
    devicesAvailables,
    groupDevicesState,
    leftSideOptions,
    rightSideOptions,
    onSearch,
    onTransferItem,
  } = useGroupDevicesTransferList();
  return (
    <GLTransferList
      leftSideList={devicesAvailables}
      leftSideOptions={leftSideOptions}
      leftSideTooltipIsOpen={!availableDevicesSearchIsCorrect}
      leftSideTooltipText={t("deviceList.search.tooltip")}
      leftSideIsFetching={groupDevicesState.isFetchingMoreAvailableDevices}
      leftSideSearchHasFailure={true}
      leftSideTexts={{
        search: t("groupsDevices.addDevicesModal.transferList.leftSide.search"),
        emptyTitle:
          groupDevicesState.availableDevicesSearch.length === 0
            ? t("groupsDevices.addDevicesModal.transferList.leftSide.emptyState.title")
            : t(
                "groupsDevices.addDevicesModal.transferList.leftSide.emptyStateFromSearch.title"
              ),
        emptyBody:
          groupDevicesState.availableDevicesSearch.length === 0
            ? t(
                "groupsDevices.addDevicesModal.transferList.leftSide.emptyState.description"
              )
            : t(
                "groupsDevices.addDevicesModal.transferList.leftSide.emptyStateFromSearch.description"
              ),
      }}
      rightSideList={devicesAddeds}
      rightSideOptions={rightSideOptions}
      rightSideTooltipIsOpen={false}
      rightSideTooltipText={undefined}
      rightSideIsFetching={groupDevicesState.isFetchingMoreDevicesInGroup}
      rightSideTexts={{
        search: t("groupsDevices.addDevicesModal.transferList.rightSide.search"),
        emptyTitle:
          groupDevicesState.devicesInGroupSearch.length === 0
            ? t("groupsDevices.addDevicesModal.transferList.rightSide.emptyState.title")
            : t(
                "groupsDevices.addDevicesModal.transferList.rightSide.emptyStateFromSearch.title"
              ),
        emptyBody:
          groupDevicesState.devicesInGroupSearch.length === 0
            ? t(
                "groupsDevices.addDevicesModal.transferList.rightSide.emptyState.description"
              )
            : t(
                "groupsDevices.addDevicesModal.transferList.rightSide.emptyStateFromSearch.description"
              ),
      }}
      onTransferItemToLeft={(items) => onTransferItem(items, "left")}
      onTransferItemToRight={(items) => onTransferItem(items, "right")}
      onSearch={(value, side) => onSearch(value, side)}
    />
  );
}
