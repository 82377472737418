import {
  GLHeaderNavListItemProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useMemo } from "react";

import { useFlags } from "../Context/FlagsProvider";
import { usePrivileges } from "../hooks/usePrivilleges";

enum SettingsRoutes {
  DEFAULT = "/settings",
  OVERVIEW = "/settings/overview",
  MY_ACCOUNT = "/settings/my-account",
  LOGIN_SECURITY = "/settings/login-security",
  USERS = "/settings/users",
  GROUPS_USERS = "/settings/groups-users",
  ORGANIZATIONS = "/settings/organizations",
  APPS = "/settings/apps",
  AUDIT = "/settings/audit",
}

enum HealthCheckRoutes {
  MAP = "/health-check/map",
  DEFAULT = "/health-check",
}

type TypeLayout = "settings" | "health-check";

export function usePageLayout() {
  const { t } = useI18n();
  const { flags, hasPrivileges, user, hasBetaPrivileges, flagsAlreadyLoaded } =
    useFlags();

  const { pages } = usePrivileges();

  const settings: GLHeaderNavListItemProps[] = useMemo(() => {
    const items = [
      {
        id: 1,
        title: t("pages.deviceOverview.overview"),
        path: SettingsRoutes.OVERVIEW,
        isToAppear: pages.settings.overview,
      },
      {
        id: 2,
        title: t("pages.settings.myAccount"),
        path: SettingsRoutes.MY_ACCOUNT,
        isToAppear: pages.settings.myAccount,
      },
      {
        id: 3,
        title: t("pages.settings.loginSecurity"),
        path: SettingsRoutes.LOGIN_SECURITY,
        isToAppear:  pages.settings.loginSecurity,
      },
      {
        id: 4,
        title: t("users.subtitle"),
        path: SettingsRoutes.USERS,
        isToAppear: pages.settings.users,
      },
      {
        id: 5,
        title: t("audit.title"),
        path: SettingsRoutes.AUDIT,
        isToAppear: pages.settings.audit,
      },
      {
        id: 6,
        title: t("groupsUsers.subtitle"),
        path: SettingsRoutes.GROUPS_USERS,
        isToAppear: pages.settings.groupsUsers,
      },
    ];

    return items.filter((item) => item.isToAppear);
  }, [flags, user, flagsAlreadyLoaded, hasPrivileges, hasBetaPrivileges]);

  const healthCheck: GLHeaderNavListItemProps[] = useMemo(() => {
    const items = [
      {
        id: 1,
        title: "Overview",
        isToAppear: hasBetaPrivileges("beta"),
        path: HealthCheckRoutes.DEFAULT,
      },
      {
        id: 2,
        title: "Mapa de Dispositivos",
        isToAppear: hasBetaPrivileges("beta"),
        path: HealthCheckRoutes.MAP,
      },
    ];

    return items.filter((item) => item.isToAppear);
  }, [flags, user, flagsAlreadyLoaded, hasPrivileges, hasBetaPrivileges]);

  function getHeaderNavList(type: TypeLayout) {
    switch (type) {
      case "settings":
        return settings;
      case "health-check":
        return healthCheck;
      default:
        return [];
    }
  }

  return {
    getHeaderNavList
  };
}
