import {
  IGroupsUser,
  PaginateResponseProps,
} from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useGroupService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getOrganizationGroups = async (): Promise<PaginateResponseProps<IGroupsUser[]>> => {
    const response = await httpPrivate.get("/org-group");

    return response.data;
  };

  return {
    getOrganizationGroups,
  };
};
