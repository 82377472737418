import { useI18n } from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

export const useAuditCard = () => {
  const { t } = useI18n();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const translatingCategory = (category: string) => {
    let translatedCategory = "" as string;

    switch (category) {
      case "User Management":
        translatedCategory = t(
          "audit.list.cards.category.types.userManagement"
        );
        break;
      case "Campaign Management":
        translatedCategory = t(
          "audit.list.cards.category.types.campaignManagement"
        );
        break;
      case "System Alerts":
        translatedCategory = t("audit.list.cards.category.types.systemAlerts");
        break;
      case "Account Security":
        translatedCategory = t(
          "audit.list.cards.category.types.accountSecurity"
        );
        break;
      case "Data Management":
        translatedCategory = t(
          "audit.list.cards.category.types.dataManagement"
        );
        break;
      case "Group Management":
        translatedCategory = t(
          "audit.list.cards.category.types.groupManagement"
        );
        break;
      case "Device Activation":
        translatedCategory = t(
          "audit.list.cards.category.types.deviceActivation"
        );
        break;
      default:
        translatedCategory = category;
        break;
    }

    return translatedCategory;
  };

  return {
    isMobile,
    translatingCategory,
  };
};
