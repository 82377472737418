import { useAuth } from "@group-link-one/gl-auth";
import {
  GetReportRow,
  ReportStatus,
  ReportType,
  useCreateReportStore,
  useGetParamsToCreateReport,
  useI18n,
  useReportTypeStore,
  useToast,
} from "@group-link-one/grouplink-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useReportListService } from "../../../Services/reportListService/useReportListService";

export const useModalContent = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { t } = useI18n();

  const { getParamsToCreateReport } = useGetParamsToCreateReport({ user });

  const {
    createReport: createReportFn,
    devicesActivated,
    devicesActivatedCount,
  } = useReportListService();

  const { state: reportTypeState, actions: reportTypeActions } =
    useReportTypeStore();

  const { actions: createReportActions } = useCreateReportStore();

  const queryClient = useQueryClient();

  const { mutateAsync: createReport, isPending } = useMutation({
    mutationKey: ["create-report"],
    mutationFn: async () => {
      const reportTypeId = reportTypeState.reportTypeSelected.id;
      if (!reportTypeId) return;

      const paramsToCreate = getParamsToCreateReport(reportTypeId);

      if (!paramsToCreate) return;

      paramsToCreate.params = {
        ...paramsToCreate.params,
        device_id: reportTypeState.device_id,
      };

      const response = await createReportFn({
        ...paramsToCreate,
      });

      return {
        id: Number(response.id),
        ...paramsToCreate,
      };
    },
    onSuccess: (data) => {
      addToast({
        type: "success",
        title: t("reports.toast.created.success.title"),
        message: t("reports.toast.created.success.message"),
      });

      const newReportStatusFormatted = formatReportStatus();

      const newReport: GetReportRow = {
        id: data?.id || 0,
        name: reportTypeState.reportName,
        created_at: new Date().toISOString(),
        devices_count: 0,
        download_url: "",
        report_type: reportTypeState.reportTypeSelected.id as ReportType,
        status: newReportStatusFormatted,
        params_from: String(reportTypeState.reportDateRange?.from),
        params_until: String(reportTypeState.reportDateRange?.to),
      };

      updateReportList(newReport);

      createReportActions.resetAll();
      reportTypeActions.resetAll();
    },
    onError: () => {
      addToast({
        type: "error",
        title: "Error creating report",
        message: "An error occurred while creating the report",
      });
    },
  });

  function formatReportStatus(): ReportStatus {
    const reportsCached = getReportsCached();

    if (reportsCached) {
      const reportStatus = reportsCached.find(
        (report) => report.status === "running"
      );

      if (reportStatus) {
        return "pending";
      }
    }

    return "running";
  }

  async function updateReport() {
    // some code
  }

  function updateReportList(newReport: GetReportRow) {
    const reportsCached = getReportsCached();

    if (reportsCached) {
      queryClient.setQueryData(
        ["get-reports"],
        reportsCached.concat(newReport)
      );
    }
  }

  function getReportsCached() {
    return queryClient.getQueryData<GetReportRow[]>(["get-reports"]);
  }

  async function getDevicesActivated() {
    await devicesActivated({
      device_name: reportTypeState.deviceNameReportFilter,
      remote_id: reportTypeState.deviceRemoteIdReportFilter,
    });

    await devicesActivatedCount({
      device_name: reportTypeState.deviceNameReportFilter,
      remote_id: reportTypeState.deviceRemoteIdReportFilter,
    }).then((res) => {
      reportTypeActions.setDeviceId(res.device_ids);

      if (res.count === 0) {
        addToast({
          type: "warning",
          title: t(
            "reports.modalStepper.stepper.one.form.filter.toast.two.title"
          ),
          message: t(
            "reports.modalStepper.stepper.one.form.filter.toast.two.message"
          ),
        });

        return;
      }

      addToast({
        type: "success",
        title: t(
          "reports.modalStepper.stepper.one.form.filter.toast.one.title"
        ),
        message: t(
          "reports.modalStepper.stepper.one.form.filter.toast.one.message",
          {
            count: res.count,
          }
        ),
      });
    });
  }

  return {
    createReport,
    updateReport,
    getDevicesActivated,
    isPending,
    user,
  };
};
