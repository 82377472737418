import { formatDateDistanceText,Notification,NotificationType, useActiveLanguageDateFns } from "@group-link-one/grouplink-components";
import { useEffect, useState } from "react";

import { useFireStore } from "../Context/FireStoreProvider"
import { useFlags } from "../Context/FlagsProvider";

interface NotificationDocument {
  created_at: {
    seconds: number
    nanoseconds: number
  };
  title: string;
  description: string;
  type: NotificationType;
  is_active: boolean;
}

export const useNotifications = () => {
  const { getDocument } = useFireStore();
  const [allNotifications, setAllNotifications] = useState<Notification[]>([]);
  const languageActive = useActiveLanguageDateFns()
  const { hasBetaPrivileges } = useFlags();

  const fetchNotifications = async () => {
    const notifications = await getDocument<NotificationDocument>("notifications");

    const formattedNotifications: Notification[] =
      notifications.filter(filterNotificationsActive).map(formatNotifications);

    setAllNotifications(formattedNotifications);
  };

  function filterNotificationsActive(notification: NotificationDocument) {
    return notification.is_active;
  }

  function formatNotifications(notification: NotificationDocument) {
    return {
      id: `${notification.created_at.seconds}${notification.created_at.nanoseconds}`,
      titleOptions: {
        text: notification.title,
      },
      createdAtMiddle: formatDateDistanceText(
        new Date(notification.created_at.seconds * 1000).toString(),
        languageActive,
      ),
      description: {
        text: notification.description,
      },
      type: notification.type,
    }
  }

  useEffect(() => {
    if (hasBetaPrivileges()) {
      fetchNotifications();
    }
  }, [])

  return {
    allNotifications,
    fetchNotifications
  }
}
