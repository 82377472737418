import {
  GLAudit,
  GLPaginationProvider,
} from "@group-link-one/grouplink-components";

import { AuditContent } from "./Content/AuditContent";
import { useAudit } from "./useAudit";

export const Audit = () => {
  const { tabs, lastPageToken, relativeFilterTimeOptions } = useAudit();

  return (
    <GLPaginationProvider
      tabs={tabs}
      lastNextPageToken={lastPageToken}
      isInfiniteScroll
    >
      <GLAudit
        relativeFilterTimeOptions={relativeFilterTimeOptions}
        allAuditsOptions={{
          auditListContent: <AuditContent />,
        }}
      />
    </GLPaginationProvider>
  );
};
