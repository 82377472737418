import { GLModalSteperContent } from "@group-link-one/grouplink-components";

import { useImpersonateStore } from "../../store/impersonateModal.store";
import { OrganizationsContent } from "../Organizations/organizationsContent";
import { Users } from "../Users/users";
import { useImpersonateContent } from "./useImpersonateContent";

const contentsPerStep = {
  organization: <OrganizationsContent />,
  user: <Users />,
};

export function ImpersonateModalContent() {
  const { actions, state } = useImpersonateStore();
  const { functionToVerifyIfCanAdvanceMap, impersonateCustomer } =
    useImpersonateContent();

  return (
    <GLModalSteperContent
      actions={actions}
      state={state}
      contentsPerStep={contentsPerStep}
      isLoading={state.isLoading}
      functionToVerifyIfCanAdvanceMap={functionToVerifyIfCanAdvanceMap}
      sendForm={impersonateCustomer}
      updateForm={() => {}}
    />
  );
}
