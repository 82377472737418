/* eslint-disable */

import { useState } from "react";
import { useLoginSecurityServices } from "../../../Services/loginSecurityService/useLoginSecurityService";
import { useAuthServices } from "../../../Services/authService/useAuthServices";
import { useQuery } from "@tanstack/react-query";
import { ILoginSecurityData, useI18n, useToast } from "@group-link-one/grouplink-components";
import { useAuth } from "@group-link-one/gl-auth";

export const useLoginSecurity = () => {
  const [token, setToken] = useState("")
  const { logOut } = useAuthServices();
  const { t } = useI18n();
  const { user } = useAuth()
  const { updatePasswordLogged, getUserHistory, sendCode } = useLoginSecurityServices();
  const { addToast } = useToast()
  const [sendCodeIsLoading, setSendCodeIsLoading] = useState(false)

  const generateCode = async () => {
    setSendCodeIsLoading(true)
    const response = await sendCode({ email: user!.email })
    setSendCodeIsLoading(false)

    setToken(response.data)

    if (response.data) {
      addToast({
        title: t("loginAndSecurity.toast.email.title"),
        message: t("loginAndSecurity.toast.email.message"),
        type: "success"
      })
    }

    return response
  }

  const updatePassword = async ({ password, code }: Partial<ILoginSecurityData>) => {
    return updatePasswordLogged({
      token,
      code: code!,
      password: password!,
    }).then((res) => {
      if (res.status === 200) {
        addToast({
          title: t("loginAndSecurity.toast.password.title"),
          message: t("loginAndSecurity.toast.password.message"),
          type: "success"
        })
      }
    }).catch((err) => {
      addToast({
        title: t("toast.error.title"),
        message: t("toast.error.message"),
        type: "error",
      });
    });
  };

  const { data: sessions, isLoading } = useQuery({
    queryKey: ["get-user-history"],
    queryFn: () => getUserHistory().then((res) => res.data.rows),
  });

  const logOutDevice = async (id: string) => {
    await logOut({ session_id: id });
  };

  return {
    generateCode,
    updatePassword,
    logOutDevice,
    sessions,
    isLoading,
    sendCodeIsLoading,
    user
  };
};
