import {
  useGLPagination,
  useI18n,
  useInfiniteScroll,
} from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef } from "react";
import { useDebounceCallback, useMediaQuery } from "usehooks-ts";

import { useCustomerService } from "../../../../Services/customerService/useCustomerService";
import { queryClient } from "../../../../Services/queryClient";
import { useImpersonateContentStore } from "../../store/impersonateContent.store";
import { useImpersonateStore } from "../../store/impersonateModal.store";

export const useUsersContent = () => {
  const { getCustomers } = useCustomerService();
  const { state: paginationState, actions: paginationActions } =
    useGLPagination();
  const { state: impersonateModalState } = useImpersonateStore();
  const { state: impersonateContentState, actions: impersonateContentActions } =
    useImpersonateContentStore();

  const { t } = useI18n();

  const { onInfiniteScroll } = useInfiniteScroll();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const activeTab = useMemo(() => {
    return paginationActions.getActiveTabById(paginationState.activeTabId);
  }, [paginationState.activeTabId]);

  const { data, isLoading } = useQuery({
    queryKey: ["get-customers-by-org-id", paginationState.search],
    queryFn: async () => {
      const { nextPageToken, optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();
      if (impersonateModalState.stepActive === 0) return;

      const response = await getCustomers({
        ipp: 15,
        org_id: Number(impersonateContentState.organizationId),
        next_page_token: nextPageToken,
        search: paginationState.search,
      });

      paginationActions.setNextPageToken({
        hasMore: response.has_more,
        nextPageToken: response.next_page_token || undefined,
        optionsToStoreNextPageToken,
      });

      return response.rows;
    },
    staleTime: 1000 * 60 * 10,
  });

  async function getMoreCustomers() {
    const activeTabIdMap = {
      "tab-1": {
        do: async (options: any) => {
          return await getCustomers(options);
        },
        cachekey: "get-customers-by-org-id",
      },
    };

    if (paginationState.isFetchingInfiniteScroll) return;

    const { nextPageToken, optionsToStoreNextPageToken } =
      paginationActions.getNextPageToken();

    if (!nextPageToken) return;

    paginationActions.setIsFetchingInfiniteScroll(true);

    const tabMapActive =
      activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap];

    const response = await tabMapActive.do({
      org_id: Number(impersonateContentState.organizationId),
      next_page_token: nextPageToken,
      search: paginationState.search,
    });

    paginationActions.setIsFetchingInfiniteScroll(false);

    paginationActions.setNextPageToken({
      hasMore: response.has_more,
      nextPageToken: response.next_page_token || undefined,
      optionsToStoreNextPageToken,
    });

    const currentCustomerList: any = queryClient.getQueryData([
      tabMapActive.cachekey,
      paginationState.search,
    ]);

    if (!currentCustomerList) return;

    queryClient.setQueryData(
      [tabMapActive.cachekey, paginationState.search],
      [...currentCustomerList, ...response.rows]
    );
  }

  const onSelectUser = async (user_id: string | number) => {
    if (user_id === impersonateContentState.userId) {
      impersonateContentActions.setUserId(0);
      return;
    }
    impersonateContentActions.setUserId(user_id);
  };

  function invalidateAllQueries() {
    queryClient.invalidateQueries({
      queryKey: ["get-customers-by-org-id"],
    });
  }

  useEffect(() => {
    paginationActions.tabsActions.setOnClick(paginationState.tabs[0], () => {
      paginationActions.setCurrentPage(0);
      paginationActions.setActiveTab(1);
    });

    invalidateAllQueries();
  }, []);

  const handleInputChange = useDebounceCallback(
    async (event: KeyboardEvent) => {
      const value = (event.target as HTMLInputElement).value;
      paginationActions.setSearch(value);
      paginationActions.setCurrentPage(0);
    },
    500
  );

  useEffect(() => {
    if (!inputSearchRef.current) return;

    inputSearchRef.current.addEventListener("keyup", handleInputChange);

    return () => {
      if (!inputSearchRef.current) return;

      inputSearchRef.current.removeEventListener("keyup", handleInputChange);
    };
  }, []);

  return {
    data,
    isLoading,
    impersonateContentState,
    onSelectUser,
    onInfiniteScroll,
    getMoreCustomers,
    tabs: paginationState.tabs,
    activeTab,
    paginationState,
    inputSearchRef,
    isMobile,
    t,
  };
};
