import {
  TabProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useMemo } from "react";

import { useFlags } from "../../Context/FlagsProvider";

export const useDeviceList = () => {

  const { t } = useI18n();
  const { userHasPrivileges, hasPrivileges, user } = useFlags();

  const tabs: TabProps[] = useMemo(() => {
    const Alltabs = [
      {
        id: 1,
        active: true,
        cacheKey: "all-devices",
        count: undefined,
        name: t("deviceList.tabs.ut.one"),
        onClick: () => {},
      },
    ]

    if (hasPrivileges(["tab_show_pending_devices"])) {
      Alltabs.push({
        id: 2,
        active: false,
        cacheKey: "pending-devices",
        count: undefined,
        name: t("deviceList.tabs.ut.two"),
        onClick: () => {},
      },);
    }

    return Alltabs;
  }, [userHasPrivileges, user]);

  const lastPageToken = {
    "all-devices": {
      token: undefined,
      page: 0,
    },
    "gas-devices": {
      token: undefined,
      page: 0,
    },
    "water-devices": {
      token: undefined,
      page: 0,
    },
    "pending-devices": {
      token: undefined,
      page: 0,
    },
  };

  return {
    tabs,
    lastPageToken,
  };
};
