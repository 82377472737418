import { useFlags } from "../Context/FlagsProvider";

export const usePrivileges = () => {
  const { hasBetaPrivileges, user, hasPrivileges } = useFlags();

  const pages = {
    reports: hasPrivileges(["show_main_report_page"]),
    deviceList: hasPrivileges(["show_devices_list_page"]),
    deviceGroupList: hasPrivileges(["show_devices_group_page"]),
    healthCheck: hasBetaPrivileges("beta") &&
      (hasPrivileges(["show_health_check_overview"]) || hasPrivileges(["show_health_check_map"])) &&
      user?.org_occupational_area === "concessionaire",
    eventList: hasPrivileges(["show_network_event_page"]),
    settings: {
      overview: hasPrivileges(["show_settings_overview_page"]),
      myAccount: hasPrivileges(["show_settings_my_account_page"]),
      loginSecurity: hasPrivileges(["show_settings_login_security_page"]),
      users: hasPrivileges(["show_settings_users_page"]),
      audit: hasPrivileges(["show_settings_audit_page"]),
      groupsUsers: hasPrivileges(["show_settings_user_group_page"]),
    }
  }

  const features = {
    impersonation: hasPrivileges(["action_btn_impersonate_mode"]),

    devices: {
      editDevice: hasPrivileges(["show_btn_edit_device"]),
      showDetails: hasPrivileges(["action_btn_show_device_details"]),
      activateDevice: hasPrivileges(["action_btn_show_activate_device"]),
      decommissionDevice: hasPrivileges(["action_btn_show_decommission_device"]),
    }
  }

  const requests = {
    lastReading: hasPrivileges(["request_last_reading"]),
  }

  return {
    pages,
    features,
    requests
  }
}
