import {
  GL_COLORS,
  GL_USER,
  GLBox,
  GLCard,
  GLExtendedTextField,
  GLList,
  GLLoading,
  GLTipsCard,
  GLTypography,
} from "@group-link-one/grouplink-components";

import { useUsersContent } from "./useUsersContent";

export const UsersContent = () => {
  const {
    data,
    isLoading,
    impersonateContentState,
    onSelectUser,
    tabs,
    onInfiniteScroll,
    getMoreCustomers,
    activeTab,
    paginationState,
    inputSearchRef,
    isMobile,
    t,
  } = useUsersContent();

  return (
    <GLBox direction="row" justify="space-between">
      <GLBox
        direction="column"
        width={"100%"}
        style={{
          maxWidth: 1174,
        }}
      >
        <GLTypography
          text={t("impersonate.modal.steps.user.labels.selectUser")}
          weight={600}
        />

        <GLList
          isLoading={isLoading}
          contentStyle={{
            height: 500,
            overflow: "auto",
          }}
          error={impersonateContentState.userErrorMessage}
          mode="infinit-scroll"
          onScroll={(event) =>
            onInfiniteScroll({ event, callback: getMoreCustomers })
          }
          tabs={tabs}
          tabActive={activeTab?.name || ""}
          filters={<GLExtendedTextField ref={inputSearchRef} />}
          content={
            <>
              <GLBox width={"100%"} direction="column">
                {data?.map((user: any) => (
                  <GLCard
                    id={user.id}
                    key={user.id}
                    variant="user-impersonate-card"
                    last_name={user.last_name}
                    name={user.name}
                    email={user.email}
                    onClick={onSelectUser}
                    isCheck={user.id === impersonateContentState.userId}
                  />
                ))}
              </GLBox>

              {paginationState.isFetchingInfiniteScroll && (
                <GLBox align="center" justify="center">
                  <GLLoading type="dots" size="primary" />
                </GLBox>
              )}
            </>
          }
        />
      </GLBox>

      {!isMobile && (
        <GLBox width={"504px"}>
          <GLTipsCard
            icon={<GL_USER fill={GL_COLORS.FONT_COLOR_DARK_GREY} />}
            title={t("impersonate.modal.steps.user.tips.title")}
            size="small"
            hasAnimation
            type="default"
            content={
              <GLTypography
                text={t("impersonate.modal.steps.user.tips.description")}
              />
            }
          />
        </GLBox>
      )}
    </GLBox>
  );
};
