import { useAuth } from "@group-link-one/gl-auth";
import {
  GL_COLORS,
  GL_DELETE,
  GL_EQUALIZER,
  GL_HEADPHONES,
  GL_USER_CHECK,
  IMenuOptions,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useNavigate } from "react-router-dom";

import { useFlags } from "../Context/FlagsProvider";
import { usePrivileges } from "../hooks/usePrivilleges";
import { useImpersonateStore } from "../Pages/Impersonate/store/impersonateModal.store";

export const useMenuOptions = () => {
  const { t } = useI18n();
  const { hasBetaPrivileges } = useFlags();
  const navigate = useNavigate();
  const { actions } = useImpersonateStore();
  const { manipulateReduxValues, setIsPending, rootToken } = useAuth();
  const { features } = usePrivileges();

  const isImpersonating = localStorage.getItem("isImpersonated");

  const menuOptions: IMenuOptions[] = [
    {
      icon: <GL_USER_CHECK size={18} fill={GL_COLORS.FONT_COLOR} />,
      text: t("topbar.userModal.profile"),
      onClick: () => navigate("/settings/overview"),
    },
    {
      icon: <GL_EQUALIZER size={18} fill={GL_COLORS.FONT_COLOR} />,
      text: t("topbar.userModal.accountSettings"),
      onClick: () => navigate("/settings/my-account"),
    },
  ];

  if (
    features.impersonation &&
    hasBetaPrivileges("beta")
  ) {
    menuOptions.push({
      icon: <GL_HEADPHONES size={18} fill={GL_COLORS.FONT_COLOR} />,
      text: t("impersonate.menuOptions.supportMode"),
      onClick: () => actions.setModalSteperContentIsOpen(true),
    });
  }

  if (isImpersonating) {
    menuOptions.push({
      icon: <GL_DELETE size={18} fill={GL_COLORS.DANGER} />,
      text: t("impersonate.menuOptions.leaveSupportMode"),
      colorText: GL_COLORS.DANGER,
      onClick: () => {
        manipulateReduxValues("SET_USER", null);
        setIsPending(true);

        actions.resetAll();

        localStorage.removeItem("isImpersonated");
        manipulateReduxValues("SET_ACCESS_TOKEN", rootToken);

        navigate("/settings/overview");
        setIsPending(false);
      },
    });
  }

  return {
    menuOptions,
  };
};
