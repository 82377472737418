import { GLSettingsUsers } from "@group-link-one/grouplink-components";

import { UsersContent } from "./Content/usersContent";
import { useOrganizationUsers } from "./useOrganizationUsers";

export const UsersPage = () => {
  const {
    activeUserById,
    createUserInvite,
    deactivateUserById,
    deleteInviteById,
    updateRole,
    // hasBetaPrivileges
  } = useOrganizationUsers();

  return (
    <GLSettingsUsers
      onlyBetaPrivileges={true}
      onActiveUserById={activeUserById}
      onCreateUserInvite={createUserInvite}
      onDeactivateUserById={deactivateUserById}
      onDeleteInviteById={deleteInviteById}
      onChangeRole={updateRole}
    >
      <UsersContent />
    </GLSettingsUsers>
  );
};
