import { GLBox, GLRelativeFilterTime } from "@group-link-one/grouplink-components";

import { useRightFilters } from "./useRightFilters";

export function RightFilters() {
  const {
    relativeFilterTimeOptions
  } = useRightFilters();

  return (
    <GLBox
      width="fit-content"
      justify="center"
      align="center"
      style={{
        position: "absolute",
        top: 40,
        right: 40,
      }}
    >
      {/* <GLSelectMultiple
        options={[
          { id: "all", text: "Todos" },
          {
            id: "with-alert",
            text: "Com alerta",
            content: (
              <GLSelectMultiple
                hasInput={false}
                canSelectMultiple={false}
                options={Object.entries(alertsMap).map(([key, value]) => ({
                  id: key,
                  text: value,
                }))}
                optionsSelected={healthCheckState.alertTypesSelected || []}
                boxOptions={{
                  width: 200
                }}
                onSelectedOptionsChange={(selectedOptions) => {

                  if (
                    selectedOptions.length === 0 &&
                    (!healthCheckState?.alertTypesSelected || healthCheckState?.alertTypesSelected?.length === 0)
                  ) return

                  healthCheckActions.setAlertTypesSelected(selectedOptions);
                  healthCheckActions.setAlertFilterValue(selectedOptions);
                }}
              />
            )
          },
          { id: "without-alert", text: "Sem alerta" },
        ]}
        optionsSelected={healthCheckState.alertFilterValue || []}
        rootOptions={{
          style: {
            minWidth: 250
          }
        }}
        inputOptions={{
          placeholder: "Filtrar por alerta",
        }}
        boxOptions={{
          width: "100%",
          position: "bottom-right"
        }}
        canSelectMultiple={false}
        hasCheckbox={false}
        isAsynchronous
        onSelectedOptionsChange={(selectedOptions) => {

          if (selectedOptions.length === 0) {
            healthCheckActions.setAlertFilterValue([{ id: "all", text: "Todos" }]);
            return
          }

          const isAllOrWithoutAlert = selectedOptions.some((option) => option.id === "all" || option.id === "without-alert");
          if (!isAllOrWithoutAlert) return

          healthCheckActions.setAlertTypesSelected([]);
          healthCheckActions.setAlertFilterValue(selectedOptions);
        }}
      /> */}

      <GLRelativeFilterTime
        {...relativeFilterTimeOptions}
      />
    </GLBox>
  )
}
