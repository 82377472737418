import { useAuth } from "@group-link-one/gl-auth";
import {
  GLHeader,
  GLHeaderNavListItemProps,
} from "@group-link-one/grouplink-components";
import { Outlet } from "react-router-dom";

import { useFlags } from "../Context/FlagsProvider";
import { useNotifications } from "../hooks/useNotifications";
import { useAuthServices } from "../Services/authService/useAuthServices";
import { useMenuOptions } from "./useMenuOptions";

interface PageLayoutProps {
  headerNavListItems?: GLHeaderNavListItemProps[];
}

export function PageLayout({ headerNavListItems }: PageLayoutProps) {
  const { user } = useAuth();
  const { logOut } = useAuthServices();
  const { hasBetaPrivileges } = useFlags();
  const { menuOptions } = useMenuOptions();

  const headerNavItemActive = headerNavListItems?.find(
    (navItem) => navItem.path === window.location.pathname
  );

  const { allNotifications } = useNotifications();

  return (
    <>
      <GLHeader
        onLogOut={() => logOut({})}
        headerNavListItems={headerNavListItems}
        headerNavItemActive={headerNavItemActive?.id}
        user={user!}
        notifications={hasBetaPrivileges() ? allNotifications : undefined}
        menuOptions={menuOptions}
      />
      <Outlet />
    </>
  );
}
