import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export type MyAccForm = {
  name?: string | undefined;
  last_name?: string | undefined | null;
  email?: string | undefined;
  phone?: string | undefined | null;
  job_title?: string | undefined | null;
  organization?: string | undefined;
  mask?: string | undefined;
};

export const useMyAccountService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const updateMyAccount = async (data?: MyAccForm) => {
    return await httpPrivate.patch("/my-account", data);
  };

  const getMyAccount = async () => {
    return await httpPrivate.get("/my-account");
  };

  return {
    updateMyAccount,
    getMyAccount,
  };
};
