import {
  GLGroupsDevice,
  IGroupsDevice,
} from "@group-link-one/grouplink-components";

import { AddDevicesBody } from "./AddDevicesBody/AddDevicesBody";
import { useGroupListBody } from "./useGroupListBody";

export function GroupListBody() {
  const {
    groupList,
    user,
    groupsDevicesState,
    deleteMutation,
    hasPrivileges,
    addDevicesToGroup,
    createMutation,
    OPTIONS,
    updateMutation,
  } = useGroupListBody();

  return (
    <GLGroupsDevice
    groups={groupList || []}
    user={user}
      onCreateGroup={(data) => createMutation.mutateAsync(data)}
      onDeleteGroupById={(data) => deleteMutation.mutateAsync(data)}
      onListGroups={async () => console.log("onListGroups")}
      onUpdateGroup={(id, data) => updateMutation.mutateAsync({ id, data })}
      onSaveDevicesOnGroup={async (group: IGroupsDevice) => {
        addDevicesToGroup({
          id: Number(group.id),
          data: {
            device_ids: groupsDevicesState.devices_ids.map(Number),
          },
        });
      }}
      hasPermissionToCreate={hasPrivileges(["action_btn_create_new_group"])}
      coordinatorOptions={OPTIONS}
      transferListContent={<AddDevicesBody />}
      viewerOptions={OPTIONS}
    />
  );
}
