import { GL_COLORS, GLBox, GLLoading, GLTypography } from "@group-link-one/grouplink-components";
import { GoogleMap, MarkerClusterer } from "@react-google-maps/api";

import { ModalDeviceDetails } from "../../DeviceList/ModalDeviceDetails/ModalDeviceDetails";
import { HealthCheckBigNumbers } from "../../HealthCheck/Content/BigNumbers/HealthCheckBigNumbers";
import { DeviceModal } from "../DeviceModal/DeviceModal";
import { HealthCheckMapFooter } from "./HealthCheckMapStyle";
import { MarkerMap } from "./Marker/Marker";
import { RightFilters } from "./RightFilters/RightFilters";
import { useHealthCheckMap } from "./useHealthCheckMap";

const containerStyle = {
  width: "100%",
  height: "100%"
};

export function HealthCheckMap() {
  const {
    t,
    center,
    currentDevices,
    markerIcon,
    mapStyle,
    healthCheckState,
    isLoaded,
    onLoad,
    onBoundsChanged,
    onUnmount
  } = useHealthCheckMap();

  return isLoaded ? (
    <>
      <GLBox height="100%" gap={0}>
        <GLBox
          width="30%"
          height="100%"
          style={{
            padding: 20,
            borderRight: `1px solid ${GL_COLORS.BORDER_COLOR}`,
          }}
        >
          <HealthCheckBigNumbers local="map" />
        </GLBox>

        <GoogleMap
          mapContainerStyle={{
            ...containerStyle,
            // borderRadius: "10px",
          }}
          options={{
            styles: mapStyle,
            disableDefaultUI: true,
            minZoom: 7,
            // maxZoom: 20,
          }}
          center={center}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onBoundsChanged={() => onBoundsChanged()}
        >

          <GLBox
            width="fit-content"
            direction="column"
            gap={0}
            style={{
              position: "absolute",
              top: 40,
              left: 40,
            }}
          >
            <GLTypography
              text={t("healthCheck.header.title")}
              color="ACCENT_COLOR"
              weight={600}
              as="h2"
              fontSize={4.2}
              style={{
                textTransform: "uppercase",
              }}
            />
            <GLTypography
              text={t("healthCheck.header.subtitle")}
              color="FONT_COLOR"
              weight={600}
              as="h2"
              fontSize={7}

            />
          </GLBox>

          <GLBox
            width="fit-content"
            direction="column"
            gap={0}
            animate={{
              opacity: healthCheckState.isFetchingDevices ? 1 : 0,
              transition: {
                duration: 0.2,
              },
            }}
            style={{
              position: "absolute",
              top: 50,
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
              borderRadius: "10px",
              padding: "20px 30px",
              boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
              border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
              pointerEvents: "none",
            }}
          >
            <GLLoading
              type="dots"
              size="secondary"
            />
          </GLBox>

          <RightFilters />

          {currentDevices && currentDevices.length > 0 && (
            <MarkerClusterer
              options={{
                maxZoom: 17,
                // styles: [
                //   {
                //     url: ImgTest, // URL do ícone do cluster
                //     height: 50, // Altura do ícone
                //     width: 50, // Largura do ícone
                //     textColor: "#ffffff", // Cor do texto dentro do cluster
                //     // textSize: 16, // Tamanho do texto dentro do cluster
                //     textSize: 0, // Tamanho do texto dentro do cluster
                //     anchorText: [10, 0], // Ajuste da posição do texto
                //   },
                //   {
                //     url: ImgTest, // URL do ícone do cluster
                //     // url: "URL_DO_SEU_ICONE_MEDIO",
                //     height: 60,
                //     width: 60,
                //     textColor: "#ffffff",
                //     // textSize: 18,
                //     textSize: 0,
                //     anchorText: [15, 0],
                //   },
                //   {
                //     url: ImgTest, // URL do ícone do cluster
                //     // url: "URL_DO_SEU_ICONE_GRANDE",
                //     height: 70,
                //     width: 70,
                //     textColor: "#ffffff",
                //     // textSize: 20,
                //     textSize: 0,
                //     anchorText: [20, 0],
                //   },
                // ]
              }}
            >
              {(clusterer) =>
                <>
                  {currentDevices.map(device => (
                    <MarkerMap
                      device={device}
                      clusterer={clusterer}
                      markerIcon={markerIcon}
                      key={device.device_id}
                    />
                  ))}
                </>
              }
            </MarkerClusterer>
          )}

          <HealthCheckMapFooter width="fit-content " justify="space-between" align="flex-end">
            {/* <GLBox width="fit-content" direction="column">
              <GLBox
                direction="column"
                style={{
                  backgroundColor: GL_COLORS.PURPLE,
                  borderRadius: "16px",
                  padding: "20px 25px",
                  marginTop: 20,
                  border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
                }}
              >
                <GLBox align="center">
                  <GLBox
                    width={12}
                    height={12}
                    style={{
                      backgroundColor: GL_COLORS.LIGHT_GREEN,
                      borderRadius: "50%",
                      outline: `2px solid ${GL_COLORS.WHITE}`,
                      flex: "0 0 12px",
                    }}
                  >
                    <></>
                  </GLBox>

                  <GLTypography
                    text="Dispositivos sem alertas"
                    fontSize={4}
                    color="WHITE"
                    weight={600}
                  />
                </GLBox>

                <GLBox align="center">
                  <GLBox
                    width={12}
                    height={12}
                    style={{
                      backgroundColor: GL_COLORS.DANGER,
                      borderRadius: "50%",
                      outline: `2px solid ${GL_COLORS.WHITE}`,
                      flex: "0 0 12px",
                    }}
                  >
                    <></>
                  </GLBox>

                  <GLTypography
                    text="Dispositivos com alertas - (123)"
                    fontSize={4}
                    color="WHITE"
                    weight={600}
                  />
                </GLBox>
              </GLBox>
            </GLBox> */}
            {/*
            <GLBox
              direction="column"
              width="fit-content"
              // align="center"
            >
              {devicesFetched?.slice(0, 5).map((device, index) => (
                <GLBox
                  key={device.device_id}
                  width="fit-content"
                  align="center"
                  gap={10}
                  style={{
                    padding: "10px 20px",
                    borderRadius: 10,
                    backgroundColor: GL_COLORS.DANGER,
                    boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
                    // marginTop: -20,
                  }}
                  initial={{
                    opacity: 0,
                    y: 20,
                  }}

                  animate={{
                    opacity: 1 / (index + 1),
                    // scale:
                      // index === 0 ? 1.1 : 1 / (index * 0.1 + 1),
                    y: 0,
                    transition: {
                      delay: index * 0.1,
                      duration: 0.5,
                    },
                  }}

                >
                  <GL_ALARM fill={GL_COLORS.WHITE}  />

                  <GLBox direction="column" gap={5}>
                    <GLTypography
                      text="Magnetic fraud"
                      fontSize={4}
                      color="WHITE"
                      weight={600}
                    />
                    <GLTypography
                      text={String(device.device_id)}
                      fontSize={4}
                      color="WHITE"
                      weight={600}
                    />

                    <GLTypography
                      text={device.meta.display_address}
                      fontSize={3}
                      color="WHITE"
                      weight={400}
                    />
                  </GLBox>
                </GLBox>
              ))}
            </GLBox> */}
          </HealthCheckMapFooter>

          <DeviceModal />
        </GoogleMap>
      </GLBox>

      <ModalDeviceDetails />
    </>

  ) : <></>;
}
