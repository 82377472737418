import { useLoadScript } from "@react-google-maps/api";

import { env } from "../../env";

export const useGoogleMapsService = () => {
  const { isLoaded: googleMapsIsLoaded } = useLoadScript({
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const getSuggestions = async (search: string) => {
    if (!search || !googleMapsIsLoaded) return [];

    const autoCompleteService =
      new window.google.maps.places.AutocompleteService();

    const predictionsFiltered = await new Promise<
      google.maps.places.AutocompletePrediction[]
    >((resolve, reject) => {
      autoCompleteService.getPlacePredictions(
        { input: search },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            resolve(predictions);
          } else {
            reject([]);
          }
        }
      );
    });

    return predictionsFiltered;
  };

  const getPlaceDetails = async (placeId: string) => {
    if (!googleMapsIsLoaded) return;

    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    const placeDetails = await new Promise<google.maps.places.PlaceResult>(
      (resolve, reject) => {
        placesService.getDetails({ placeId }, (place, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            place
          ) {
            resolve(place);
          } else {
            reject(null);
          }
        });
      }
    );

    return placeDetails;
  };

  return {
    googleMapsIsLoaded,
    getSuggestions,
    getPlaceDetails,
  };
};
