import { GLSettingsGroupsUsers } from "@group-link-one/grouplink-components";

import { GroupUsersTransferList } from "./TransferList/GroupUsersTransferList";
import { useOrganizationGroups } from "./useOrganizationGroups";

export const GroupsUsersPage = () => {
  const { groups, listGroups, createGroup, updateGroup, deleteGroup, hasPrivileges } =
    useOrganizationGroups();

  return (
    <GLSettingsGroupsUsers
      hasPrivilegeToCreate={hasPrivileges(["action_btn_new_user_group"])}
      groups={groups}
      transferListContent={<GroupUsersTransferList />}
      onListGroups={listGroups}
      onCreateGroup={createGroup}
      onUpdateGroup={updateGroup}
      onDeleteGroupById={deleteGroup}
    />
  );
};
