import { useAuth } from "@group-link-one/gl-auth";
import {
  BigNumberProps,
  Colors,
  GL_CLOCK,
  GL_COLORS,
  GL_IDEA,
  GL_LOGIN,
  GL_SCALE,
  GL_THUNDERBOLT,
  GL_USER,
  GLBarChartDataProps,
  useActiveLanguageDateFns,
  useI18n,
} from "@group-link-one/grouplink-components";
import { format } from "date-fns";
import { useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";

import { GLBigNumberLastReading } from "../../../../images/DeviceList/bigNumbers/BigNumberLastReading";
import AverageImg from "../../../../images/DeviceList/bigNumbers/details-big-number-clock.svg";
import EnergyImg from "../../../../images/DeviceList/bigNumbers/details-big-number-energy.svg";
import PeakPeriodImg from "../../../../images/DeviceList/bigNumbers/details-big-number-scale.svg";
import VisitingImg from "../../../../images/DeviceList/bigNumbers/details-big-number-user.svg";
import StreetLightImg from "../../../../images/DeviceList/bigNumbers/idea.svg";
import { convertDailyReading } from "../../../../utils/convertDailyReading";
import { UseCase } from "../../../EventList/Content/Columns/AllColumns";
import { useDetailsDeviceStore } from "../../store/details-device-store";
import { getOptionsByApplication } from "../../utils/getOptionsByApplication";
import { useGetDailyReadings } from "../Graphs/useGetDailyReadings";

export const useDeviceDetailsContent = () => {
  const { state: detailsDeviceState } = useDetailsDeviceStore();
  const { t } = useI18n();
  const { getAverage, formatReadings } = useGetDailyReadings();
  const langActive = useActiveLanguageDateFns();
  const isSM = useMediaQuery("(max-width: 660px)");

  const { user } = useAuth()

  const userUseCase = user?.use_case as UseCase

  const currentChannelData = useMemo(() => {
    return detailsDeviceState.device?.channels[detailsDeviceState.channelActive || 0]
  }, [detailsDeviceState.channelActive])

  const streetLightValues = useMemo(() => {
    const rmsVoltage = currentChannelData?.last_reading && typeof currentChannelData?.last_reading !== "number"
      && currentChannelData?.last_reading.series.find(series => series.serie === "rms_voltage");

    const rmsCurrent = currentChannelData?.last_reading && typeof currentChannelData?.last_reading !== "number"
      && currentChannelData?.last_reading.series.find(series => series.serie === "rms_current");

    const reactiveEnergy = currentChannelData?.last_reading && typeof currentChannelData?.last_reading !== "number"
      && currentChannelData?.last_reading.series.find(series => series.serie === "reactive_energy_accum");

    const activeEnergy = currentChannelData?.last_reading && typeof currentChannelData?.last_reading !== "number"
      && currentChannelData?.last_reading.series.find(series => series.serie === "active_energy_accum");

    return {
      rms_voltage: rmsVoltage ? rmsVoltage.value : "0",
      rms_current: rmsCurrent ? rmsCurrent.value : "0",
      reactive_energy: reactiveEnergy ? reactiveEnergy.value : "0",
      active_energy: activeEnergy ? activeEnergy.value : "0",
    }

  }, [currentChannelData]);

  const bigNumbers: BigNumberProps[] = [
    {
      iconLeft: <GL_LOGIN fill={getOptionsByApplication({
        type: detailsDeviceState.device?.application,
        deviceMeasurementCategory: detailsDeviceState.device?.meta.device_measurement_category || "water"
      }).color} size={24} />,
      iconRight: <GLBigNumberLastReading fill={getOptionsByApplication({
        type: detailsDeviceState.device?.application,
        deviceMeasurementCategory: detailsDeviceState.device?.meta.device_measurement_category || "water"
      }).color} />,
      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? "none" : "block",
      },
      title: t("deviceList.detailsModal.ut.bigNumbers.one.title"),
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application
      }).unit})`,
      count: getLastReading(),
      type: isSM ? "sm" : "lg",
      countColor: "FONT_COLOR",
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: "FONT_COLOR_DARK_GREY",
      tooltipText: t("deviceList.detailsModal.ut.bigNumbers.one.tooltipDescription"),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: "top-center",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    },
    {
      iconLeft: <GL_CLOCK fill={GL_COLORS.PURPLE} size={24} />,
      iconRight: <img src={AverageImg} alt="" />,
      iconRightStyle: {
        bottom: -14,
        right: 30,
        display: isSM ? "none" : "block",
      },
      title: t("deviceList.detailsModal.ut.bigNumbers.three.title"),
      type: isSM ? "sm" : "lg",
      // subTitle: `(${detailsDeviceState.device?.channels[0].unit})`,
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application
      }).unit})`,
      textColor: "FONT_COLOR_DARK_GREY",
      count: getAverageConsumption(),
      countColor: "FONT_COLOR",
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      tooltipText: t("deviceList.detailsModal.ut.bigNumbers.three.tooltipDescription"),
      hasBorder: true,
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    },
    {
      iconLeft: <GL_SCALE fill={GL_COLORS.NAVY} size={24} />,
      iconRight: <img src={PeakPeriodImg} alt="" />,
      iconRightStyle: {
        bottom: -15,
        right: 15,
        display: isSM ? "none" : "block",
      },
      title: t("deviceList.detailsModal.ut.bigNumbers.four.title"),
      type: isSM ? "sm" : "lg",
      textColor: "FONT_COLOR_DARK_GREY",
      count: getPeakPeriod(),
      countColor: "FONT_COLOR",
      countSize: 6.5,
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      tooltipText: t("deviceList.detailsModal.ut.bigNumbers.four.tooltipDescription"),
      hasBorder: true,
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    },
  ];

  if (userUseCase === "util_water" || userUseCase === "util_light") {
    bigNumbers.splice(1, 0, {
      iconLeft: <GL_USER fill={Colors.LEMON_GREEN} size={24} />,
      iconRight: <img src={VisitingImg} alt="" />,
      iconRightStyle: {
        bottom: 0,
        right: 30,
        display: isSM ? "none" : "block",
      },
      title: t("deviceList.detailsModal.ut.bigNumbers.two.title"),
      // subTitle: `(${detailsDeviceState.device?.channels[0].unit})`,
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application,
        deviceMeasurementCategory: detailsDeviceState.device?.meta.device_measurement_category || "water"
      }).unit})`,
      textColor: "FONT_COLOR_DARK_GREY",
      type: isSM ? "sm" : "lg",
      count: getDailyConsumption(),
      countColor: "FONT_COLOR",
      tooltipText: t("deviceList.detailsModal.ut.bigNumbers.two.tooltipDescription"),
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      hasBorder: true,
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    });
  }

  if (userUseCase === "util_light") {
    const activeEnergy: BigNumberProps = {
      iconLeft: <GL_IDEA fill={getOptionsByApplication({
        type: detailsDeviceState.device?.application,
        deviceMeasurementCategory: detailsDeviceState.device?.meta.device_measurement_category || "water"
      }).color} size={24} />,

      iconRight: <img src={StreetLightImg} alt="" />,

      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? "none" : "block",
      },

      title: "Active Energy",
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application
      }).unit})`,
      count: streetLightValues.active_energy,
      type: isSM ? "sm" : "lg",
      countColor: "FONT_COLOR",
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: "FONT_COLOR_DARK_GREY",
      tooltipText: t("deviceList.detailsModal.ut.bigNumbers.one.tooltipDescription"),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: "top-center",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    }

    const reactiveEnergy: BigNumberProps = {
      iconLeft: <GL_IDEA fill={getOptionsByApplication({
        type: detailsDeviceState.device?.application,
        deviceMeasurementCategory: detailsDeviceState.device?.meta.device_measurement_category || "water"
      }).color} size={24} />,

      iconRight: <img src={StreetLightImg} alt="" />,

      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? "none" : "block",
      },

      title: "Reactive Energy",
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application
      }).unit})`,
      count: streetLightValues.reactive_energy,
      type: isSM ? "sm" : "lg",
      countColor: "FONT_COLOR",
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: "FONT_COLOR_DARK_GREY",
      tooltipText: t("deviceList.detailsModal.ut.bigNumbers.one.tooltipDescription"),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: "top-center",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    }

    const rmsVoltagem: BigNumberProps = {
      iconLeft: <GL_THUNDERBOLT fill={getOptionsByApplication({
        type: detailsDeviceState.device?.application,
        deviceMeasurementCategory: detailsDeviceState.device?.meta.device_measurement_category || "water"
      }).color} size={24} />,

      iconRight: <img src={EnergyImg} alt="" />,

      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? "none" : "block",
      },

      title: "Voltagem",
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application
      }).unit})`,
      count: streetLightValues.rms_voltage,
      type: isSM ? "sm" : "lg",
      countColor: "FONT_COLOR",
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: "FONT_COLOR_DARK_GREY",
      tooltipText: t("deviceList.detailsModal.ut.bigNumbers.one.tooltipDescription"),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: "top-center",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    }

    const rmsCurrent: BigNumberProps = {
      iconLeft: <GL_THUNDERBOLT fill={getOptionsByApplication({
        type: detailsDeviceState.device?.application,
        deviceMeasurementCategory: detailsDeviceState.device?.meta.device_measurement_category || "water"
      }).color} size={24} />,

      iconRight: <img src={EnergyImg} alt="" />,

      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? "none" : "block",
      },

      title: "Tensão",
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application
      }).unit})`,
      count: streetLightValues.rms_current,
      type: isSM ? "sm" : "lg",
      countColor: "FONT_COLOR",
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: "FONT_COLOR_DARK_GREY",
      tooltipText: t("deviceList.detailsModal.ut.bigNumbers.one.tooltipDescription"),
      hasBorder: true,
      hasShadow: false,
      tooltipPosition: "top-center",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    }

    bigNumbers.push(activeEnergy, reactiveEnergy, rmsVoltagem, rmsCurrent)
  }

  if (userUseCase === "util_kwh") {
    bigNumbers.splice(1, 0, {
      iconLeft: <GL_THUNDERBOLT fill={Colors.LEMON_GREEN} size={24} />,
      iconRight: <img src={EnergyImg} alt="" />,
      iconRightStyle: {
        bottom: 0,
        right: 30,
        display: isSM ? "none" : "block",
      },
      title: t("deviceList.detailsModal.ut.bigNumbers.reverseEnergy.title"),
      // subTitle: `(${detailsDeviceState.device?.channels[0].unit})`,
      subTitle: `(${getOptionsByApplication({
        type: detailsDeviceState.device?.application
      }).unit})`,
      textColor: "FONT_COLOR_DARK_GREY",
      type: isSM ? "sm" : "lg",
      count: getLatestReadingReverseEnergy(),
      countColor: "FONT_COLOR",
      tooltipText: t("deviceList.detailsModal.ut.bigNumbers.reverseEnergy.tooltipDescription"),
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      hasBorder: true,
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      }
    });
  }

  function getLastReading() {
    if (
      !detailsDeviceState.currentData ||
      detailsDeviceState.currentData.length === 0 ||
      !detailsDeviceState.currentData[detailsDeviceState.currentData.length - 1].reading
    )
      return "--";

    const lastReading = detailsDeviceState.currentData[detailsDeviceState.currentData.length - 1].reading;
    return String(lastReading);
  }

  function getDailyConsumption() {
    if (
      !detailsDeviceState.currentData ||
      detailsDeviceState.currentData.length === 0 ||
      !detailsDeviceState.currentData[detailsDeviceState.currentData.length - 1].reading
    )
      return "--";

    const { readingsFormatted } = formatReadings(detailsDeviceState.currentData);

    const dailyConsumption = convertDailyReading(readingsFormatted);

    if (dailyConsumption[dailyConsumption.length - 1].reading === 0) return "--";

    return dailyConsumption[dailyConsumption.length - 1].reading.toFixed(2);
  }

  function getLatestReadingReverseEnergy() {

    if (!detailsDeviceState.device) return "--"
    const { channels } = detailsDeviceState.device

    const currentChannel = channels.find(channel => channel.channel === detailsDeviceState.channelActive)
    if (!currentChannel) return "--"

    const lastReadings = currentChannel.last_reading

    if (typeof lastReadings === "number") return "--"
    const energyReverse = lastReadings.series.find(serie => serie.serie === "energy_reverse")

    return energyReverse?.value || "--"
  }

  function getAverageConsumption() {
    if (!detailsDeviceState.currentData) return "";

    const { readingsFormatted } = formatReadings(detailsDeviceState.currentData);


    const dailyConsumption = convertDailyReading(readingsFormatted);

    const readingAreTheSame = dailyConsumption.every(
      (reading) => reading.reading === dailyConsumption[0].reading
    );

    if (readingAreTheSame) return "--";

    const formattedData: GLBarChartDataProps[] = dailyConsumption.map(
      (reading) => {
        return {
          x: reading.reading,
          label: format(new Date(reading.time), "MM/dd/yyyy"),
          unit: detailsDeviceState.device?.channels[0].unit,
        };
      }
    );

    const average = getAverage(formattedData).toFixed(2);

    return average;
  }

  function getPeakPeriod() {
    if (!detailsDeviceState.currentData) return "";

    const { readingsFormatted } = formatReadings(detailsDeviceState.currentData);

    const dailyConsumption = convertDailyReading(readingsFormatted);

    const readingAreTheSame = dailyConsumption.every(
      (reading) => reading.reading === dailyConsumption[0].reading
    );

    if (readingAreTheSame) return "--";

    const dayWithPeriodMaxReading = dailyConsumption.find(
      (reading) =>
        reading.reading ===
        Math.max(...dailyConsumption.map((readingMax) => readingMax.reading))
    );

    return dayWithPeriodMaxReading
      ? format(dayWithPeriodMaxReading.time, "EEE, MMM d", {
        locale: langActive,
      })
      : "";
  }

  return {
    bigNumbers,
    isLoading: detailsDeviceState.isLoading,
  };
};
