import { useGroupsDevicesStore } from "@group-link-one/grouplink-components";
import { parse } from "csv-parse/browser/esm";
import { useEffect, useState } from "react";

export const useCsvTransfer = () => {
  const [devicesID, setDevicesID] = useState<number[]>([])
  const [devicesIDChecked, setDevicesIDChecked] = useState<number[]>([])

  const {
    actions: groupsDevicesActions,
  } = useGroupsDevicesStore()

  function onDropCsv(result: string) {

    if (typeof result === "string" && result.startsWith("data:text/csv;base64,")) {
      // Remove o prefixo 'data:text/csv;base64,'
      const base64String = result.replace("data:text/csv;base64,", "");

      // Decodifica a string base64 para um ArrayBuffer
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Converte o ArrayBuffer para uma string de texto
      const decodedCsv = new TextDecoder().decode(byteArray);

      // Analisa o CSV
      parse(decodedCsv, { delimiter: ",", columns: false, trim: true }, (err, data) => {
        if (err) {
          // console.error("Erro ao analisar o CSV:", err);
        } else {
          // console.log("Dados CSV analisados: ", data);

          const dataWithoutDuplicated: number[] = Array.from(new Set(data.flat().map(Number)))
          const dataFormatted: number[] = dataWithoutDuplicated
            .filter((deviceID) => !isNaN(deviceID))
            .filter((deviceID) => String(deviceID).length === 10)

          setDevicesID(dataFormatted)
          setDevicesIDChecked(dataFormatted)
        }
      });
    } else {
      console.error("Formato de arquivo não suportado ou erro ao carregar o arquivo.");
    }
  }

  function onCheckDevice(id: number, checked: boolean) {
    if (checked) {

      setDevicesIDChecked([
        ...devicesIDChecked,
        id
      ])

      return
    }

    setDevicesIDChecked(devicesIDChecked.filter(deviceID => deviceID !== id))
  }

  useEffect(() => {
    groupsDevicesActions.setDevicesIds(devicesIDChecked)
  }, [devicesIDChecked])

  return {
    devicesID,
    devicesIDChecked,
    onDropCsv,
    onCheckDevice
  }
}
