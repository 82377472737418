import { PaginateResponseProps } from "@group-link-one/grouplink-components";

import { Application } from "../deviceListService/useDeviceListService.types";
import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import { GetEventListParams, GetEventListResponse } from "./useEventListService.types";

export const useEventListService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getEventList = async ({
    from,
    until,
    device_id,
    ipp,
    next_page_token,
    application,
  }: GetEventListParams): Promise<PaginateResponseProps<GetEventListResponse[]>> => {

    const urlMap: Record<Application, string> = {
      GLUtilitiesWater: "/util-water/event-list",
      GLUtilitiesEnergy: "/util-kwh/event-list",
      GLSmartRetail: "/sr/event-list",
      GLTracking: "/tracking/event-list",
      GLUtilitiesLight: "/util-water/event-list",
    }

    return (await httpPrivate.get(urlMap[application], {
      params: {
        from,
        until,
        device_id,
        ipp,
        next_page_token,
      },
    })).data;
  };

  const getDevices = async () => {
    return await httpPrivate.get("/sr/device-name");
  };

  return {
    getEventList,
    getDevices,
  };
};
