import { GLEventList } from "@group-link-one/grouplink-components";

import { EventListTableContent } from "./Content/TableContent";
import { useEventList } from "./useEventList";

export const EventList = () => {
  const { relativeFilterTimeOptions } = useEventList();

  return <GLEventList
    tableContent={<EventListTableContent />}
    relativeFilterTimeOptions={relativeFilterTimeOptions}
    isDeviceIdSearchByEntireID={true}
    hasFilterByRelativeTime
    hasInfiniteScrollOnDeviceIdsList
  />;
};
