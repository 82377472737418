import { create } from "zustand";

interface ThemeActiveStoreState {
  isDarkMode: boolean;
}

interface ThemeActiveStoreActions {
  listenDarkMode: () => void;
}

interface ThemeActiveStore {
  state: ThemeActiveStoreState;
  actions: ThemeActiveStoreActions;
}

export const useThemeActiveStore = create<ThemeActiveStore>((set) => ({
  state: {
    isDarkMode: window.matchMedia("(prefers-color-scheme: dark)").matches,
  },
  actions: {
    listenDarkMode: () => {
      const darkModeListener = window.matchMedia(
        "(prefers-color-scheme: dark)"
      );

      darkModeListener.addEventListener("change", (event) => {
        set(() => ({
          state: {
            isDarkMode: event.matches,
          },
        }));
      });
    },
  },
}));
