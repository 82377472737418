import "firebase/firestore";

import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { env } from "../env";

const firebaseConfig = JSON.parse(env.REACT_APP_FB_CONFIG_JSON);

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
