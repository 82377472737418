import { Marker, MarkerClusterer } from "@react-google-maps/api"

import { DeviceMapResponse } from "../../../../Services/deviceListService/useDeviceListService.types"
import { useMarker } from "./useMarker"

export interface GLMarkerProps {
  device: DeviceMapResponse
  markerIcon?: string | google.maps.Icon | google.maps.Symbol | undefined
  clusterer: any | MarkerClusterer | undefined
}

export function MarkerMap({ device, clusterer }: GLMarkerProps) {

  const {
    markerIcon,
    onMarkerClick
  } = useMarker({ device, clusterer })

  return (
    <Marker
      key={device.device_id}
      position={{ lat: device.lat, lng: device.long }}
      icon={markerIcon}
      clusterer={clusterer}
      animation={google.maps.Animation.DROP}
      onClick={() => {
        onMarkerClick(device)
      }}
    />
  )
}
