import { useLocation, useNavigate } from "react-router-dom";

export const useExtractURLSearch = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const extractTokenFromSearch = (param: string) => {
    const params = new URLSearchParams(search);
    return params.get(param);
  };

  const setParams = (newParams: Record<string, string>) => {
    const params = new URLSearchParams(search);

    // Itera sobre os novos parâmetros e os define
    Object.entries(newParams).forEach(([key, value]) => {
      params.set(key, value);
    });

    // Atualiza a URL com todos os novos parâmetros de uma vez
    navigate({ pathname, search: params.toString() });
  };


  return {
    extractTokenFromSearch,
    setParams
  };
};
