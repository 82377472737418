
import { IOrganizationData, IOrganizationReqParams } from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useOrganizationsService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const createOrganization = async (data: IOrganizationData) => {
    return await httpPrivate.post("/organization", data);
  };

  const getOrganizations = async ({
    org_name,
    occupational_area,
    use_case,
  }: IOrganizationReqParams) => {
    return await httpPrivate.get("/organization", {
      params: {
        org_name: org_name && org_name,
        occupational_area,
        use_case: use_case ? use_case : "sr",
      },
    });
  };

  const updateOrganization = async (
    id: number,
    data: Partial<IOrganizationData>
  ) => {
    return await httpPrivate.patch(`/organization/${id}`, data);
  };

  return {
    createOrganization,
    getOrganizations,
    updateOrganization,
  };
};
