import { useQueryClient } from "@tanstack/react-query";

import { useGroupListService } from "../../Services/groupListService/useGroupListService";

export const useDeviceGroupsUtils = () => {
  const { getOrgDeviceGroupId } = useGroupListService();

  const queryClient = useQueryClient();

  async function getDeviceGroup(id: number) {
    const response = await queryClient.fetchQuery({
      queryKey: ["get-device-group", id],
      queryFn: () => getOrgDeviceGroupId(id),
    })

    return response;
  }

  return {
    getDeviceGroup,
  }
}
