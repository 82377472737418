import { PaginateResponseProps } from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import {
  CustomerImpersonation,
  GetCustomersParams,
  GetCustomersResponse,
  GetGroupByIdParams,
  GetGroupByIdResponse,
} from "./useCustomerService.types";

export const useCustomerService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getCustomers = async (
    params: GetCustomersParams
  ): Promise<PaginateResponseProps<GetCustomersResponse[]>> => {
    const response = await httpPrivate.get("/customer", {
      params,
    });

    return response.data;
  };

  const getGroupById = async (
    params: GetGroupByIdParams
  ): Promise<GetGroupByIdResponse> => {
    const response = await httpPrivate.get(`/org-group/${params.id}`);

    return response.data;
  };

  const customerImpersonation = async (data: CustomerImpersonation) => {
    const response = await httpPrivate.post("/customer-impersonation", data);

    return response.data;
  };

  return {
    getCustomers,
    getGroupById,
    customerImpersonation,
  };
};
