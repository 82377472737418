import { Colors, GL_COLORS, GL_GAS, GL_IDEA, GL_THUNDERBOLT, GL_WATER } from "@group-link-one/grouplink-components";
import { ReactElement } from "react";

import PinEneryGreen from "../../../images/DeviceList/energy-green"
import PinEneryRed from "../../../images/DeviceList/energy-red"
import PinGasGreen from "../../../images/DeviceList/gas-green"
import PinGasRed from "../../../images/DeviceList/gas-red"
import PinStreetLightGreen from "../../../images/DeviceList/street-light-green"
import PinStreetLightRed from "../../../images/DeviceList/street-light-red"
import { useWaterIcon } from "../../../images/DeviceList/water-green"
import { Application, DeviceMeasurementCategory } from "../../../Services/deviceListService/useDeviceListService.types";

interface GetOptionsByApplicationParams {
  type?: Application;
  deviceMeasurementCategory?: DeviceMeasurementCategory
}

interface ApplicationOptions {
  text: string;
  unit: string;
  color: string;
  hexColor: string;
  icon: (size: number, fill: string) => ReactElement;
  svgIcon?: {
    green: string | ( (type: "red" | "green") => string );
    red: string | ( (type: "red" | "green") => string );
  };
}

const energy: ApplicationOptions = {
  color: GL_COLORS.PURPLE,
  text: "Energy",
  unit: "kWh",
  hexColor: "#7523D7",
  icon: (size, fill) => <GL_THUNDERBOLT size={size} fill={fill} />,
  svgIcon: {
    green: PinEneryGreen,
    red: PinEneryRed,
  },
};

const water: ApplicationOptions = {
  color: GL_COLORS.ACCENT_COLOR,
  text: "Water",
  unit: "m³",
  icon: (size, fill) => <GL_WATER size={size} fill={fill} />,
  hexColor: "#0033FF",
  svgIcon: {
    green: useWaterIcon,
    red: useWaterIcon,
  },
};

const gas: ApplicationOptions = {
  color: Colors.ORANGE,
  text: "Gas",
  unit: "m³",
  hexColor: "#ff9966",
  icon: (size, fill) => <GL_GAS size={size} fill={fill} />,
  svgIcon: {
    green: PinGasGreen,
    red: PinGasRed,
  },
};

const streetlight: ApplicationOptions = {
  color: Colors.LIGHT_BLUE,
  text: "Street Light",
  unit: "Wh",
  hexColor: "#00DCFF",
  icon: (size, fill) => <GL_IDEA size={size} fill={fill} />,
  svgIcon: {
    green: PinStreetLightGreen,
    red: PinStreetLightRed,
  },
};

export function getOptionsByApplication({
  type,
  deviceMeasurementCategory
}: GetOptionsByApplicationParams): ApplicationOptions {
  switch (type) {
    case "GLUtilitiesEnergy":
      return energy;

    case "GLUtilitiesWater":
      if (deviceMeasurementCategory?.includes("water")) {
        return water;
      }

      if (deviceMeasurementCategory?.includes("gas")) {
        return gas;
      }

      return water

    case "GLUtilitiesLight":
      return streetlight;

    default:
      return water
  }
}
