import { create } from "zustand";

export interface DeviceListStoreState {
  deviceId: number | undefined
  modalType: "edit" | "register"

  modalDecomissionIsOpen: boolean
  isDecomissionLoading: boolean
}

export interface DeviceListStoreActions {
  setDeviceId: (deviceId: number) => void;
  setModalType: (modalType: "edit" | "register") => void;

  setModalDecomissionIsOpen: (modalDecomissionIsOpen: boolean) => void;
  setIsDecomissionLoading: (isDecomissionLoading: boolean) => void;

  resetAll: () => void;
}

export interface DeviceListStore {
  state: DeviceListStoreState;
  actions: DeviceListStoreActions;
}

export const useDeviceListStore = create<DeviceListStore>((set) => ({
  state: {
    useCaseOptionsAvailables: undefined,
    useCaseOptionsSelecteds: undefined,
    userGroupSelecteds: undefined,
    deviceId: undefined,
    modalType: "edit",
    modalDecomissionIsOpen: false,
    isDecomissionLoading: false,
  },
  actions: {
    setDeviceId: (deviceId) =>
      set((state) => ({
        state: {
          ...state.state,
          deviceId,
        }
      })),

    setModalType: (modalType) =>
      set((state) => ({
        state: {
          ...state.state,
          modalType,
        }
      })),

    setModalDecomissionIsOpen: (modalDecomissionIsOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          modalDecomissionIsOpen,

        }
      })),

    setIsDecomissionLoading: (isDecomissionLoading) =>
      set((state) => ({
        state: {
          ...state.state,
          isDecomissionLoading,
        }
      })),

    resetAll: () =>
      set(() => ({
        state: {
          useCaseOptionsAvailables: undefined,
          useCaseOptionsSelecteds: undefined,
          userGroupSelecteds: undefined,
          deviceId: undefined,
          modalType: "edit",
          modalDecomissionIsOpen: false,
          isDecomissionLoading: false,
        }
      })),
  },
}));
