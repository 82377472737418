import { ReactNode } from "react";

export interface StatementProps {
  condition: boolean;
  children: ReactNode;
}

export const If = ({ condition, children }: StatementProps) => {
  return condition ? <>{children}</> : null;
};
