import { useAuth } from "@group-link-one/gl-auth";
import { OnSubmitParams, useToast } from "@group-link-one/grouplink-components";
import { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ErrorType, SmartRetailErrors } from "../../../Errors/errors";

export const useLogin = () => {
  const navigate = useNavigate();
  const { verifyUserAPI } = useAuth();
  const { addToast } = useToast();
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | boolean>(
    ""
  );

  const sendForm = async (data: OnSubmitParams) => {
    try {
      const response = await verifyUserAPI(data);

      if (response.error) {
        setLoginErrorMessage(true);
        throw new AxiosError(response.error.data.error);
      }

      navigate("/");
    } catch (error) {
      if (error instanceof AxiosError) {
        const typeError = error.message as ErrorType;

        const { title, message } = new SmartRetailErrors({
          error: typeError,
        }).getError();

        addToast({
          title,
          message,
          type: "error",
        });
      }
    }
  };

  const redirectToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return {
    sendForm,
    redirectToForgotPassword,
    loginErrorMessage,
  };
};
