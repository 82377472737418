import {
  GL_COLORS,
  GLLineChartDataProps,
  GLLineChartLineProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { differenceInDays, format } from "date-fns";
import { useEffect, useState } from "react";

import { GetDevicesActivatedDailyReadingsRow } from "../../../../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "../../../store/details-device-store";
import { getOptionsByApplication } from "../../../utils/getOptionsByApplication";
import { useGetDailyReadings } from "../useGetDailyReadings";

export const useConsumptionHistory = () => {
  const { t } = useI18n();
  const [lines, setLines] = useState<GLLineChartLineProps[]>([]);

  const { state: detailsDeviceState } = useDetailsDeviceStore();
  const { formatReadings, getPreviousReading } = useGetDailyReadings();

  function formatData(readings?: GetDevicesActivatedDailyReadingsRow[]) {
    if (
      !readings ||
      !detailsDeviceState.currentData ||
      !detailsDeviceState.range.from ||
      !detailsDeviceState.range.to
    )
      return [];

    const { readingsFormatted } = formatReadings(readings);

    const formattedData: GLLineChartDataProps[] = readingsFormatted.map(
      (reading) => {
        return {
          label: format(new Date(reading.time), "MM/dd/yyyy"),
          x: reading.reading,
          unit: getOptionsByApplication({
            type: detailsDeviceState.device?.application,
          }).unit,
        };
      }
    );

    formatLines(formattedData, readings);
  }

  function formatLines(
    lineChartData: GLLineChartDataProps[],
    readings: GetDevicesActivatedDailyReadingsRow[]
  ) {
    const deviceActivatedAt = format(
      new Date(detailsDeviceState.device?.channels[0].activated_at || ""),
      "MM/dd/yyyy"
    );

    const hasPeriodPreActivation = lineChartData
      .map((item) => {
        return (
          differenceInDays(new Date(item.label), new Date(deviceActivatedAt)) <
          0
        );
      })
      .some((item) => item);

    const hasPeriodWithValueZero = lineChartData
      .map((item) => item.x === 0)
      .some((item) => item);

    if (hasPeriodPreActivation) {
      setWhenHasPeriodPreActivation(lineChartData);
    } else {
      // console.log("caiu", lineChartData);
      // setWhenHasNoPeriodPreActivation(lineChartData)
      if (!hasPeriodWithValueZero) {
        setWhenHasNoPeriodPreActivation(lineChartData);
      } else {
        setWhenHasPeriodWithValueZero(lineChartData, readings);
      }
    }
  }

  function setWhenHasPeriodPreActivation(
    lineChartData: GLLineChartDataProps[]
  ) {
    const dayWithAFirstValue = lineChartData.findIndex((item) => item.x !== 0);

    const daysWithNoValue = lineChartData
      .slice(0, dayWithAFirstValue)
      .map((item) => ({ ...item, type: "disabled", y: 0 }));

    const daysWithValue = lineChartData
      .slice(dayWithAFirstValue)
      .map((item) => ({ ...item, type: "active" }));

    if (dayWithAFirstValue === -1) {
      const newLines: GLLineChartLineProps[] = [
        {
          dataKey: "y",
          name: t("deviceList.detailsModal.ut.graphs.one.legends.pre"),
          stroke: GL_COLORS.DISABLED_FONT_COLOR,
          type: "disabled",
          data: daysWithNoValue,
        },
        {
          dataKey: "x",
          name: t("deviceList.detailsModal.ut.graphs.one.legends.post"),
          animationBeginInSeconds: 1,
          stroke: getOptionsByApplication({
            type: detailsDeviceState.device?.application,
            deviceMeasurementCategory:
              detailsDeviceState.device?.meta.device_measurement_category ||
              "water",
          }).color,
          type: "active",
          data: undefined,
        },
      ];

      setLines(newLines);
      return;
    }

    const periodPreActivation = formatPeriodPreActivation(
      daysWithNoValue,
      daysWithValue
    );
    const newLines: GLLineChartLineProps[] = [
      {
        dataKey: "y",
        name: t("deviceList.detailsModal.ut.graphs.one.legends.pre"),
        stroke: GL_COLORS.DISABLED_FONT_COLOR,
        type: "disabled",
        data: periodPreActivation,
      },
      {
        dataKey: "x",
        name: t("deviceList.detailsModal.ut.graphs.one.legends.post"),
        animationBeginInSeconds: 1,
        stroke: getOptionsByApplication({
          type: detailsDeviceState.device?.application,
          deviceMeasurementCategory:
            detailsDeviceState.device?.meta.device_measurement_category ||
            "water",
        }).color,
        type: "active",
        data: daysWithValue,
      },
    ];

    setLines(newLines);
  }

  function formatPeriodPreActivation(
    preActivationData: GLLineChartDataProps[],
    postActivationData: GLLineChartDataProps[]
  ) {
    if (!preActivationData || preActivationData.length === 0) return [];

    if (preActivationData.length > 0 && postActivationData.length > 0) {
      const firstItemOfPostActivation = postActivationData[0];

      return [
        ...preActivationData,
        {
          ...firstItemOfPostActivation,
          type: "disabled",
          y: firstItemOfPostActivation.x,
        },
      ];
    }

    if (preActivationData.length > 0 && postActivationData.length === 0) {
      return preActivationData;
    }
  }

  function setWhenHasNoPeriodPreActivation(
    lineChartData: GLLineChartDataProps[]
  ) {
    // console.log("setWhenHasNoPeriodPreActivation", lineChartData);

    const lineChartDataDisabled = lineChartData.map((item) => ({
      ...item,
      y: item.x === 0 ? 0 : item.x,
      x: item.x === 0 ? undefined : item.x,
      type: "disabled",
    }));

    setLines([
      {
        dataKey: "y",
        name: t("deviceList.detailsModal.ut.graphs.one.legends.pre"),
        stroke: GL_COLORS.DISABLED_FONT_COLOR,
        type: "disabled",
        data: lineChartDataDisabled,
      },
      {
        dataKey: "x",
        name: t("deviceList.detailsModal.ut.graphs.one.legends.post"),
        stroke: getOptionsByApplication({
          type: detailsDeviceState.device?.application,
          deviceMeasurementCategory:
            detailsDeviceState.device?.meta.device_measurement_category ||
            "water",
        }).color,
        type: "active",
        data: lineChartData.map((item) => ({
          ...item,
          x: item.x === 0 ? undefined : item.x,
        })), // se precisar aparecer a linha trecejada no meio
        // data: lineChartData,
      },
    ]);
  }

  function setWhenHasPeriodWithValueZero(
    lineChartData: GLLineChartDataProps[],
    readings: GetDevicesActivatedDailyReadingsRow[]
  ) {
    // const lineChartDataWithValueZero = lineChartData
    //   .filter((item) => item.x === 0)
    //   .map((item) => ({ ...item, type: "disabled", y: 0 }));

    // const lineChartDataWithValue = lineChartData.filter((item) => item.x !== 0);

    // const periodPreValue = formatPeriodPreActivation(
    //   lineChartDataWithValueZero,
    //   lineChartDataWithValue
    // );

    setLines([
      {
        dataKey: "y",
        name: t("deviceList.detailsModal.ut.graphs.one.legends.pre"),
        stroke: GL_COLORS.DISABLED_FONT_COLOR,
        type: "disabled",
        data: lineChartData.map((item, index) => ({
          ...item,
          type: "disabled",
          y:
            item.x === 0
              ? getPreviousReading(index, readings, lineChartData)
              : item.x,
        })),
      },
      {
        dataKey: "x",
        name: t("deviceList.detailsModal.ut.graphs.one.legends.post"),
        // animationBeginInSeconds: 1,
        stroke: getOptionsByApplication({
          type: detailsDeviceState.device?.application,
          deviceMeasurementCategory:
            detailsDeviceState.device?.meta.device_measurement_category ||
            "water",
        }).color,
        type: "active",
        data: lineChartData.map((item) => ({
          ...item,
          x: item.x === 0 ? undefined : item.x,
        })), // se precisar aparecer a linha trecejada no meio
      },
    ]);
  }

  useEffect(() => {
    formatData(detailsDeviceState.currentData);
  }, [detailsDeviceState.currentData]);

  return {
    lines,
    detailsDeviceState,
    isLoading: detailsDeviceState.isLoading,
  };
};
