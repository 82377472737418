import { GLBox } from "@group-link-one/grouplink-components";
import styled from "styled-components";

export const BigNumbersContainer = styled(GLBox).attrs({
  gap: 20,
  animation: "fade-in-down",
  transition: {
    delay: 0.4,
    duration: 1.4,
  },
})`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  /* & > div:nth-child(5) {
    grid-column: 1 / 3;
  }

  & > div:nth-child(6) {
    grid-column: 3 / 5;
  } */

  @media (max-width: 1350px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ChartsContainer = styled(GLBox).attrs({
  gap: 20,
  animation: "fade-in-down",
  direction: "row",
  transition: {
    delay: 0.5,
    duration: 1.4,
  },
})`
  @media (max-width: 1350px) {
    flex-direction: column !important;
  }
`;
