import {
  GL_COLORS,
  GLBox,
  GLCardTemplate,
  GLTooltip,
  GLTypography,
} from "@group-link-one/grouplink-components";
import { format } from "date-fns";

import { EventCardProps } from "../EventListCards.types";
import { useEventListCard } from "../useEventListCard";

export function WaterCard({ event }: EventCardProps) {
  const { t, isMobile, formatTime, formatCubicMeters, formatAlarms } = useEventListCard();

  if (!event) return null;

  return (
    <GLCardTemplate.Provider>
      <GLCardTemplate.Root borderRadius="0px">
        {!isMobile ? (
          <GLCardTemplate.Header>
            <GLCardTemplate.Column
              style={{
                borderRadius: "0px 10px",
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography
                text={String(format(event.eventCreated, "dd/MM/yyyy - HH:mm:ss"))}
                color={"FONT_COLOR"}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: "0px 10px",
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography
                text={String(format(event.eventSent, "dd/MM/yyyy - HH:mm:ss"))}
                color={"FONT_COLOR"}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: "0px 10px",
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography
                text={formatTime(event.delta)}
                color={"FONT_COLOR"}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: "0px 10px",
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography text={event.deviceId} color={"FONT_COLOR"} />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: "0px 10px",
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography
                text={event.liters ? formatCubicMeters(event.liters) : "--"}
                color={"FONT_COLOR"}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: "0px 10px",
              }}
              align="center"
              justify="flex-start"
            >
              <GLTooltip
                position="top-left"
                text={event.alarms && event.alarms?.length > 0
                  ? event.alarms.map((alarm) => alarm).join(", ")
                  : undefined
                }
              >
                <GLTypography
                  text={formatAlarms(event.alarms?.length || 0)}
                  color={"FONT_COLOR"}
                />
              </GLTooltip>
            </GLCardTemplate.Column>

          </GLCardTemplate.Header>
        ) : (
          <>
            <GLCardTemplate.Header direction="row">
              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="flex-start"
                justify="flex-start"
                direction="column"
                gap={5}
              >
                <GLTypography
                  text={t("eventList.columns.device_id")}
                  color={"FONT_COLOR_DARK_GREY"}
                  fontSize={3}
                  weight={600}
                />
                <GLTypography
                  fontSize={3}
                  text={event.deviceId}
                  weight={600}
                  color={"FONT_COLOR"}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="flex-start"
                justify="flex-start"
                direction="column"
                gap={5}
              >
                <GLTypography
                  text={t("eventList.columns.created_at")}
                  color={"FONT_COLOR_DARK_GREY"}
                  fontSize={3}
                  weight={600}
                />
                <GLTypography
                  text={String(format(event.eventCreated, "dd/MM/yyyy - HH:mm:ss"))}
                  color={"FONT_COLOR"}
                  fontSize={3}

                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Arrow />
            </GLCardTemplate.Header>

            <GLCardTemplate.Content>
              <GLBox
                direction="column"
                style={{
                  padding: 15,
                  backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                  flexWrap: "wrap",
                }}
                gap={20}
              >

                <GLBox>
                  <GLBox
                    style={{
                      borderRadius: "0px 10px",
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  // width="fit-content"
                  >
                    <GLTypography
                      text={t("eventList.columns.sent")}
                      color={"FONT_COLOR_DARK_GREY"}
                      weight={600}
                      fontSize={3}

                    />
                    <GLTypography
                      text={String(format(event.eventSent, "dd/MM/yyyy - HH:mm:ss"))}
                      color={"FONT_COLOR"}
                      fontSize={3}

                    />
                  </GLBox>

                  <GLBox
                    style={{
                      borderRadius: "0px 10px",
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  // width="fit-content"

                  >
                    <GLTypography
                      text={t("eventList.columns.liters")}
                      color={"FONT_COLOR_DARK_GREY"}
                      weight={600}
                      fontSize={3}

                    />

                    <GLTypography
                      text={event.liters ? formatCubicMeters(event.liters) : "--"}
                      color={"FONT_COLOR"}
                      fontSize={3}

                    />
                  </GLBox>

                  <GLBox
                    style={{
                      borderRadius: "0px 10px",
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  // width="fit-content"
                  >
                    <GLTooltip
                      position="top-left"
                      text={event.alarms && event.alarms?.length > 0
                        ? event.alarms.map((alarm) => alarm).join(", ")
                        : undefined
                      }
                    >
                      <GLTypography
                        text={t("eventList.columns.alarms")}
                        color={"FONT_COLOR_DARK_GREY"}
                        weight={600}
                        fontSize={3}
                        style={{ marginBottom: 5 }}
                      />

                      <GLTypography
                        text={String(event.alarms?.length + " alarms" || "--")}
                        color={"FONT_COLOR"}
                        fontSize={3}
                      />
                    </GLTooltip>
                  </GLBox>

                </GLBox>

                <GLBox>
                  {/* <GLBox
                    style={{
                      borderRadius: "0px 10px",
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  // width="fit-content"

                  >
                    <GLTypography
                      text="RSSI"
                      color={"FONT_COLOR_DARK_GREY"}
                      weight={600}
                      fontSize={3}

                    />
                    <GLTypography
                      text={String(event.beacon_rssi || "--")}
                      color={"FONT_COLOR"}
                      fontSize={3}

                    />
                  </GLBox> */}

                  <GLBox
                    style={{
                      borderRadius: "0px 10px",
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  // width="fit-content"

                  >
                    <GLTypography
                      text={t("eventList.columns.duration")}
                      color={"FONT_COLOR_DARK_GREY"}
                      weight={600}
                      fontSize={3}

                    />
                    <GLTypography
                      text={formatTime(event.delta)}
                      color={"FONT_COLOR"}
                      fontSize={3}

                    />
                  </GLBox>

                  <GLBox>
                    <></>
                  </GLBox>

                  <GLBox>
                    <></>
                  </GLBox>
                </GLBox>

              </GLBox>
            </GLCardTemplate.Content>
          </>
        )}
      </GLCardTemplate.Root>
    </GLCardTemplate.Provider>
  );
}
