import { TabProps, useI18n } from "@group-link-one/grouplink-components";

export const usePagination = () => {
  const { t } = useI18n();

  const tabs: TabProps[] = [
    {
      id: 1,
      active: false,
      cacheKey: "users",
      count: undefined,
      name: t("impersonate.modal.steps.user.tabs.users"),
      onClick: () => {},
    },
  ];

  const lastPageToken = {
    users: {
      token: undefined,
      page: undefined,
    },
  };

  return {
    tabs,
    lastPageToken,
  };
};
