import { PaginateResponseProps } from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import {
  BignumbersParams,
  DeviceMapResponse,
  GetDeviceHealthCheckCountParams,
  GetDeviceHealthCheckCountResponse,
  GetDeviceMapParams,
  GetDevicesActivatedDailyReadingsParams,
  GetDevicesActivatedDailyReadingsResponse,
  GetDevicesActivatedLastReadingsParams,
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesCountParams,
  GetDevicesCountResponse,
  GetDevicesPendingActivationParams,
  GetDevicesPendingActivationResponse,
  GetDeviceStatusParams,
  RegisterDeviceBody,
  RegisterDeviceResponse,
  UpdateDeviceNameParams,
  UpdateDeviceParams,
} from "./useDeviceListService.types";

export const useDeviceListService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getDevicesActivatedLastReadings = async ({
    ...params
  }: GetDevicesActivatedLastReadingsParams): Promise<
    PaginateResponseProps<GetDevicesActivatedLastReadingsResponse[]>
  > => {
    const response = await httpPrivate.get("/devices-activated/last-reading", {
      params,
    });

    return response.data;
  };

  const getDevicesActivatedDailyReadings = async ({
    ...params
  }: GetDevicesActivatedDailyReadingsParams): Promise<GetDevicesActivatedDailyReadingsResponse> => {
    const response = await httpPrivate.get("/devices-activated/daily-reading", {
      params,
    });

    return response.data;
  };

  const getDevicesPendingActivation = async ({
    ...params
  }: GetDevicesPendingActivationParams): Promise<
    PaginateResponseProps<GetDevicesPendingActivationResponse[]>
  > => {
    const response = await httpPrivate.get("/devices-pending-activation", {
      params,
    });

    return response.data;
  };

  const getDevicesCount = async (
    params?: GetDevicesCountParams
  ): Promise<GetDevicesCountResponse> => {
    const response = await httpPrivate.get("/device-activation/count", {
      params,
    });

    return response.data;
  };

  const getDevicesActivateds = async (): Promise<any> => {
    const response = await httpPrivate.get("/device-activation/activated");
    return response.data;
  };

  const updateDevice = async (params: UpdateDeviceParams): Promise<any> => {
    const response = await httpPrivate.patch("/device-activation", params);
    return response.data;
  };

  const getBignumbers = async (params: BignumbersParams) => {
    const response = await httpPrivate.get("my-organization/count-panel", {
      params,
    });

    return response;
  };

  const updateDeviceName = async (params: UpdateDeviceNameParams) => {
    const response = await httpPrivate.patch("/sr/device-name", params);

    return response.data;
  };

  const getDeviceMap = async (data: GetDeviceMapParams): Promise<DeviceMapResponse[]> => {
    const response = await httpPrivate.post("/device-activation/device/map", data);

    return response.data;
  }

  const getDevicesHealthCheckCount = async (
    params: GetDeviceHealthCheckCountParams
  ): Promise<GetDeviceHealthCheckCountResponse> => {
    const response = await httpPrivate.get("/device-activation/status/counts", {
      params,
    });

    return response.data;
  };

  const getDeviceStatus = async (params: GetDeviceStatusParams) => {
    const response = await httpPrivate.get("/device-activation/device-status", {
      params,
    });

    return response.data;
  }

  const registerDevice = async (
    body: RegisterDeviceBody
  ): Promise<RegisterDeviceResponse> => {
    const response = await httpPrivate.post("/device-activation", body);

    return response.data;
  };

  const deviceDecomission = async (device_id: number) => {
    const response = await httpPrivate.post("/device-decommission", {
      device_id,
    });
    return response.data
  }

  return {
    getDevicesActivatedLastReadings,
    getDevicesActivatedDailyReadings,
    getDevicesCount,
    getDevicesActivateds,
    getDevicesPendingActivation,
    getBignumbers,
    getDevicesHealthCheckCount,
    getDeviceMap,
    getDeviceStatus,
    updateDevice,
    updateDeviceName,
    registerDevice,
    deviceDecomission
  };
};
