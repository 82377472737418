import { GL_COLORS, GL_DELETE, GLBox, GLBoxChart, GLButton, GLButtonIcon, GLPieChart, GLTypography, SkeletonBox, StyledAnim } from "@group-link-one/grouplink-components";
import { format } from "date-fns";

// import { Choose, Otherwise, When } from "../../../utils/GLStatement/Choose";
import { If } from "../../../utils/GLStatement/If";
import { getOptionsByApplication } from "../../DeviceList/utils/getOptionsByApplication";
import { HealthCheckModal } from "./DeviceModalStyle";
import { useDeviceModal } from "./useDeviceModal";

export function DeviceModal() {
  const {
    communicationRate,
    dailyReadingInfo,
    t,
    pieChartData,
    healthCheckState,
    healthCheckActions,
    onClickSeeDetails
  } = useDeviceModal();

  return (
    <HealthCheckModal
      initial={{
        opacity: 0,
        y: 50,

      }}
      animate={{
        opacity: healthCheckState.deviceModalIsOpen ? 1 : 0,
        y: healthCheckState.deviceModalIsOpen ? 0 : 50,
        pointerEvents: healthCheckState.deviceModalIsOpen ? "all" : "none",
        animation: ["easeInOut"],
        transition: {
          duration: 0.2,
        },
      }}
    >
      <GLBox
        height="100%"
        justify="center"
        align="center"
        direction="column"
        style={{ position: "relative" }}
      >

        <If condition={!healthCheckState.isFetchingDeviceInfo}>

          <If condition={!!healthCheckState.deviceSelected}>
            <GLBox align="center" alignSelf="center" direction="column">
              <GLButtonIcon
                variant="outline"
                icon={<GL_DELETE />}
                color={GL_COLORS.FONT_COLOR}
                style={{
                  position: "absolute",
                  top: -40,
                  right: -10,
                  zIndex: 1,
                }}
                onClick={() => {
                  healthCheckActions.setDeviceSelected(undefined)
                  healthCheckActions.setDeviceModalIsOpen(false)
                }}
              />

              <GLBox style={{ marginLeft: "auto" }}>
                <GLBox
                  direction="column"
                  width="fit-content"
                  justify="center"
                  align="center"
                  style={{ paddingInline: 20 }}
                >
                  <GLBox
                    width={150}
                    height={150}
                    justify="center"
                    align="center"
                    borderRadius="50%"
                    style={{
                      backgroundColor: GL_COLORS.ACCENT_COLOR,
                      boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
                      marginBottom: 10,
                    }}
                  >
                    {getOptionsByApplication({
                      type: healthCheckState.deviceSelected?.application
                    }).icon(64, GL_COLORS.WHITE)}
                  </GLBox>

                  <GLBox width="fit-content" gap={5} direction="column" align="center">
                    <GLTypography
                      text={String(healthCheckState.deviceSelected?.channels[0].name) || ""}
                      weight={600}
                      fontSize={5}
                      color="FONT_COLOR"
                    />
                    <GLTypography
                      text={String(healthCheckState.deviceSelected?.channels[0].remote_id) || ""}
                      weight={400}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                  </GLBox>

                  <GLBox
                    align="flex-end"
                    justify="center"
                    height="fit-content"
                    gap={5}
                    style={{
                      marginTop: "auto"
                    }}
                  >
                    <GLButton
                      align="center"
                      variant="outline"
                      border
                      text="Ver detalhes"
                      weight={600}
                      padding
                      onClick={() => onClickSeeDetails()}
                      style={{ width: "fit-content" }}

                    />
                  </GLBox>
                </GLBox>

                <GLBox height="100%">
                  <GLBox
                    direction="column"
                    width="50%"
                    height="100%"
                    justify="space-around"
                    style={{
                      backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                      padding: 20
                    }}
                  >
                    <GLBox width="fit-content" gap={5}>
                      <GLTypography
                        text="Device ID:"
                        weight={400}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                      <GLTypography
                        text={String(healthCheckState.deviceSelected?.device_id) || ""}
                        weight={600}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                    </GLBox>

                    <GLBox width="fit-content" gap={5}>
                      <GLTypography
                        text={`${t("healthCheck.deviceSelected.daysWithReadings")}:`}
                        weight={400}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                      <GLTypography
                        text={String(dailyReadingInfo?.with_readings) || "0"}
                        weight={600}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                    </GLBox>

                    <GLBox width="fit-content" gap={5}>
                      <GLTypography
                        text={`${t("healthCheck.deviceSelected.daysWithoutReadings")}:`}
                        weight={400}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                      <GLTypography
                        text={String(dailyReadingInfo?.without_readings) || "0"}
                        weight={600}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                    </GLBox>

                    <GLBox width="fit-content" gap={5}>
                      <GLTypography
                        text={`${t("healthCheck.deviceSelected.lastReading")}:`}
                        weight={400}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                      <GLTypography
                        text={
                          String(
                            healthCheckState.deviceSelected?.channels[0].last_reading +
                            ` ${healthCheckState.deviceSelected?.channels[0].unit}`
                          )
                        }
                        weight={600}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                    </GLBox>

                    <GLBox width="fit-content" gap={5}>
                      <GLTypography
                        text={`${t("healthCheck.deviceSelected.lastSeen")}:`}
                        weight={400}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                      <GLTypography
                        text={format(healthCheckState.deviceSelected?.last_seen || new Date(), "dd/MM/yyyy HH:mm:ss")}
                        weight={600}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                    </GLBox>
                  </GLBox>

                  <GLBoxChart
                    textSwitcherId="health-check-chart"
                    title={t("healthCheck.deviceSelected.communicationRate.title")}
                    marginBottomTitle={0}
                    marginTopChart={0}
                    tooltipText={t("healthCheck.deviceSelected.communicationRate.tooltip")}
                    hasBorder={false}
                    mainStyle={{
                      padding: 20,
                      width: "50%",
                      height: "100%",
                      backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                    }}
                    chart={
                      <GLPieChart
                        hasTooltip={false}
                        responsiveContainerOptions={{
                          height: 200,
                        }}
                        data={pieChartData}
                        defaultValue={communicationRate}
                      />
                    }
                  />
                </GLBox>

              </GLBox>

            </GLBox>
          </If>

          <If condition={!healthCheckState.deviceSelected}>
            <GLBox direction="column" height="100%" justify="center" align="center">
              <GLTypography
                text="Não foi possível carregar as informações do dispositivo."
                weight={600}
                fontSize={6}
                color="FONT_COLOR"
                style={{ textAlign: "center", marginBottom: 20 }}
              />

              <GLButton
                align="center"
                variant="fill"
                text="Fechar"
                weight={600}
                padding
                style={{ width: "fit-content", paddingInline: 50 }}
                onClick={() => {
                  healthCheckActions.setDeviceModalIsOpen(false)
                }}
              />
            </GLBox>
          </If>

        </If>

        <If condition={healthCheckState.isFetchingDeviceInfo}>
          <GLBox direction="row" gap={20} align="center" height="100%">
            <GLBox direction="column" width="30%">
              <SkeletonBox height={200} width={100}>
                <StyledAnim />
              </SkeletonBox>

              <SkeletonBox height={50} width={100}>
                <StyledAnim />
              </SkeletonBox>

              <SkeletonBox height={50} width={100}>
                <StyledAnim />
              </SkeletonBox>

            </GLBox>

            <GLBox width="70%" gap={20}>
              <SkeletonBox width={50} height={320}>
                <StyledAnim />
              </SkeletonBox>

              <SkeletonBox width={50} height={320}>
                <StyledAnim />
              </SkeletonBox>
            </GLBox>

          </GLBox>
        </If>

          {/* <Choose> */}
            {/* <When condition={!healthCheckState.isFetchingDeviceInfo}>
              <Choose>
                <When condition={!!healthCheckState.deviceSelected}>
                  <GLBox align="center" alignSelf="center" direction="column">
                    <GLButtonIcon
                      variant="outline"
                      icon={<GL_DELETE />}
                      color={GL_COLORS.FONT_COLOR}
                      style={{
                        position: "absolute",
                        top: -40,
                        right: -10,
                        zIndex: 1,
                      }}
                      onClick={() => {
                        healthCheckActions.setDeviceSelected(undefined)
                        healthCheckActions.setDeviceModalIsOpen(false)
                      }}
                    />

                    <GLBox style={{ marginLeft: "auto" }}>
                      <GLBox
                        direction="column"
                        width="fit-content"
                        justify="center"
                        align="center"
                        style={{ paddingInline: 20 }}
                      >
                        <GLBox
                          width={150}
                          height={150}
                          justify="center"
                          align="center"
                          borderRadius="50%"
                          style={{
                            backgroundColor: GL_COLORS.ACCENT_COLOR,
                            boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
                            marginBottom: 10,
                          }}
                        >
                          {getOptionsByApplication({
                            type: healthCheckState.deviceSelected?.application
                          }).icon(64, GL_COLORS.WHITE)}
                        </GLBox>

                        <GLBox width="fit-content" gap={5} direction="column" align="center">
                          <GLTypography
                            text={String(healthCheckState.deviceSelected?.channels[0].name) || ""}
                            weight={600}
                            fontSize={5}
                            color="FONT_COLOR"
                          />
                          <GLTypography
                            text={String(healthCheckState.deviceSelected?.channels[0].remote_id) || ""}
                            weight={400}
                            fontSize={4}
                            color="FONT_COLOR"
                          />
                        </GLBox>

                        <GLBox
                          align="flex-end"
                          justify="center"
                          height="fit-content"
                          gap={5}
                          style={{
                            marginTop: "auto"
                          }}
                        >
                          <GLButton
                            align="center"
                            variant="outline"
                            border
                            text="Ver detalhes"
                            weight={600}
                            padding
                            onClick={() => onClickSeeDetails()}
                            style={{ width: "fit-content" }}

                          />
                        </GLBox>
                      </GLBox>

                      <GLBox height="100%">
                        <GLBox
                          direction="column"
                          width="50%"
                          height="100%"
                          justify="space-around"
                          style={{
                            backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                            padding: 20
                          }}
                        >
                          <GLBox width="fit-content" gap={5}>
                            <GLTypography
                              text="Device ID:"
                              weight={400}
                              fontSize={4}
                              color="FONT_COLOR"
                            />
                            <GLTypography
                              text={String(healthCheckState.deviceSelected?.device_id) || ""}
                              weight={600}
                              fontSize={4}
                              color="FONT_COLOR"
                            />
                          </GLBox>

                          <GLBox width="fit-content" gap={5}>
                            <GLTypography
                              text="Dias com leitura:"
                              weight={400}
                              fontSize={4}
                              color="FONT_COLOR"
                            />
                            <GLTypography
                              text={String(dailyReadingInfo?.with_readings) || "0"}
                              weight={600}
                              fontSize={4}
                              color="FONT_COLOR"
                            />
                          </GLBox>

                          <GLBox width="fit-content" gap={5}>
                            <GLTypography
                              text="Dias sem leitura:"
                              weight={400}
                              fontSize={4}
                              color="FONT_COLOR"
                            />
                            <GLTypography
                              text={String(dailyReadingInfo?.without_readings) || "0"}
                              weight={600}
                              fontSize={4}
                              color="FONT_COLOR"
                            />
                          </GLBox>

                          <GLBox width="fit-content" gap={5}>
                            <GLTypography
                              text="Última leitura:"
                              weight={400}
                              fontSize={4}
                              color="FONT_COLOR"
                            />
                            <GLTypography
                              text={
                                String(
                                  healthCheckState.deviceSelected?.channels[0].last_reading +
                                  ` ${healthCheckState.deviceSelected?.channels[0].unit}`
                                )
                              }
                              weight={600}
                              fontSize={4}
                              color="FONT_COLOR"
                            />
                          </GLBox>

                          <GLBox width="fit-content" gap={5}>
                            <GLTypography
                              text="Lido por último em:"
                              weight={400}
                              fontSize={4}
                              color="FONT_COLOR"
                            />
                            <GLTypography
                              text={format(healthCheckState.deviceSelected?.last_seen || new Date(), "dd/MM/yyyy HH:mm:ss")}
                              weight={600}
                              fontSize={4}
                              color="FONT_COLOR"
                            />
                          </GLBox>
                        </GLBox>

                        <GLBoxChart
                          textSwitcherId="health-check-chart"
                          title="Taxa de comunicação"
                          marginBottomTitle={0}
                          marginTopChart={0}
                          tooltipText="Taxa de comunicação significa a quantidade de vezes que o dispositivo se comunicou com o servidor em comparação com a quantidade de vezes que deveria ter se comunicado."
                          hasBorder={false}
                          mainStyle={{
                            padding: 20,
                            width: "50%",
                            height: "100%",
                            backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                          }}
                          chart={
                            <GLPieChart
                              hasTooltip={false}
                              responsiveContainerOptions={{
                                height: 200,
                              }}
                              data={pieChartData}
                              defaultValue={communicationRate}
                            />
                          }
                        />
                      </GLBox>

                    </GLBox>

                  </GLBox>
                </When>

                <Otherwise>
                  <GLBox direction="column" height="100%" justify="center" align="center">
                    <GLTypography
                      text="Não foi possível carregar as informações do dispositivo."
                      weight={600}
                      fontSize={6}
                      color="FONT_COLOR"
                      style={{ textAlign: "center", marginBottom: 20 }}
                    />

                    <GLButton
                      align="center"
                      variant="fill"
                      text="Fechar"
                      weight={600}
                      padding
                      style={{ width: "fit-content", paddingInline: 50 }}
                      onClick={() => {
                        healthCheckActions.setDeviceModalIsOpen(false)
                      }}
                    />
                  </GLBox>
                </Otherwise>
              </Choose>
            </When> */}

            {/* <Otherwise>
              <GLBox direction="row" gap={20} align="center" height="100%">
                <GLBox direction="column" width="30%">
                  <SkeletonBox height={200} width={100}>
                    <StyledAnim />
                  </SkeletonBox>

                  <SkeletonBox height={50} width={100}>
                    <StyledAnim />
                  </SkeletonBox>

                  <SkeletonBox height={50} width={100}>
                    <StyledAnim />
                  </SkeletonBox>

                </GLBox>

                <GLBox width="70%" gap={20}>
                  <SkeletonBox width={50} height={320}>
                    <StyledAnim />
                  </SkeletonBox>

                  <SkeletonBox width={50} height={320}>
                    <StyledAnim />
                  </SkeletonBox>
                </GLBox>

              </GLBox>
            </Otherwise> */}
          {/* </Choose> */}

      </GLBox>
    </HealthCheckModal>
  )
}
