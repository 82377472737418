import { GLSettingsMyAccount } from "@group-link-one/grouplink-components";

import { useMyAccount } from "./useMyAccount";

export const MyAccount = () => {
  const { user, handleUpdateAccountInformation } = useMyAccount();

  return (
    <GLSettingsMyAccount
      onSubmitAction={handleUpdateAccountInformation}
      user={user!}
    />
  );
};
