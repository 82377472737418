import { GLBigNumberCard } from "@group-link-one/grouplink-components";
import React from "react";

import { DeviceListBigNumbersContainer } from "./HealthCheckBigNumbersStyle";
import { useHealthCheckBigNumbers } from "./useHealthCheckBigNumbers";

interface HealthCheckBigNumbers {
  local: "map" | "overview";
}

export function HealthCheckBigNumbers({ local }: HealthCheckBigNumbers) {
  const { bigNumbers, isLoading, isSM } = useHealthCheckBigNumbers();

  return (
    <DeviceListBigNumbersContainer
      direction={local === "map" ? "column" : "row"}
      gap={isSM ? 15 : 20}
      height="100%"
      justify="center"
    >
      {bigNumbers &&
        bigNumbers.map((bigNumber, index) => (
          <GLBigNumberCard bigNumber={bigNumber} isLoading={isLoading} key={index} />
        ))}
    </DeviceListBigNumbersContainer>
  );
}
