import {
  GLBarChart,
  GLBoxChart,
  useI18n,
} from "@group-link-one/grouplink-components";
import { LegendType } from "recharts";

import { getOptionsByApplication } from "../../../utils/getOptionsByApplication";
import { useDailyConsumption } from "./useDailyConsumption";

export function GraphDailyConsumption() {
  const { bars, data, legends, average, detailsDeviceState, isLoading } =
    useDailyConsumption();
  const { t } = useI18n();

  return (
    <GLBoxChart
      title={t("deviceList.detailsModal.ut.graphs.two.title")}
      description={t("deviceList.detailsModal.ut.graphs.two.description")}
      textSwitcherId="daily-consumption-history-switcher"
      tooltipText={t(
        "deviceList.detailsModal.ut.graphs.two.tooltipDescription"
      )}
      marginBottomTitle={20}
      isLoading={isLoading}
      chart={
        <GLBarChart
          data={data}
          bars={bars}
          average={average > 0 ? average : undefined}
          yAxisOptions={{
            tickFormatter: (value: string) =>
              `${Number(value).toFixed(2)} ${
                getOptionsByApplication({
                  type: detailsDeviceState.device?.application,
                }).unit
              }`,
          }}
          xAxisOptions={{
            padding: {
              left: 40,
              right: 70,
            },
          }}
          legendOptions={{
            payload: legends.map((legend) => ({
              id: legend.id,
              value: legend.value,
              color: legend.color,
              type: legend.type as LegendType,
            })),
          }}
          isLoading={isLoading}
          hasLegend
        />
      }
    />
  );
}
