// Contexto com hook useFireStore
import { useAuth } from "@group-link-one/gl-auth";
import { GLOnboardingModalList } from "@group-link-one/grouplink-components";
import CryptoJS from "crypto-js";
import { collection, doc,getDocs, setDoc,updateDoc   } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import React from "react";

import { db, firebaseApp } from "../Services/remoteConfig";

interface FireStoreProviderProps {
  children: React.ReactNode;
}

interface UserCustomMessageCollection {
  title: string
  description: string
  options: GLOnboardingModalList[]
}

interface UsersCollection {
  id: string;
  show_onboard: boolean
  show_welcome: boolean
  custom_message: {
    show: boolean
    options: string
    // options: {
    //   title: string
    //   description: string
    //   options: {
    //     title: string
    //     description: string
    //   }[]
    // }
  }
}

interface FireStoreConfig {
  customMessageOptions: UserCustomMessageCollection | undefined;
  hasToShowOnboard: boolean | undefined;
  hasToShowWelcome: boolean | undefined;
  hasToShowCustomMessage: boolean | undefined;
  resetAllUsersStoraged: () => void;
  getUserByEmail: () => UsersCollection | undefined;
  getDocument<T>(documentName: string): Promise<T[]>
  updateUserOnboard: (type: "onboard" | "welcome" | "custom-message") => Promise<void>;
}

const remoteConfig = getRemoteConfig(firebaseApp);
const FireStoreContext = createContext<FireStoreConfig>({} as FireStoreConfig);

// Only for development
remoteConfig.settings.minimumFetchIntervalMillis = 20000;

/**
 * FireStoreProvider component.
 *
 * @param children - ReactNode as children components.
 * @returns JSX.Element
 */
export const FireStoreProvider: React.FC<FireStoreProviderProps> = ({
  children,
}: FireStoreProviderProps): JSX.Element => {
  const [allUsersStoraged, setAllUsersStoraged] = useState<UsersCollection[] | undefined>(undefined);
  const { user, accessTokenIsValid } = useAuth();
  const userEmail = user?.email || "";

  const userEmailEncrypted = CryptoJS.SHA256(userEmail).toString();

  const hasToShowOnboard = useMemo(() => {
    return allUsersStoraged?.find(userStoraged => {
      return userStoraged.id === userEmailEncrypted;
    })?.show_onboard;
  }, [allUsersStoraged, userEmail]);

  const hasToShowWelcome = useMemo(() => {

    return allUsersStoraged?.find(userStoraged => {
      return userStoraged.id === userEmailEncrypted;
    })?.show_welcome;
  }, [allUsersStoraged, userEmail]);

  const hasToShowCustomMessage = useMemo(() => {
    return allUsersStoraged?.find(userStoraged => {
      return userStoraged.id === userEmailEncrypted;
    })?.custom_message.show;
  }, [allUsersStoraged, userEmail]);

  const customMessageOptions: UserCustomMessageCollection = useMemo(() => {
    const customMessageString = allUsersStoraged?.find(userStoraged => {
      return userStoraged.id === userEmailEncrypted;
    })?.custom_message.options;

    if (!customMessageString) return {}

    return JSON.parse(customMessageString);
  }, [allUsersStoraged, userEmail]);

  async function fetchAllUsers() {
    try {
      const usersCollectionRef = collection(db, "users");
      const querySnapshot = await getDocs(usersCollectionRef);
      const users = querySnapshot.docs.map(docStoraged => ({
        id: docStoraged.id,
        ...docStoraged.data()
      })) as UsersCollection[];

      setAllUsersStoraged(users);

    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }

  function getUserByEmail() {
    return allUsersStoraged?.find(userStoraged => {
      return userStoraged.id === userEmailEncrypted;
    });
  }

  function resetAllUsersStoraged() {
    setAllUsersStoraged(undefined);
  }

  async function updateUserOnboard(type: "onboard" | "welcome" | "custom-message") {
    try {
      const userRef = doc(db, "users", userEmailEncrypted);

      let updatedUser = {}

      if (type === "onboard") {
        updatedUser = {
          show_welcome: true,
        }
      }

      if (type === "custom-message") {
        updatedUser = {
          custom_message: {
            show: false,
            options: JSON.stringify({
              title: "",
              description: "",
              options: []
            })
          }
        }
      }

      if (type === "welcome") {
        updatedUser = {
          show_welcome: false,
        }
      }

      await updateDoc(userRef, updatedUser);

      const updatedUsers = allUsersStoraged?.map(userStoraged => {
        if (userStoraged.id === userEmailEncrypted) {
          return {
            ...userStoraged,
            ...updatedUser,
          };
        }
        return userStoraged;
      });

      setAllUsersStoraged(updatedUsers);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  }

  async function createUserOnboard() {
    try {
      const userRef = doc(db, "users", userEmailEncrypted);

      await setDoc(userRef, {
        show_onboard: false,
        show_welcome: true,
        custom_message: {
          show: false,
          options: JSON.stringify({
            title: "",
            description: "",
            options: []
          })
        }
      }, { merge: true });

      const newUser: UsersCollection = {
        id: userEmailEncrypted,
        show_onboard: false,
        show_welcome: true,
        custom_message: {
          show: false,
          options: JSON.stringify({
            title: "",
            description: "",
            options: []
          })
        }
      }

      setAllUsersStoraged([...allUsersStoraged || [], newUser]);
    } catch (error) {
      console.error("Error creating/updating user:", error);
    }
  }

  async function getDocument<T>(documentName: string): Promise<T[]> {
    const document = await getDocs(collection(db, documentName));
    return document.docs.map(docParam => docParam.data() as T);
  }

  useEffect(() => {
    if (!accessTokenIsValid) return;
    fetchAllUsers();
  }, [accessTokenIsValid])

  useEffect(() => {
    if (!allUsersStoraged || !accessTokenIsValid) return;
    const userStoraged = getUserByEmail();

    if (!userStoraged && user?.email) {
      createUserOnboard();
    }
  }, [user?.email, allUsersStoraged]);

  return (
    <FireStoreContext.Provider
      value={{
        customMessageOptions,
        hasToShowOnboard,
        hasToShowWelcome,
        hasToShowCustomMessage,
        getUserByEmail,
        getDocument,
        updateUserOnboard,
        resetAllUsersStoraged
      }}
    >
      {children}
    </FireStoreContext.Provider>
  );
};

export const useFireStore = (): FireStoreConfig => {
  const context = useContext(FireStoreContext);

  if (!context) {
    throw new Error("useFireStore must be used within an FireStoreProvider");
  }

  return context;
}

export default FireStoreProvider;
