import {
  useDeviceListStore as useGLDeviceListStore,
  useGLPagination,
  useInfiniteScroll,
  useToast,
} from "@group-link-one/grouplink-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {format} from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";

import { useExtractURLSearch } from "../../../hooks/useExtractURLSearch";
import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import {
  DeviceMeasurementCategory,
  GetDevicesActivatedLastReadingsParams,
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesPendingActivationResponse
} from "../../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "../store/details-device-store";

export const DEVICE_LIST_IPP = 50;
type CacheKeyMap = DeviceMeasurementCategory | "none";

export const useDeviceListAllDevices = () => {
  const [distanceFromHeader, setDistanceFromHeader] = useState(0);

  const { getDevicesActivatedLastReadings, getDevicesPendingActivation } =
    useDeviceListService();

  const { state: paginationState, actions: paginationActions } =
    useGLPagination();

  const { state: detailsDeviceState } = useDetailsDeviceStore();
  const { state: deviceListStateGL } = useGLDeviceListStore();

  const queryClient = useQueryClient();

  const { extractTokenFromSearch, setParams } = useExtractURLSearch();

  const queryDeviceID = extractTokenFromSearch("q");
  // const queryDetailsOpen = extractTokenFromSearch("details_open");

  const { addToast } = useToast();
  const { onInfiniteScroll } = useInfiniteScroll();

  const listContainerRef = useRef<HTMLDivElement>(null);

  const activeTab = useMemo(() => {
    return paginationActions.getActiveTabById(paginationState.activeTabId);
  }, [paginationState.activeTabId]);

  const searchDeviceID = useMemo(() => {

    if (
      paginationState.search.length === 0 &&
      queryDeviceID &&
      queryDeviceID.length > 0
    ) {
      return isNaN(Number(queryDeviceID))
        ? undefined
        : [Number(queryDeviceID)];
    }

    if (paginationState.search.length === 0) return undefined;

    return isNaN(Number(paginationState.search))
      ? undefined
      : [Number(paginationState.search)];

  }, [
    paginationState.search,
    queryDeviceID
  ]);

  const textSearch = useMemo(() => {

    if (
      paginationState.search.length === 0 &&
      queryDeviceID &&
      queryDeviceID.length > 0
    ) {
      return queryDeviceID;
    }

    if (paginationState.search.length === 0) return undefined;

    return paginationState.search;
  }, [
    paginationState.search,
    queryDeviceID
  ]);

  const useCasesSelected: DeviceMeasurementCategory | undefined = useMemo(() => {
    return deviceListStateGL?.useCaseOptionsSelecteds?.map((useCase) =>
      useCase.id as DeviceMeasurementCategory)[0] || undefined;

  }, [deviceListStateGL?.useCaseOptionsSelecteds]);

  const userGroupSelected: number[] | undefined = useMemo(() => {
    if (!deviceListStateGL?.userGroupSelecteds) return [];
    const userGroupsId = deviceListStateGL?.userGroupSelecteds?.map((userGroup) => Number(userGroup.id));
    return userGroupsId
  }, [deviceListStateGL?.userGroupSelecteds]);

  const from = useMemo(() => {
    if (!deviceListStateGL?.range?.from) return undefined;
    return format(deviceListStateGL?.range?.from || new Date(), "yyyy-MM-dd");
  }, [deviceListStateGL?.range?.from]);

  const to = useMemo(() => {
    if (!deviceListStateGL?.range?.to) return undefined;
    return format(deviceListStateGL?.range?.to || new Date(), "yyyy-MM-dd");
  }, [deviceListStateGL?.range?.to]);

  const cacheKeyByUseCase = useMemo(() => {
    const useCaseSelected =  deviceListStateGL?.useCaseOptionsSelecteds?.map((useCase) =>
      useCase.id as DeviceMeasurementCategory)[0] || undefined;

    const cacheKeyMap: Record<CacheKeyMap, string> = {
      energy: "energy-devices",
      gas: "gas-devices",
      pressure: "pressure-devices",
      water: "water-devices",
      none: "all-use-cases",
    }

    return useCaseSelected && useCaseSelected in cacheKeyMap ? cacheKeyMap[useCaseSelected] : "all-devices";

  }, [deviceListStateGL?.useCaseOptionsSelecteds]);

  const cacheKeyParam = useMemo(() => {
    let cacheKeyFormatted = "";
    const userGroupSelectedText = userGroupSelected && userGroupSelected.length > 0 ? userGroupSelected.join("-") : "all-groups";

    if (cacheKeyByUseCase) cacheKeyFormatted += cacheKeyByUseCase + "-";
    if (userGroupSelectedText) cacheKeyFormatted += userGroupSelectedText + "-";
    if (textSearch) cacheKeyFormatted += textSearch + "-";
    if (from) cacheKeyFormatted += from + "-";
    if (to) cacheKeyFormatted += to;

    return cacheKeyFormatted;
  }, [cacheKeyByUseCase, userGroupSelected, textSearch, from, to]);

  const {
    data: deviceList,
    isLoading: deviceListIsLoading,
    isError: deviceListIsErrored,
  } = useQuery({
    queryKey: [
      "devices-activated-last-readings",
      textSearch,
      useCasesSelected,
      userGroupSelected,
      from,
      to
    ],
    enabled: activeTab?.id === 1 && !detailsDeviceState.modalIsOpen,
    queryFn: async () => {
      const { optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();

      try {
        const response = await getDevicesActivatedLastReadings({
          ipp: DEVICE_LIST_IPP,
          next_page_token: undefined,
          object_readings: true,
          all_devices: true,
          device_measurement_category: useCasesSelected,
          text: textSearch,
          group_ids: userGroupSelected,
          activated_at_since: from,
          activated_at_until: to,
        });

        paginationActions.setNextPageToken({
          hasMore: response.has_more,
          nextPageToken: response.next_page_token || undefined,
          optionsToStoreNextPageToken,
          cacheKeyParam: cacheKeyParam
        });

        // if (queryDetailsOpen === "true" && response.rows.length === 1 && queryDeviceID) {
        //   detailsDeviceActions.setDevice(response.rows[0]);
        //   detailsDeviceActions.setModalIsOpen(true);
        // }

        return response.rows;
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          addToast({
            type: "error",
            title: "Device ID not found",
            message: "Please enter a valid Device ID",
          })
        }
      }

    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: pendingDeviceList,
    isLoading: pendingDeviceListIsLoading,
    isError: pendingDeviceListIsErrored,
  } = useQuery({
    queryKey: [
      "devices-pendings-last-readings",
      searchDeviceID,
    ],
    enabled: activeTab?.id === 2,
    queryFn: async () => {
      const { optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();

      try {
        const response = await getDevicesPendingActivation({
          ipp: DEVICE_LIST_IPP,
          next_page_token: undefined,
          device_id: searchDeviceID,
        });

        paginationActions.setNextPageToken({
          hasMore: response.has_more,
          nextPageToken: response.next_page_token || undefined,
          optionsToStoreNextPageToken,
        });

        return response.rows;
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          addToast({
            type: "error",
            title: "Device not found",
            message: "Please review your search",
          })
        }
      }

    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  async function getMoreDevicesOnScroll() {
    const activeTabIdMap = {
      "tab-1": {
        do: async (options: GetDevicesActivatedLastReadingsParams) => {
          return await getDevicesActivatedLastReadings(options);
        },
        cachekey: "devices-activated-last-readings",
        queryKey: ["devices-activated-last-readings", textSearch, useCasesSelected, userGroupSelected, from, to],
      },
      "tab-2": {
        do: async (options: GetDevicesActivatedLastReadingsParams) => {
          return await getDevicesPendingActivation(options);
        },
        cachekey: "devices-pendings-last-readings",
        queryKey: ["devices-pendings-last-readings", searchDeviceID],
      },
    };

    if (paginationState.isFetchingInfiniteScroll) return;

    const { nextPageToken, optionsToStoreNextPageToken } =
      paginationActions.getNextPageToken(activeTab?.id === 1 ? cacheKeyParam : undefined);

    if (!nextPageToken) return;

    const options: GetDevicesActivatedLastReadingsParams = {
      ipp: DEVICE_LIST_IPP,
      next_page_token: nextPageToken,
      device_id: searchDeviceID,
      object_readings: true,
      all_devices: true,
      device_measurement_category: useCasesSelected,
    };

    paginationActions.setIsFetchingInfiniteScroll(true);

    const tabMapActive =
      activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap];

    const response = await tabMapActive.do(options);

    paginationActions.setIsFetchingInfiniteScroll(false);

    paginationActions.setNextPageToken({
      hasMore: response.has_more,
      nextPageToken: response.next_page_token || undefined,
      optionsToStoreNextPageToken,
      cacheKeyParam: activeTab?.id === 1 ? cacheKeyParam : undefined
    });

    const currentDeviceList:
      | GetDevicesActivatedLastReadingsResponse[]
      | GetDevicesPendingActivationResponse[]
      | undefined = queryClient.getQueryData(tabMapActive.queryKey);

    if (!currentDeviceList) return;

    queryClient.setQueryData(
      tabMapActive.queryKey,
      [...currentDeviceList, ...response.rows]
    );
  }

  function invalidateAllQueries() {
    queryClient.invalidateQueries({
      queryKey: ["devices-activated-last-readings"],
    });
  }

  const currentDeviceList = useMemo(() => {

    const activeTabIdMap = {
      "tab-1": deviceList,
      "tab-2": pendingDeviceList
    }

    return activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap] || [];
  }, [activeTab?.id, deviceList, pendingDeviceList]);

  const currentDeviceListIsLoading = useMemo(() => {

    const activeTabIdMap = {
      "tab-1": deviceListIsLoading,
      "tab-2": pendingDeviceListIsLoading
    }

    return activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap];
  }, [activeTab?.id, deviceListIsLoading, pendingDeviceListIsLoading]);

  const currentDeviceListIsErrored = useMemo(() => {

    const activeTabIdMap = {
      "tab-1": deviceListIsErrored,
      "tab-2": pendingDeviceListIsErrored
    }

    return activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap]
  }, [activeTab?.id, deviceListIsErrored, pendingDeviceListIsErrored]);

  const isToShowDeviceList = !currentDeviceListIsLoading &&
    !currentDeviceListIsLoading &&
    currentDeviceList &&
    currentDeviceList.length > 0;

  const isToShowEmptyState = currentDeviceList && currentDeviceList.length === 0;
  const isToShowError = !currentDeviceListIsLoading && currentDeviceListIsErrored;

  useEffect(() => {
    paginationActions.tabsActions.setOnClick(paginationState.tabs[0], () => {
      paginationActions.setActiveTab(1);
    });

    if (paginationState.tabs[1]) {
      paginationActions.tabsActions.setOnClick(paginationState.tabs[1], () => {
        paginationActions.setActiveTab(2);
      });
    }

    invalidateAllQueries();

  }, []);

  useEffect(() => {
    paginationActions.setIsLoading(deviceListIsLoading);
  }, [deviceListIsLoading]);

  useEffect(() => {
    setTimeout(() => {
      const header = document.querySelector("header")?.getBoundingClientRect();

      if (listContainerRef.current) {
        setDistanceFromHeader(listContainerRef.current.offsetTop - (header?.height || 0));
      }

    }, 200)
  }, [])

  useEffect(() => {
    if (queryDeviceID) {
      paginationActions.setSearch(queryDeviceID);
    }
  }, [])

  useEffect(() => {
    setParams({
      "q": paginationState.search
    });
  }, [paginationState.search])

  return {
    activeTab,
    currentDeviceList,
    deviceList,
    deviceListIsErrored,
    distanceFromHeader,
    deviceListIsLoading,
    currentDeviceListIsLoading,
    paginationState,
    paginationActions,
    listContainerRef,
    tabs: paginationState.tabs,
    devicesCount: 0,
    isToShowDeviceList,
    isToShowEmptyState,
    isToShowError,
    getMoreDevicesOnScroll,
    onInfiniteScroll
  };
};
