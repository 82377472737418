import { useAuth } from "@group-link-one/gl-auth";
import { MeasurementCategory } from "@group-link-one/gl-auth/dist/auth/types/user";
import { GLSelectMultipleOptions, IOrganizationReqParams, useI18n } from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useOrganizationsService } from "../../../../Services/organizationsService/useOrganizations";
import { useCasesMap } from "../../../DeviceList/useDeviceListBody";
import { useImpersonateContentStore } from "../../store/impersonateContent.store";
import { useImpersonateStore } from "../../store/impersonateModal.store";

export const useOrganizationContent = () => {
  const { getOrganizations } = useOrganizationsService();
  const { state } = useImpersonateStore();

  const { t } = useI18n();
  const { user } = useAuth();

  const isMobile = useMediaQuery("(max-width: 1024px)");

  const { state: impersonateContentState, actions: impersonateContentActions } =
    useImpersonateContentStore();

  const useCaseSelected = useMemo(() => {
    if (!impersonateContentState.useCaseOptionsSelecteds ||
      !(impersonateContentState.useCaseOptionsSelecteds.length > 0)
    ) return "util_water";

    const useCasesToApiMap: Record<MeasurementCategory, IOrganizationReqParams["use_case"]> = {
      energy: "util_kwh",
      gas: "util_water",
      water: "util_water",
    }

    const useCaseActive = impersonateContentState.useCaseOptionsSelecteds[0]

    if (!useCaseActive) return "util_water";

    return useCasesToApiMap[useCaseActive.id as MeasurementCategory]
  }, [impersonateContentState.useCaseOptionsSelecteds]);

  const { data, isLoading } = useQuery({
    queryKey: [
      "get-organizations",
      state.modalSteperContentIsOpen,
      impersonateContentState.textToSearchOrg,
      useCaseSelected
    ],
    queryFn: async () => {
      if (!state.modalSteperContentIsOpen) return;

      const response = await getOrganizations({
        use_case: useCaseSelected,
        org_name: impersonateContentState.textToSearchOrg,
      });

      return response.data.rows;
    },
  });

  const onSelectOrganization = async (orgId: string | number) => {
    if (orgId === impersonateContentState.organizationId) {
      impersonateContentActions.setOrganizationId(0);
      return;
    }
    impersonateContentActions.setOrganizationId(orgId);
  };

  function onSelectUseCase(useCaseOptionsSelecteds: GLSelectMultipleOptions[]) {
    impersonateContentActions.setUseCaseOptionsSelecteds(useCaseOptionsSelecteds);
  }

  useEffect(() => {
    // const useCaseOptionsAvailables = useCasesMap.filter((useCase) =>
    //   user?.device_measurement_categories?.includes(
    //     useCase.id as MeasurementCategory
    //   )
    // );

    // // impersonateContentActions.setUseCaseOptions(useCaseOptionsAvailables);
    impersonateContentActions.setUseCaseOptions(useCasesMap);

  }, [user]);

  return {
    data,
    isLoading,
    onSelectUseCase,
    onSelectOrganization,
    impersonateContentState,
    impersonateContentActions,
    isMobile,
    t,
  };
};
