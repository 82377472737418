import { useAuth } from "@group-link-one/gl-auth";
import { IGroupsDevice, IGroupsUser, useGLPagination, useGroupsDevicesStore, useI18n, useToast } from "@group-link-one/grouplink-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect } from "react";

import { useFlags } from "../../Context/FlagsProvider";
// import { useEffect } from "react";
import { useGroupListService } from "../../Services/groupListService/useGroupListService";
import { GetOrgDeviceGroupIdResponse, IEditGroups } from "../../Services/groupListService/useGroupListService.types";
import { useGroupService } from "../../Services/groupService/useGroupService";
import { useDeviceGroupsUtils } from "./useDeviceGroupsUtils";
// import { useDeviceGroupsUtils } from "./useDeviceGroupsUtils";

const transformOptions = (options: IGroupsUser[]): { id: string, text: string }[] => {
  return options.map((option) => ({
    id: String(option.id),
    text: option.name,
  }));
};

const useGroupMutations = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { t } = useI18n();

  const {
    actions: groupsDevicesActions,
  } = useGroupsDevicesStore();

  const { deleteOrgDeviceGroupId, patchOrgDeviceGroupId } = useGroupListService();

  const createMutation = useMutation({
    mutationFn: useGroupListService().postOrgDeviceGroup,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["orgdevicegroup"] }),
  });

  const deleteMutation = useMutation<void, any, number>({
    mutationFn: async (groupId: number) => {
      try {
        await deleteOrgDeviceGroupId(groupId);

        addToast({
          title: t("groupsDevices.toast.successDelete.title"),
          message: t("groupsDevices.toast.successDelete.message"),
          type: "success",
        })
      } catch (error: unknown) {

        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data?.error;

          if (errorMessage === "HAS_CHILD_GROUPS") {
            addToast({
              title: t("toast.error.title"),
              message: t("toast.error.cannotDeleteWithChildren"),
              type: "error",
            });
          } else {
            addToast({
              title: t("toast.error.title"),
              message: t("toast.error.message"),
              type: "error",
            });
          }

        }
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["orgdevicegroup"] });
    },
  });

  const updateMutation = useMutation<void, Error, { id: number, data: Partial<IEditGroups> }>({
    mutationFn: async({id, data}) => {
      await patchOrgDeviceGroupId({ id, data });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["orgdevicegroup"] })

      addToast({
        title: t("groupsDevices.toast.successUpdate.title"),
        message: t("groupsDevices.toast.successUpdate.message"),
        type: "success",
      })

      setTimeout(() => {
        groupsDevicesActions.setOpenModal(false);
      }, 500);
    },
  });

  const addDevicesToGroup = async ({ id, data }: {
    id: number;
    data: Partial<IEditGroups>;
  }) => {
    try {
      await patchOrgDeviceGroupId({
        id, data
      });

      addToast({
        title: t("groupsDevices.toast.successAddDevices.title"),
        message: t("groupsDevices.toast.successAddDevices.message"),
        type: "success",
      })

      queryClient.invalidateQueries({ queryKey: ["orgdevicegroup"] });

      setTimeout(() => {
        groupsDevicesActions.setOpenAddDevicesModal(false);
      }, 500);

    } catch (err: unknown) {
      addToast({
        title: t("groupsDevices.toast.errorAddDevices.title"),
        message:  t("groupsDevices.toast.errorAddDevices.message"),
        type: "error",
      })
    }
  }

  return { createMutation, deleteMutation, updateMutation, addDevicesToGroup };
};

export const useGroupListBody = () => {
  const { getOrgDeviceGroup } = useGroupListService();
  const { getOrganizationGroups } = useGroupService();
  const { createMutation, deleteMutation, updateMutation, addDevicesToGroup } = useGroupMutations();

  const { user } = useAuth();

  const { hasPrivileges } = useFlags();

  const {
    state: groupsDevicesState,
    actions: groupsDevicesActions,
  } = useGroupsDevicesStore();

  const {
    actions: paginationActions,
  } = useGLPagination();

  const { getDeviceGroup } = useDeviceGroupsUtils()

  const { data: groupList, isLoading: groupListIsLoading } = useQuery({
    queryKey: ["orgdevicegroup"],
    queryFn: async () => {
      const devicesGroup = await getOrgDeviceGroup();

      return formatGroupList(devicesGroup?.rows);
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const { data: { rows: optionsList = [] } = {} } = useQuery({
    queryKey: ["get-groups-users"],
    queryFn: () => getOrganizationGroups(),
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 5,
  });

  const OPTIONS = transformOptions(optionsList);

  function formatGroupList(groups: IGroupsDevice[] | undefined): IGroupsDevice[] | undefined {

    if (!groups) return;

    const AllGroupsLevelZero = groups?.filter((group) => group.parent_id === null);
    const groupsFormattedWithChildren = [];

    for (const group of AllGroupsLevelZero) {
      groupsFormattedWithChildren.push({
        ...group,
        children: getChildGroups(group, groups),
      })
    }

    return groupsFormattedWithChildren
  }

  function getChildGroups(
    group: IGroupsDevice, groups: IGroupsDevice[]
  ): IGroupsDevice[] | undefined {
    const children = groups.filter((g) => g.parent_id === group.id);

    if (children.length === 0) return [];

    return children.map((child) => ({
      ...child,
      children: getChildGroups(child, groups),
    }));

  }

  async function getDataFromGroupList(id: number) {
    const dataFromDeviceGroup = await getDeviceGroup(id);

    const coordinatorsUnique = dataFromDeviceGroup.coordinators.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);

      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [] as GetOrgDeviceGroupIdResponse["coordinators"]);

    const coordinators = coordinatorsUnique.map((coordinator) => ({
      id: String(coordinator.id),
      text: coordinator.name,
    }));

    const viewersUnique = dataFromDeviceGroup.viewers.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);

      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [] as GetOrgDeviceGroupIdResponse["viewers"]);

    const viewers = viewersUnique.map((viewer) => ({
      id: String(viewer.id),
      text: viewer.name,
    }));

    groupsDevicesActions.setGroupsDeviceCardInfo({
      ...groupsDevicesState.groupsDevicesCardInfo,
      coordinators,
      viewers
    });

  }

  useEffect(() => {
    paginationActions.setIsLoading(groupListIsLoading);
  }, [groupListIsLoading])

  useEffect(() => {
    if (groupsDevicesState.openModal && groupsDevicesState.groupsDevicesCardInfo.id) {
      getDataFromGroupList(Number(groupsDevicesState.groupsDevicesCardInfo.id));
    }
  }, [groupsDevicesState.openModal])


  return {
    createMutation,
    deleteMutation,
    updateMutation,
    addDevicesToGroup,
    groupList,
    groupsDevicesState,
    optionsList,
    hasPrivileges,
    user,
    OPTIONS

  }
}
