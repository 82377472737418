import { IRequestParams } from "../../Pages/Audit/types/types";
import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useAuditService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getAudits = async ({ ...params }: IRequestParams) => {
    const response = await httpPrivate.get("/customer-audit", {
      params,
    });

    return response.data;
  };

  return {
    getAudits,
  };
};
