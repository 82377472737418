import { BigNumberProps, Colors, GL_CHECK, GL_COLORS, GL_GLStation, GL_LOGIN, useI18n } from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import {format} from "date-fns"
import { useEffect, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";

import CheckImg from "../../../../images/DeviceList/icon-check.svg";
// import InternetImg from "../../../../images/DeviceList/icon-internet.svg";
import LoginImg from "../../../../images/DeviceList/icon-log-in.svg";
import StationImg from "../../../../images/DeviceList/icon-station.svg";
import { useDeviceListService } from "../../../../Services/deviceListService/useDeviceListService";
import { GetDeviceHealthCheckCountResponse } from "../../../../Services/deviceListService/useDeviceListService.types";
import { useHealthCheckStore } from "../../../HealthCheckMap/store/health-check-store";

export const useHealthCheckBigNumbers = () => {

  const isSM = useMediaQuery("(max-width: 660px)");

  const { getDevicesHealthCheckCount } = useDeviceListService()
  const {
    state: healthCheckState,
    actions: healthCheckActions,
  } = useHealthCheckStore();

  const { t } = useI18n()

  const from = useMemo(() =>
    format(healthCheckState.range.from || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    [healthCheckState.range.from]
  );

  const until = useMemo(() =>
    format(healthCheckState.range.to || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    [healthCheckState.range.to]
  );

  const { data: bigNumberCount, isLoading } = useQuery({
    queryKey: ["devicesHealthCheckCount", from, until],
    queryFn: async () => {
      const response = await getDevicesHealthCheckCount({
        from,
        until,
      });

      return response;
    },
  })

  const bigNumbers: BigNumberProps[] = useMemo(() => {
    return [
      {
        iconLeft: (
          <GL_GLStation
            fillOne={GL_COLORS.ULTRADARK_GREY_SECONDARY}
            fill={GL_COLORS.ULTRADARK_GREY_SECONDARY}
            size={24}
          />
        ),
        iconRight: <img src={StationImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? "scale(0.85)" : "none",
        },
        title: t("healthCheck.bigNumbers.totalDevices.title"),
        count: calculeBigNumber(healthCheckState.devicesHealthCheckCount, "total"),
        type: isSM ? "sm" : "lg",
        backgroundColor: Colors.LIGHT_GREEN,
        textColor: "ULTRADARK_GREY_SECONDARY",
        tooltipText: t("healthCheck.bigNumbers.totalDevices.tooltipDescription"),
        tooltipPosition: "top-right",
        tooltipZIndex: 999,
        tooltipTextStyle: {
          whiteSpace: "nowrap",
        },
      },
      {
        iconLeft: <GL_LOGIN fill={GL_COLORS.WHITE} size={24} />,
        iconRight: <img src={LoginImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? "scale(0.85)" : "none",
        },
        title: t("healthCheck.bigNumbers.withReadings.title"),
        count: calculeBigNumber(healthCheckState.devicesHealthCheckCount, "devices_with_readings"),
        type: isSM ? "sm" : "lg",
        backgroundColor: Colors.NAVY,
        textColor: "WHITE",
        tooltipText: t("healthCheck.bigNumbers.withReadings.tooltipDescription"),
        helpIconColor: "WHITE",
        tooltipPosition: "top-right",
        tooltipZIndex: 999,
        tooltipTextStyle: {
          whiteSpace: "nowrap",
        }
      },
      {
        iconLeft: <GL_CHECK fill={GL_COLORS.FONT_COLOR_VARIANT} size={24} />,
        iconRight: <img src={CheckImg} alt="" />,
        iconRightStyle: {
          bottom: 0,
          right: isSM ? -40 : 20,
          transform: isSM ? "scale(0.85)" : "none",
        },
        title: t("healthCheck.bigNumbers.noReadings.title"),
        count: calculeBigNumber(healthCheckState.devicesHealthCheckCount, "devices_without_readings"),
        type: isSM ? "sm" : "lg",
        backgroundColor: GL_COLORS.ACCENT_COLOR,
        textColor: "FONT_COLOR_VARIANT",
        tooltipText: t("healthCheck.bigNumbers.noReadings.tooltipDescription"),
        helpIconColor: "WHITE",
        tooltipPosition: "top-right",
        tooltipZIndex: 999,
        tooltipTextStyle: {
          whiteSpace: "nowrap",
        }
      },
      // {
      //   iconLeft: <GL_INTERNET fill={GL_COLORS.WHITE} size={24} />,
      //   iconRight: <img src={InternetImg} alt="" />,
      //   iconRightStyle: {
      //     bottom: 0,
      //     right: isSM ? -40 : 20,
      //     transform: isSM ? "scale(0.85)" : "none",
      //   },
      //   title: t("healthCheck.bigNumbers.communicationRate.title"),
      //   count: calculeBigNumber(healthCheckState.devicesHealthCheckCount, "communication_rate"),
      //   type: isSM ? "sm" : "lg",
      //   backgroundColor: Colors.PURPLE,
      //   textColor: "WHITE",
      //   tooltipText: t("healthCheck.bigNumbers.communicationRate.tooltipDescription"),
      //   helpIconColor: "WHITE",
      //   tooltipZIndex: 999,
      //   tooltipPosition: "top-right",
      //   tooltipTextStyle: {
      //     whiteSpace: "nowrap",
      //   }
      // },
    ];
  }, [healthCheckState.devicesHealthCheckCount])

  function calculeBigNumber(
    devicesHealthCheckCountParam: GetDeviceHealthCheckCountResponse | null,
    type: "devices_with_readings" | "devices_without_readings" | "total" | "communication_rate"
  ) {

    if (!devicesHealthCheckCountParam) return 0;

    switch (type) {
      case "devices_with_readings":
        return devicesHealthCheckCountParam.devices_with_readings;
      case "devices_without_readings":
        return devicesHealthCheckCountParam.devices_without_readings;
      case "total":
        return devicesHealthCheckCountParam.devices_with_readings + devicesHealthCheckCountParam.devices_without_readings;
      case "communication_rate":
        return calculeCommunicationRate(devicesHealthCheckCountParam);
    }

  }

  function calculeCommunicationRate(
    devicesHealthCheckCountParam: GetDeviceHealthCheckCountResponse | null
  ): string | number{
    if (!devicesHealthCheckCountParam) return 0;

    const total = calculeBigNumber(devicesHealthCheckCountParam, "total");
    const devicesWithReadings = calculeBigNumber(devicesHealthCheckCountParam, "devices_with_readings");

    if (total === 0) return 0;

    return ((Number(devicesWithReadings) / Number(total)) * 100).toFixed(2) + "%";
  }

  useEffect(() => {
    healthCheckActions.setDevicesHealthCheckCount(bigNumberCount || null);
  }, [bigNumberCount])

  return {
    bigNumbers,
    isLoading,
    isSM,
  };
};
