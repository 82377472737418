import {
  useGLPagination,
  useGroupsUsersStore,
  useI18n,
  useToast,
} from "@group-link-one/grouplink-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { useFlags } from "../../../Context/FlagsProvider";
import { useOrganizationGroupsService } from "../../../Services/organizationGroupsService/useOrganizationGroups";
import { ICreateGroups, IEditGroups } from "./types/types";

export const useOrganizationGroups = () => {
  const {
    getOrganizationGroups,
    createOrganizationGroup,
    updateOrganizationGroup,
    deleteOrganizationGroup,
  } = useOrganizationGroupsService();

  const { addToast } = useToast();
  const { t } = useI18n();
  const { actions: groupsUsersStoreActions } = useGroupsUsersStore();
  const { hasPrivileges } = useFlags();
  const queryClient = useQueryClient();

  const { state: paginationStoreState, actions: paginationStoreActions } =
    useGLPagination();

  const listGroups = async () => {
    await getOrganizationGroups({})
      .then(() => {})
      .catch((err) => {
        console.error(err);
        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });

        return;
      });
  };

  const updateGroup = async (
    id: number,
    { name, description, customer_ids, is_default }: Partial<IEditGroups>
  ) => {
    groupsUsersStoreActions.setIsLoading(true);
    if (!id) {
      return;
    }
    updateOrganizationGroup(id, {
      name,
      description,
      customer_ids,
      is_default,
    })
      .then(() => {
        addToast({
          title: t("groupsUsers.toast.successUpdate.title"),
          message: t("groupsUsers.toast.successUpdate.message"),
          type: "success",
        });
        refetchGroups();
        groupsUsersStoreActions.setOpenMakeDefaultModal(false);
        groupsUsersStoreActions.setOpenModal(false);
      })
      .catch(() => {
        addToast({
          title: t("groupsUsers.toast.errorUpdate.title"),
          message: t("groupsUsers.toast.errorUpdate.message"),
          type: "error",
        });
        return;
      })
      .finally(() => {
        groupsUsersStoreActions.setIsLoading(false);
      });
  };

  const deleteGroup = async (id: number) => {
    deleteOrganizationGroup(id)
      .then(() => {
        addToast({
          title: t("groupsUsers.toast.successDelete.title"),
          message: t("groupsUsers.toast.successDelete.message"),
          type: "success",
        });
        refetchGroups();
        groupsUsersStoreActions.setOpenDeleteModal(false);
        groupsUsersStoreActions.setOpenModal(false);
      })
      .catch((err) => {
        console.error(err);
        addToast({
          title: t("groupsUsers.toast.errorDelete.title"),
          message: t("groupsUsers.toast.errorDelete.message"),
          type: "error",
        });
      })
      .finally(() => {
        groupsUsersStoreActions.setIsLoading(false);
      });
  };

  const createGroup = async ({
    name,
    description,
    customer_ids,
    is_default,
  }: ICreateGroups) => {
    groupsUsersStoreActions.setIsLoading(true);

    createOrganizationGroup({
      name,
      description,
      customer_ids,
      is_default,
    })
      .then((res) => {
        groupsUsersStoreActions.setOpenModal(false);

        if (res.status === 201) {
          addToast({
            title: t("groupsUsers.toast.success.title"),
            message: t("groupsUsers.toast.success.message"),
            type: "success",
          });
          refetchGroups();
        }
      })
      .catch((err) => {
        console.error(err);
        if(err?.response?.status === 400) {
          addToast({
            title: t("groupsUsers.toast.error.title"),
            message: t("groupsUsers.toast.error.messageOne"),
            type: "error",
          });
          return
        }
        addToast({
          title: t("groupsUsers.toast.error.title"),
          message: t("groupsUsers.toast.error.message"),
          type: "error",
        });
      })
      .finally(() => {
        groupsUsersStoreActions.setIsLoading(false);
      });
  };

  const refetchGroups = () => {
    queryClient.refetchQueries({
      queryKey: ["get-groups-users"],
    });
  };

  const { data: groups } = useQuery({
    queryKey: [
      "get-groups-users",
      paginationStoreState.search,
      paginationStoreState.currentPage,
    ],
    queryFn: async () => {
      const { optionsToStoreNextPageToken } =
        paginationStoreActions.getNextPageToken();

      const getGroupsData = await getOrganizationGroups({
        name: paginationStoreState.search,
      });

      paginationStoreActions.setNextPageToken({
        hasMore: getGroupsData.has_more,
        nextPageToken: getGroupsData.next_page_token || undefined,
        optionsToStoreNextPageToken,
      });

      return getGroupsData.rows;
    },
    staleTime: 1000 * 60 * 10,
  });

  return {
    groups,
    listGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    hasPrivileges
  };
};
