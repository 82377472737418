import { AlertType, useI18n } from "@group-link-one/grouplink-components";
import { useJsApiLoader } from "@react-google-maps/api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";

import { env } from "../../../env";
import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import { DeviceMapResponse } from "../../../Services/deviceListService/useDeviceListService.types";
import { useThemeActiveStore } from "../../../store/theme";
import { mapDarkStyles, mapStyles } from "../../../utils/mapStyles";
import { HealthCheckStoreState, useHealthCheckStore } from "../store/health-check-store";

const center = {
  lat: -23.5932056,
  lng: -46.6780125
};

export const useHealthCheckMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const { state: themeActiveState, actions: themeActiveActions } = useThemeActiveStore();

  const [mapStyle, setMapStyle] = useState(
    themeActiveState.isDarkMode ? mapDarkStyles : mapStyles
  );
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markerIcon, setMarkerIcon] = useState<google.maps.Icon | undefined>(undefined);
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);

  const { getDeviceMap } = useDeviceListService();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    state: healthCheckState,
    actions: healthCheckActions,
  } = useHealthCheckStore()

  const onLoad = useCallback(function callback(mapInstance: google.maps.Map) {
    setMap(mapInstance);

    const svgIcon = {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="18" fill="#00FFAA" stroke="white" stroke-width="2"/>
        </svg>
      `),
      scaledSize: new window.google.maps.Size(22, 22),
    };

    setMarkerIcon(svgIcon);

  }, [center]);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const from = useMemo(() =>
    format(healthCheckState.range.from || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    [healthCheckState.range.from]
  );

  const until = useMemo(() =>
    format(healthCheckState.range.to || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    [healthCheckState.range.to]
  );

  const alertsOnly = useMemo(() => {
    if (healthCheckState.alertFilterValue?.length === 0) {
      return undefined
    }

    if (healthCheckState.alertFilterValue?.length === 1) {

      if (healthCheckState.alertFilterValue[0].id === "with-alert") return true
      if (healthCheckState.alertFilterValue[0].id === "without-alert") return false

    }

    return undefined
  }, [healthCheckState.alertFilterValue])

  const alerts = useMemo(() => {
    if (healthCheckState.alertTypesSelected?.length === 0) {
      return undefined
    }

    return healthCheckState.alertTypesSelected?.map((alert) => alert.id)
  }, [healthCheckState.alertTypesSelected])

  const {
    data: currentDevices,
    isLoading: currentDevicesIsLoading,
  } = useQuery<DeviceMapResponse[]>({
    queryKey: ["activateds-devices", from, until, alertsOnly, alerts, healthCheckState.geo_filter],
    queryFn: async () => {

      if (!healthCheckState.geo_filter) return [];

      const devicesCacheds = isGeoFilterCached(healthCheckState.geo_filter);

      if (devicesCacheds) return devicesCacheds;

      const response = await getDeviceMap({
        from,
        until,
        alerts_only: alertsOnly,
        alerts: alerts as AlertType[],
        geo_filter: healthCheckState.geo_filter
      });

      return response
    },
  })

  const currentDevicesWithoutDuplicates = useMemo(() => {
    const allDevicesCached = queryClient.getQueriesData<DeviceMapResponse[]>({
      queryKey: ["activateds-devices", from, until, alertsOnly, alerts]
    })

    if (!allDevicesCached) return currentDevices;

    const deviceMap = new Map<number, DeviceMapResponse>();

    allDevicesCached.forEach(([, devicesCached]) => {
      devicesCached?.forEach((device) => {
        deviceMap.set(device.device_id, device);
      });
    });

    return Array.from(deviceMap.values());

  }, [currentDevices]);

  const onBoundsChanged = useDebounceCallback(async () => {
    if (!map) return;

    const zoomMap = map.getZoom();

    if (zoomMap && zoomMap < 10) return;

    const boundsVisible = map.getBounds();

    if (boundsVisible) {
      const northEast = boundsVisible.getNorthEast();
      const southWest = boundsVisible.getSouthWest();

      healthCheckActions.setGeoFilter({
        min_lat: southWest.lat(),
        max_lat: northEast.lat(),
        min_long: southWest.lng(),
        max_long: northEast.lng(),
      });
    }
  }, 500);

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  function isGeoFilterCached(geo_filter: HealthCheckStoreState["geo_filter"]) {
    const allDevicesCached = queryClient.getQueriesData<DeviceMapResponse[]>({
      queryKey: ["activateds-devices", from, until, alertsOnly, alerts]
    })

    if (!allDevicesCached || !geo_filter) return false;

    const devicesFiltered = allDevicesCached.find(([queryKey]) => {
      const geo_filter_cached = queryKey[5] as HealthCheckStoreState["geo_filter"];

      if (!geo_filter_cached) return false;

      const isInside =
        geo_filter_cached.min_lat <= geo_filter.min_lat &&
        geo_filter_cached.max_lat >= geo_filter.max_lat &&
        geo_filter_cached.min_long <= geo_filter.min_long &&
        geo_filter_cached.max_long >= geo_filter.max_long;

      return isInside
    });

    if (devicesFiltered) return devicesFiltered[1]

    return false;
  }

  useEffect(() => {
    if (map) {
      const zoomTimer = setTimeout(() => {
        map.setZoom(10);
      }, 400);
      return () => clearTimeout(zoomTimer);
    }
  }, [map]);

  useEffect(() => {
    setMapStyle(themeActiveState.isDarkMode ? mapDarkStyles : mapStyles);
  }, [themeActiveState.isDarkMode]);

  useEffect(() => {
    themeActiveActions.listenDarkMode();
  }, [themeActiveActions]);

  useEffect(() => {
    if (healthCheckState.deviceSelected && map && healthCheckState.deviceModalIsOpen) {
      const latLng = new window.google.maps.LatLng(
        healthCheckState.deviceSelected.meta.latitude,
        healthCheckState.deviceSelected.meta.longitude
      );
      map.panTo(latLng);

      const currentZoom = map.getZoom();

      const zoomTimer = setTimeout(() => {

        if (currentZoom && currentZoom >= 18) return;

        map.setZoom(18);
      }, 600);
      return () => clearTimeout(zoomTimer);
    }

    // if (healthCheckState.deviceSelected && map && !healthCheckState.deviceModalIsOpen) {
    //   map.setZoom(16);
    // }
  }, [
    healthCheckState.deviceSelected,
    healthCheckState.deviceModalIsOpen,
    map
  ]);

  useEffect(() => {
    if (map && userLocation) {
      const latLng = new google.maps.LatLng(userLocation.lat, userLocation.lng);
      map.panTo(latLng);
    }
  }, [map, userLocation]);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  useEffect(() => {
    healthCheckActions.setIsFetchingDevices(currentDevicesIsLoading);
  }, [currentDevicesIsLoading])

  return {
    t,
    center,
    map,
    deviceSelected: healthCheckState.deviceSelected,
    currentDevices: currentDevicesWithoutDuplicates,
    markerIcon,
    mapStyle,
    healthCheckState,
    isLoaded,
    onLoad,
    onBoundsChanged,
    onUnmount,
  };
};
