import { GLBox, GLButton,GLModal } from "@group-link-one/grouplink-components";

import { useModalDecomission } from "./useModalDecomission";

export function ModalDecomission() {
  const {
    t,
    deviceListState,
    deviceListActions,
    onCancel,
    onDecomission
   } = useModalDecomission();


  return (
    <GLModal
      open={deviceListState.modalDecomissionIsOpen}
      type="remove"
      title={t("deviceList.decomissionModal.title")}
      description={t("deviceList.decomissionModal.description")}
      hasButtonClose={true}
      content={
        <>
          <GLBox>
            <GLButton
              text={t("common.cancel")}
              variant="outline"
              size="primary"
              align="center"
              border
              onClick={onCancel}
            />
            <GLButton
              text={`${t("common.yes")}, ${t("common.deactivate")}`}
              variant="danger_fill"
              weight={600}
              size="primary"
              align="center"
              border={false}
              isLoading={deviceListState.isDecomissionLoading}
              disabled={deviceListState.isDecomissionLoading}
              onClick={onDecomission}
            />
          </GLBox>
        </>
      }
      onClose={() => deviceListActions.setModalDecomissionIsOpen(false)}
    />
  );
}
