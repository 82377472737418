import { useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useMemo, useState } from "react";

import { env } from "../../../../env";
import { useThemeActiveStore } from "../../../../store/theme";
import { mapDarkStyles, mapStyles } from "../../../../utils/mapStyles";
import { useDetailsDeviceStore } from "../../store/details-device-store";
import { getOptionsByApplication } from "../../utils/getOptionsByApplication";

export const useDeviceOnlyMap = () => {
  const { state, actions } = useThemeActiveStore();

  const [mapStyle, setMapStyle] = useState(
    state.isDarkMode ? mapDarkStyles : mapStyles
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [markerIcon, setMarkerIcon] = useState<google.maps.Icon | undefined>(undefined);
  const [center, setCenter] = useState({ lat: -23.5932056, lng: -46.6780125 });

  const {
    state: detailsDeviceState,
  } = useDetailsDeviceStore();

  const currentChannelData = useMemo(() => {
    return detailsDeviceState.device?.channels[detailsDeviceState.channelActive || 0]
  }, [detailsDeviceState.channelActive])

  const onLoad = useCallback(function callback(mapInstance: google.maps.Map) {
    mapInstance.setZoom(15);

    setMap(mapInstance)
  }, [])

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  function onAddressClick() {
    if (!detailsDeviceState.device) return;

    const lat = detailsDeviceState.device.meta.latitude;
    const lng = detailsDeviceState.device.meta.longitude;

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank");
  }

  function setOptionsOnMap() {
    const hasAlerts = currentChannelData?.alerts && currentChannelData.alerts.length > 0;

    const iconText = getOptionsByApplication({
      type: detailsDeviceState.device?.application,
      deviceMeasurementCategory: detailsDeviceState.device?.meta.device_measurement_category || "water"
    }).svgIcon?.[hasAlerts ? "red" : "green"];

    const svgIcon = {
      url: "data:image/svg+xml;utf-8," + encodeURIComponent(`
        ${typeof iconText === "function" ? iconText(hasAlerts ? "red" : "green") : iconText}
      `),
      scaledSize: new window.google.maps.Size(40, 40),
    };
    setMarkerIcon(svgIcon);

    setTimeout(() => {
      if (detailsDeviceState.device) {
        setCenter({
          lat: detailsDeviceState.device.meta.latitude,
          lng: detailsDeviceState.device.meta.longitude
        });
      }
    }, 1000)
  }

  useEffect(() => {
    actions.listenDarkMode();
  }, [])

  useEffect(() => {
    setMapStyle(state.isDarkMode ? mapDarkStyles : mapStyles)
    if (map) setOptionsOnMap()
  }, [state.isDarkMode, map])

  useEffect(() => {
    if (map) {
      setOptionsOnMap()
    }
  }, [currentChannelData, map])


  return {
    map,
    center,
    markerIcon,
    mapStyle,
    isLoaded,
    onLoad,
    onUnmount,
    onAddressClick
  };
};
