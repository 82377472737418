import { GLPaginationProvider } from "@group-link-one/grouplink-components";

import { usePagination } from "./usePagination";
import { UsersContent } from "./usersContent";

export function Users() {
  const { tabs, lastPageToken } = usePagination();

  return (
    <GLPaginationProvider
      tabs={tabs}
      lastNextPageToken={lastPageToken}
      isInfiniteScroll
    >
      <UsersContent />
    </GLPaginationProvider>
  );
}
