import { useAuth } from "@group-link-one/gl-auth";
import {
  AlertType,
  DateRange,
  useActiveLanguageDateFns,
  // useGLPagination,
  useI18n,
  useToast,
} from "@group-link-one/grouplink-components";
import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { useMemo, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { onRequestError } from "../../../../Errors/onError";
import { useDeviceListService } from "../../../../Services/deviceListService/useDeviceListService";
import {
  DevicesActivatedLastReadingsChannel,
  GetDevicesActivatedLastReadingsResponse,
} from "../../../../Services/deviceListService/useDeviceListService.types";
import { UseCase } from "../../../EventList/Content/Columns/AllColumns";
import { useDetailsDeviceStore } from "../../store/details-device-store";
import { getOptionsByApplication } from "../../utils/getOptionsByApplication";

export const alertsMap = {
  CALIBRATING: "Calibrating",
  CAR_PLUGGED: "Car plugged",
  LEAKAGE: "Leakage",
  MAGNETIC_FRAUDULENCE: "Magnetic fraud",
  MAX_FLOW: "Max flow",
  OPEN_DOOR: "Open door",
  OUT_OF_RANGE: "Out of range",
  REVERSE_FLOW: "Reverse flow",
  VIOLATION: "Violation",
  WIRE_CUTTING: "Wire cutting",
} as Record<AlertType, string>;

export const useDeviceDetailsTitleContent = () => {
  const [isEditingDeviceName, setIsEditingDeviceName] = useState(false);
  const [isEditingRemoteID, setisEditingRemoteID] = useState(false);

  const { state: detailsDeviceState, actions: detailsDeviceActions } =
    useDetailsDeviceStore();

  const { updateDevice } = useDeviceListService();

  const { user } = useAuth();
  const { t } = useI18n();

  const { addToast } = useToast();

  const editInputRef = useRef<HTMLInputElement>(null);
  const remoteIDInputRef = useRef<HTMLInputElement>(null);

  const isMobile = useMediaQuery("(max-width: 1270px)");
  const langActive = useActiveLanguageDateFns();

  const is1190px = useMediaQuery("(max-width: 1190px)");
  const is768Px = useMediaQuery("(max-width: 768px)");

  const userUseCase = user?.use_case as UseCase;

  const queryClient = useQueryClient();

  // const { state: paginationState, actions: paginationActions } =
  //   useGLPagination();

  const currentChannelValue = useMemo(() => {
    const channelsMap = {
      channel_0: "Channel 1",
      channel_1: "Channel 2",
    };

    return channelsMap[
      `channel_${detailsDeviceState.channelActive || 0}` as keyof typeof channelsMap
    ];
  }, [detailsDeviceState.device?.channels, detailsDeviceState.channelActive]);

  const currentChannelData = useMemo(() => {
    return detailsDeviceState.device?.channels[
      detailsDeviceState.channelActive || 0
    ];
  }, [detailsDeviceState.channelActive]);

  console.log("detailsDeviceState", detailsDeviceState);

  const [editingDeviceNameValue, setEditingDeviceNameValue] = useState<
    string | undefined
  >(currentChannelData?.name);

  const [editingRemoteIdValue, setEditingRemoteIdValue] = useState<
    string | undefined
  >(currentChannelData?.remote_id);

  const streetLightValues = useMemo(() => {
    const rmsVoltage =
      currentChannelData?.last_reading &&
      typeof currentChannelData?.last_reading !== "number" &&
      currentChannelData?.last_reading.series.find(
        (series) => series.serie === "rms_voltage"
      );

    const rmsCurrent =
      currentChannelData?.last_reading &&
      typeof currentChannelData?.last_reading !== "number" &&
      currentChannelData?.last_reading.series.find(
        (series) => series.serie === "rms_current"
      );

    const application = getOptionsByApplication({
      type: "GLUtilitiesLight",
    });

    return {
      rms_voltage: rmsVoltage ? rmsVoltage.value + ` ${application.unit}` : "0",
      rms_current: rmsCurrent ? rmsCurrent.value + ` ${application.unit}` : "0",
    };
  }, [currentChannelData]);

  // const activeTab = useMemo(() => {
  //   return paginationActions.getActiveTabById(paginationState.activeTabId);
  // }, [paginationState.activeTabId]);

  function onAddressClick() {
    if (!detailsDeviceState.device) return;

    const lat = detailsDeviceState.device.meta.latitude;
    const lng = detailsDeviceState.device.meta.longitude;

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank");
  }

  function onDeviceNameClick(type: "device_name" | "remote_id") {
    if (type === "device_name") {
      setIsEditingDeviceName(true);
      setTimeout(() => {
        editInputRef.current?.focus();
      }, 100);

      return;
    }

    if (type === "remote_id") {
      setisEditingRemoteID(true);
      setTimeout(() => {
        remoteIDInputRef.current?.focus();
      }, 100);

      return;
    }
  }

  async function onDeviceNameBlur(event: React.FocusEvent<HTMLInputElement>) {
    setIsEditingDeviceName(false);

    if (!detailsDeviceState.device?.device_id) return;

    if (event.target.value === currentChannelData?.name) {
      setEditingDeviceNameValue(currentChannelData?.name);
      return;
    }

    if (!event.target.value) {
      setEditingDeviceNameValue(currentChannelData?.name);
      return;
    }

    setEditingDeviceNameValue(event.target.value);

    try {
      await updateDevice({
        device_id: detailsDeviceState.device?.device_id,
        channel_data: detailsDeviceState.device?.channels.map((channel) => ({
          channel_name:
            currentChannelData?.channel === channel.channel
              ? event.target.value
              : channel.name,
          channel: channel.channel,
        })),
      });

      const newChannelData: DevicesActivatedLastReadingsChannel[] =
        detailsDeviceState.device?.channels.map((channel) => ({
          ...channel,
          name: event.target.value,
        }));

      addToast({
        title: t("deviceList.detailsModal.onEditDeviceName.success.title"),
        message: t(
          "deviceList.detailsModal.onEditDeviceName.success.description"
        ),
      });

      updateStoreAfterSuccess(newChannelData);
      updateCacheAfterSuccess(newChannelData);
    } catch (error) {
      onRequestError(error);
      setEditingDeviceNameValue(currentChannelData?.name);

      addToast({
        type: "error",
        title: t("deviceList.detailsModal.onEditDeviceName.error.title"),
        message: t(
          "deviceList.detailsModal.onEditDeviceName.error.description"
        ),
      });
    }
  }

  async function onRemoteIDBlur(event: React.FocusEvent<HTMLInputElement>) {
    setisEditingRemoteID(false);

    if (!detailsDeviceState.device?.device_id) return;

    if (event.target.value === currentChannelData?.remote_id) {
      setEditingRemoteIdValue(currentChannelData?.remote_id);
      return;
    }

    if (!event.target.value) {
      setEditingRemoteIdValue(currentChannelData?.remote_id);
      return;
    }

    setEditingRemoteIdValue(event.target.value);

    try {
      await updateDevice({
        device_id: detailsDeviceState.device?.device_id,
        channel_data: detailsDeviceState.device?.channels.map((channel) => ({
          channel: channel.channel,
          remote_id:
            currentChannelData?.channel === channel.channel
              ? event.target.value
              : channel.remote_id,
          channel_name: channel.name,
        })),
      });

      const newChannelData: DevicesActivatedLastReadingsChannel[] =
        detailsDeviceState.device?.channels.map((channel) => ({
          ...channel,
          remote_id: event.target.value,
        }));

      addToast({
        title: t("deviceList.detailsModal.onEditRemoteID.success.title"),
        message: t(
          "deviceList.detailsModal.onEditRemoteID.success.description"
        ),
      });

      updateStoreAfterSuccess(newChannelData);
      updateCacheAfterSuccess(newChannelData);
    } catch (error) {
      onRequestError(error);
      setEditingRemoteIdValue(currentChannelData?.remote_id);

      addToast({
        type: "error",
        title: t("deviceList.detailsModal.onEditRemoteID.error.title"),
        message: t("deviceList.detailsModal.onEditRemoteID.error.description"),
      });
    }
  }

  function onDateChange(range: DateRange) {
    detailsDeviceActions.setRange(range);
  }

  function onChannelChange(channel?: string) {
    if (!channel) return;

    const channelClicked = Number(channel.split(" ")[1]) - 1;

    detailsDeviceActions.setChannelActive(channelClicked);

    if (channelClicked === 0) {
      detailsDeviceActions.setCurrentData(detailsDeviceState.channelZero || []);
    }

    if (channelClicked === 1) {
      detailsDeviceActions.setCurrentData(detailsDeviceState.channelOne || []);
    }
  }

  function hasAlarm(alarm: AlertType) {
    return detailsDeviceState.device?.channels[0].alerts.includes(alarm);
  }

  function updateStoreAfterSuccess(
    newChannelData: DevicesActivatedLastReadingsChannel[]
  ) {
    if (!currentChannelData || !detailsDeviceState.device) return;

    detailsDeviceActions.setDevice({
      ...detailsDeviceState.device,
      channels: newChannelData,
    });
  }

  function updateCacheAfterSuccess(
    newChannelData: DevicesActivatedLastReadingsChannel[]
  ) {
    // if (!currentChannelData || !detailsDeviceState.device || !activeTab) return;
    if (!currentChannelData || !detailsDeviceState.device) return;

    // const activeTabMap = {
    //   "tab-1": {
    //     do: () => updateTabOne(newChannelData),
    //   },
    // };

    // activeTabMap[`tab-${activeTab.id}` as keyof typeof activeTabMap]?.do();

    updateTabOne(newChannelData);
  }

  function updateTabOne(newChannelData: DevicesActivatedLastReadingsChannel[]) {
    const allCachesData: [
      QueryKey,
      GetDevicesActivatedLastReadingsResponse[] | undefined,
    ][] = queryClient.getQueriesData({
      queryKey: ["devices-activated-last-readings"],
    });

    if (allCachesData.length === 0) return;

    allCachesData.forEach(([queryKey, cachedData]) => {
      if (!cachedData) return;

      const hasDeviceID = cachedData.some(
        (device) => device.device_id === detailsDeviceState.device?.device_id
      );

      if (!hasDeviceID) return;

      const newCacheData = cachedData.map((device) => {
        if (device.device_id === detailsDeviceState.device?.device_id) {
          return {
            ...device,
            channels: newChannelData,
          };
        }

        return device;
      });

      queryClient.setQueryData(queryKey, newCacheData);
    });
  }

  function formatActivationPlatform() {
    let formattedPlatform = "";

    switch (detailsDeviceState.device?.meta.activation_mode) {
      case "app":
        formattedPlatform = "GLActivation";
        break;
      case "web":
        formattedPlatform = "Dashboard";
        break;
      case "server_side":
        formattedPlatform = t("deviceList.detailsModal.viaServer");
        break;
    }

    return formattedPlatform;
  }

  return {
    device: detailsDeviceState.device,
    range: detailsDeviceState.range,
    userUseCase,
    currentChannelValue,
    currentChannelData,
    detailsDeviceState,
    detailsDeviceActions,
    t,
    streetLightValues,
    hasAlarm,
    langActive,
    alertsMap,
    editInputRef,
    remoteIDInputRef,
    isMobile,
    is1190px,
    is768Px,
    editingDeviceNameValue,
    editingRemoteIdValue,
    isEditingDeviceName,
    isEditingRemoteID,
    setEditingDeviceNameValue,
    setIsEditingDeviceName,
    setisEditingRemoteID,
    setEditingRemoteIdValue,
    onDeviceNameClick,
    onDeviceNameBlur,
    onRemoteIDBlur,
    onAddressClick,
    onDateChange,
    onChannelChange,
    formatActivationPlatform,
  };
};
