
import { Colors, GLPieChartDataProps, useI18n } from "@group-link-one/grouplink-components";
import { useMemo } from "react";

import { useGetDailyReadings } from "../../DeviceList/ModalDeviceDetails/Graphs/useGetDailyReadings";
import { useDetailsDeviceStore } from "../../DeviceList/store/details-device-store";
import { useHealthCheckStore } from "../store/health-check-store";

export const useDeviceModal = () => {

  const {
    state: healthCheckState,
    actions: healthCheckActions,
  } = useHealthCheckStore()

  const { t } = useI18n();

  const { actions: detailsDeviceActions } = useDetailsDeviceStore();

  const { formatReadings } = useGetDailyReadings()

  function onClickSeeDetails() {
    if (!healthCheckState.deviceSelected) return;
    detailsDeviceActions.setDevice(healthCheckState.deviceSelected);
    detailsDeviceActions.setModalIsOpen(true);
  }

  const currentDeviceChannel = healthCheckState.deviceSelected?.channels[0]

  const dailyReadingInfo = useMemo(() => {
    if (!healthCheckState.deviceSelectedDailyReadings) return;

    const { readingsFormatted } = formatReadings(
      healthCheckState.deviceSelectedDailyReadings.rows,
      healthCheckState.range.from,
      healthCheckState.range.to
    );

    return {
      with_readings: readingsFormatted.filter((reading) => reading.reading > 0).length,
      without_readings: readingsFormatted.filter((reading) => reading.reading === 0).length,
      readingsFormatted
    }

  }, [healthCheckState.deviceSelectedDailyReadings])

  const pieChartData: GLPieChartDataProps[] = useMemo(() => {
    if (!dailyReadingInfo) return [];

    return [
      {
        id: "with_readings",
        label: t("healthCheck.deviceSelected.communicationRate.withReadings"),
        value: dailyReadingInfo.with_readings,
        color: Colors.LIGHT_GREEN,
        hasTooltip: false,
        description: t("healthCheck.deviceSelected.communicationRate.withReadingAbr")
      },
      {
        id: "without_readings",
        label: t("healthCheck.deviceSelected.communicationRate.withoutReadings"),
        value: dailyReadingInfo.without_readings,
        color: Colors.ORANGE,
        hasTooltip: false,
        description: t("healthCheck.deviceSelected.communicationRate.withoutReadingAbr")
      },
    ]
  }, [dailyReadingInfo])

  const communicationRate = useMemo(() => {
    if (!healthCheckState.deviceSelectedDailyReadings) return "0%";

    const totalReadings = dailyReadingInfo?.readingsFormatted.length || 0;
    const readingsWithCommunication = dailyReadingInfo?.readingsFormatted.filter((reading) => reading.reading > 0).length || 0;

    return ((readingsWithCommunication / totalReadings) * 100).toFixed(1).concat("%");
  }, [dailyReadingInfo])

  return {
    healthCheckState,
    healthCheckActions,
    communicationRate,
    dailyReadingInfo,
    pieChartData,
    t,
    currentDeviceChannel,
    onClickSeeDetails
  };
};
