import { GLBox } from "@group-link-one/grouplink-components";
import styled from "styled-components";

export const DeviceListBigNumbersContainer = styled(GLBox)`
  > div {
    /* flex: 1 !important; */
  }

  @media (max-width: 1350px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
